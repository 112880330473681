import React, { Fragment, useState, useEffect, useReducer } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import profileImg from "./profile.png";
import swal from "sweetalert";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { Tab, Nav } from "react-bootstrap";
import useAdmins from "../../../hooks/useAdmins";
import usePayments from "../../../hooks/usePayments";
import "../../components/Dashboard/customCss/xclx.css";
import {
  notifyOldPasswordWarning,
  notifyNewPasswordWarning,
  notifyConfirmNewPasswordWarning,
  notifyOldPasswordValidity,
  notifyPasswordMatch,
  notifyPasswordSpecial,
  notifyUppercaseLetterValidity,
  notifyLengthValidity,
  notifyFullNameAbsence,
  notifyUserNameAbsence,
  notifyUserNameConflict,
} from "../../notifyHooks/notifyAdmin";

const initialState = {
  adminModal: false,
  selectedAdmin: null, // Initially set to null, no admin selected.
};

const reducer = (state, action) => {
  switch (action.type) {
    case "adminModal":
      return {
        ...state,
        adminModal: !state.adminModal,
        selectedAdmin: action.payload || null, // Set selectedAdmin based on the payload
      };
    default:
      return state;
  }
};

const EditAdmin = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [admin, setAdmin] = useState({});
  const [selectedAdmin, setSelectedAdmin] = useState();
  const [profileImage, setProfileImage] = useState(profileImg);
  const [editedAdminData, setEditedAdminData] = useState({
    fullName: "",
    adminName: "",
    password: "",
    oldPassword: "",
    confirmNewPassword: "",
  });
  const [accountData, setAccountData] = useState({
    fullName: "",
    username: "",
    email: "",
  });
  const [admins, setAdmins] = useState([]);
  const [showOldPassword, setShowOldPassword] = useState(false); // Separate state for "Old Password" visibility
  const [showPassword, setShowPassword] = useState(false); // Separate state for "Password" visibility
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false); // Separate state for "Confirm New Password" visibility
  const [activeTab, setActiveTab] = useState("accountSetup");
  const [show, setShow] = useState(true);

  const { fetchAdminHandler, changePasswordHandler, editAdminHandler, deleteAccountHandler, uploadImageHandler } = useAdmins();
  const { cancelSubscriptionHandler } = usePayments();
  useEffect(() => {
    const fetchAdmin = async () => {
      try {
        const response = await fetchAdminHandler();
        setAdmin(response.admin);

        setEditedAdminData({
          fullName: response.admin.fullName,
          username: response.admin.username,
        });

        setAccountData({
          fullName: response.admin.fullName,
          username: response.admin.username,
          email: response.admin.email,
        });
        if (response.admin.image !== null) {
          if (
            await checkFileExists(
              `/profile-pictures/${response.admin.image}`
            )
          ) {
            setProfileImage(`/profile-pictures/${response.admin.image}`);
          }
        }
      } catch (error) {
        swal("Failed!", "Event details error!", "error");
        console.error(error);
      } finally {
        setShow(false);
      }
    };

    fetchAdmin();
  }, []);
  const cancelPackage = async (e) => {
    e.preventDefault();
    try {
      const willDelete = await swal({
        title: "Are you sure?",
        text: "Once canceled, you will not be able to recover this package!",
        icon: "warning",
        className: "text-center-swal",
        buttons: true,
        dangerMode: true,
      });

      if (willDelete) {
        const response = await cancelSubscriptionHandler();
        if (response.status === 200) {
          window.location.reload();
        }
      }
    } catch (error) {
      swal("Failed!", error.response.body.error, "error");
      console.error(error);
    }
  };
  const handleDeleteAccount = async () => {
    try {
      const password = await swal({
        title: "Enter your password to confirm deletion",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        content: {
          element: "input",
          attributes: {
            type: "password",
            placeholder: "Your password",
          },
        },
      });

      if (password) {
        const response = await deleteAccountHandler(password);
        if (response.status === 200) {
          window.location.href = "login";
        } else {
          swal("Failed!", response.body.message, "error");
        }
      }
    } catch (error) {
      swal("Failed!", error.response.data.message, "error");
    }
  };

  const checkFileExists = async (fileUrl) => {
    try {
      const response = await axios.head(fileUrl);
      // If the status code is 200, it means the file exists
      const tybe = response.headers["content-type"].split("/");
      if (tybe[0] === "image") {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // If an error occurs (e.g., 404 Not Found), the file does not exist
      return false;
    }
  };

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    return dateObject.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "UTC",
    });
  };

  const toggleOldPassword = () => {
    setShowOldPassword(!showOldPassword); // Toggle the "Confirm Pin" visibility state
  };

  const togglePassword = () => {
    setShowPassword(!showPassword); // Toggle the "Confirm Pin" visibility state
  };

  const toggleConfirmNewPassword = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword); // Toggle the "Confirm Pin" visibility state
  };

  const fetchAdminById = async () => {
    try {
      // Make a GET request to your backend API endpoint for fetching admin by ID
      const response = await fetchAdminHandler();

      if (response.admin) {
        // Set initial state for full name and adminName
        setEditedAdminData({
          password: "",
          oldPassword: "",
          confirmNewPassword: "",
        });
      }

      return response;
    } catch (error) {
      console.error("Failed to fetch admin data for editing", error);
    }
  };

  const handleAdminEdit = async (adminId) => {
    try {
      const response = await fetchAdminById(adminId);

      if (response.admin) {
        const selectedAdminData = response.admin;
        // Set initial state for the date input field
        setEditedAdminData({
          ...selectedAdminData,
          password: "",
        });

        // Open the modal
        dispatch({ type: "adminModal", payload: response.admin });
        setSelectedAdmin(selectedAdminData);
      } else {
        // Handle the case where the admin is not found
        console.error("admin not found");
      }
    } catch (error) {
      console.error("Failed to fetch admin data for editing", error);
    }
  };

  // In handleInputChange function
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedAdminData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const ChangeProfileImg = async (e) => {
    // Assuming you have a state variable named 'formdata'
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    const typeParts = formData.get("image").type.split("/");
    if (typeParts[0] === "image") {
      setProfileImage(URL.createObjectURL(e.target.files[0]));
      try {
        const response = await uploadImageHandler(formData);
        // Handle the response from the server if needed
      } catch (error) {
        // Handle errors if the upload fails
        console.error("Error uploading image:", error);
      }
    }
  };


  const handleSavePasswordChanges = async () => {
    try {
      const editedData = {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      };
      if (!editedAdminData.oldPassword) {
        notifyOldPasswordWarning();
        return;
      }

      if (!editedAdminData.password) {
        notifyNewPasswordWarning();
        return;
      }

      if (!editedAdminData.confirmNewPassword) {
        notifyConfirmNewPasswordWarning();
        return;
      }

      // if (editedAdminData.password.length < 6) {
      //   notifyLengthValidity();
      //   return;
      // }

      // if (!/[A-Z]/.test(editedAdminData.password)) {
      //   notifyUppercaseLetterValidity();
      //   return;
      // }

      editedData.oldPassword = editedAdminData.oldPassword;
      editedData.newPassword = editedAdminData.password;
      editedData.confirmNewPassword = editedAdminData.confirmNewPassword;

      // Send a PUT request to update the admin's password on the backend using editedAdminData
      await changePasswordHandler(admin._id, editedData);

      // Update the admin's information on the frontend
      setAdmins((prevAdmins) =>
        prevAdmins.map((admin) =>
          admin._id === selectedAdmin._id ? editedData : admin
        )
      );

      // After successful update, close the modal or perform any other actions
      dispatch({ type: "adminModal" });

      // Reset selectedAdmin to null
      setSelectedAdmin(null);

      swal("Admin has been updated!", {
        icon: "success",
      });
    } catch (error) {
      // Handle other errors
      if (error.response && error.response.status === 401) {
        notifyOldPasswordValidity();
      } else if (error.response && error.response.status === 409) {
        notifyPasswordMatch();
      } else if (error.response && error.response.status === 406) {
        notifyPasswordSpecial();
      } else if (error.response && error.response.status === 411) {
        notifyLengthValidity();
      } else if (error.response && error.response.status === 412) {
        notifyUppercaseLetterValidity();
      } else {
        console.error("Failed to update admin", error);
        swal("Oops", "Failed to update admin!", "error");
      }
    }
  };

  const handleInputAdminChange = (e) => {
    const { name, value } = e.target;
    setAccountData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveAdminChanges = async () => {
    try {
      const editedData = {
        fullName: accountData.fullName,
        username: accountData.username,
      };

      if (!editedData.fullName) {
        notifyFullNameAbsence();
        return;
      }

      if (!editedData.username) {
        notifyUserNameAbsence();
        return;
      }

      // Send a PUT request to update the admin's password on the backend using editedAdminData
      await editAdminHandler(admin._id, editedData);
      // Update the admin's information on the frontend
      setAdmins((prevAdmins) =>
        prevAdmins.map((admin) =>
          admin._id === selectedAdmin._id ? editedData : admin
        )
      );

      if (accountData.email !== admin.email) {
        swal("Admin has been updated but email won't be changed!", {
          icon: "success",
        });
      } else {
        swal("Admin has been updated!", {
          icon: "success",
        });
      }
    } catch (error) {
      // Handle other errors
      if (error.response && error.response.status === 409) {
        notifyUserNameConflict(error.response.data.message);
      } else {
        console.error("Failed to update admin", error);
        swal("Oops", "Failed to update admin!", "error");
      }
    }
  };

  // Create a reusable component for package detail items
  const PackageDetailItem = ({ label, value }) => (
    <li className="list-group-item d-flex px-0 justify-content-between">
      <strong>{label}</strong>
      <span className="mb-0">{value}</span>
    </li>
  );

  return (
    <Fragment>
      <ToastContainer />
      {show ? (
        <div className="text-center mt-4">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden"></span>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row">
            {admin ? (
              <div className="col-xl-3 col-lg-4">
                <div className="clearfix">
                  <div className="card card-bx profile-card author-profile m-b30">
                    <div className="card-body">
                      <div className="p-5">
                        <div className="author-profile">
                          <div
                            className="author-media"
                            style={{
                              width: "150px",
                              height: "150px",
                              position: "relative",
                            }}
                          >
                            <img
                              src={profileImage}
                              alt=""
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                            />
                            <div
                              className="upload-link"
                              title=""
                              data-toggle="tooltip"
                              data-placement="right"
                              data-original-title="update"
                            >
                              <input
                                onChange={ChangeProfileImg}
                                type="file"
                                name="image"
                                accept="image/*"
                                className="update-flie"
                                enctype="multipart/form-data"
                              />
                              <i className="fa fa-camera"></i>
                            </div>
                          </div>
                          <div className="author-info">
                            <h6 className="title">{admin.fullName}</h6>
                            <span>Administrator</span>
                          </div>
                        </div>
                      </div>
                      <div
                        className="info-list"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ul>
                          <li>
                            <button
                              onClick={handleAdminEdit}
                              className="btn btn-primary"
                            >
                              CHANGE PASSWORD
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="col-xl-9 col-lg-8">
              <div className="profile-card card-bx m-b30">
                <div className="card">
                  <div className="card-body">
                    <div className="profile-tab">
                      <div className="custom-tab-1">
                        <Tab.Container defaultActiveKey="account-setup">
                          <Nav as="ul" className="nav nav-tabs">
                            <Nav.Item as="li" className="nav-item">
                              <Nav.Link
                                to="#account-setup"
                                eventKey="account-setup"
                              >
                                Account Setup
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="nav-item">
                              <Nav.Link to="#packages" eventKey="packages">
                                Package
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane
                              id="account-setup"
                              eventKey="account-setup"
                            >
                              <div className="my-post-content pt-3">
                                <form className="profile-form">
                                  <div
                                    className="card-body"
                                    style={{ padding: "10px 10px 10px" }}
                                  >
                                    <div className="row">
                                      <div className="col-sm-6 m-b30">
                                        <label className="form-label">
                                          Full Name
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="fullName"
                                          onChange={handleInputAdminChange}
                                          defaultValue={accountData.fullName}
                                        />
                                      </div>
                                      <div className="col-sm-6 m-b30">
                                        <label className="form-label">
                                          Username
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="username"
                                          onChange={handleInputAdminChange}
                                          defaultValue={accountData.username}
                                        />
                                      </div>
                                      <div className="col-sm-6 m-b30">
                                        <label className="form-label">
                                          Email address
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="email"
                                          onChange={handleInputAdminChange}
                                          defaultValue={accountData.email}
                                          disabled={true}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="card-footer"
                                    style={{ padding: "15px 15px 15px" }}
                                  >
                                    <Button
                                      variant="primary"
                                      onClick={() => handleSaveAdminChanges()}
                                    >
                                      UPDATE
                                    </Button>
                                    <Button
                                      variant="danger"
                                      onClick={() => handleDeleteAccount()}
                                    >
                                      Delete Account
                                    </Button>
                                  </div>
                                </form>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane id="packages" eventKey="packages">
                              <div className="my-post-content pt-3">
                                <form className="profile-form">
                                  <div
                                    className="card-body"
                                    style={{ padding: "25px 25px 25px" }}
                                  >
                                    <div className="row">
                                      <ul className="list-group list-group-flush">
                                        <PackageDetailItem
                                          label="Pack"
                                          value={admin.subscription?.pack}
                                        />
                                        <PackageDetailItem
                                          label="Start Date"
                                          value={formatDate(
                                            admin.subscription?.startDate
                                          )}
                                        />
                                        <PackageDetailItem
                                          label="End Date"
                                          value={formatDate(
                                            admin.subscription?.endDate
                                          )}
                                        />
                                        <PackageDetailItem
                                          label="Status"
                                          value={
                                            admin.subscription?.isActive ===
                                              true
                                              ? "Active"
                                              : "Inactive"
                                          }
                                        />
                                        <div
                                          className="card-footer"
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            padding: "0px 0px 0px",
                                          }}
                                        >
                                          <button
                                            className="btn btn-danger"
                                            style={{
                                              width: "fit-content",
                                              marginTop: "10px",
                                            }}
                                            onClick={cancelPackage}
                                            type="button"
                                          >
                                            Cancel Package
                                          </button>
                                        </div>
                                      </ul>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            className="fade"
            show={state.adminModal}
            onHide={() => {
              dispatch({ type: "adminModal" });
              setSelectedAdmin(null);
            }}
          >
            <Modal.Header>
              <Modal.Title>Change Admin's Password</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => dispatch({ type: "adminModal" })}
              ></Button>
            </Modal.Header>
            <Modal.Body>
              {selectedAdmin ? (
                <React.Fragment>
                  <div className="form-container mb-3 position-relative">
                    <span>
                      <b>Actual Password</b>
                    </span>
                    <input
                      type={showOldPassword ? "text" : "password"}
                      className="form-control"
                      value={editedAdminData.oldPassword}
                      onChange={handleInputChange}
                      name="oldPassword"
                    />
                    <span
                      className="clear-file position-absolute top-50 translate-middle-y"
                      onClick={toggleOldPassword}
                      style={{ marginTop: "10px" }}
                    >
                      {showOldPassword ? (
                        <i className="bi bi-eye-slash"></i>
                      ) : (
                        <i className="bi bi-eye"></i>
                      )}
                    </span>
                  </div>

                  <div className="form-container mb-3 position-relative">
                    <span>
                      <b>New Password</b>
                    </span>
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      value={editedAdminData.password}
                      onChange={handleInputChange}
                      name="password"
                    />
                    <span
                      className="clear-file position-absolute top-50 translate-middle-y"
                      onClick={togglePassword}
                      style={{ marginTop: "10px" }}
                    >
                      {showPassword ? (
                        <i className="bi bi-eye-slash"></i>
                      ) : (
                        <i className="bi bi-eye"></i>
                      )}
                    </span>
                  </div>

                  <div className="form-container mb-3 position-relative">
                    <span>
                      <b>Confirm Password</b>
                    </span>
                    <input
                      type={showConfirmNewPassword ? "text" : "password"}
                      className="form-control"
                      value={editedAdminData.confirmNewPassword}
                      onChange={handleInputChange}
                      name="confirmNewPassword"
                    />
                    <span
                      className="clear-file position-absolute top-50 translate-middle-y"
                      onClick={toggleConfirmNewPassword}
                      style={{ marginTop: "10px" }}
                    >
                      {showConfirmNewPassword ? (
                        <i className="bi bi-eye-slash"></i>
                      ) : (
                        <i className="bi bi-eye"></i>
                      )}
                    </span>
                  </div>
                </React.Fragment>
              ) : (
                <p>No admin selected.</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger light"
                onClick={() => dispatch({ type: "adminModal" })}
              >
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => handleSavePasswordChanges(editedAdminData)}
              >
                Save changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </Fragment>
  );
};

export default EditAdmin;
