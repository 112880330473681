import { toast } from "react-toastify";

const notifyTrackerFullNameWarning = () => {
  toast.warn("Please enter tracker's full name.", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyTrackerNameWarning = () => {
  toast.warn("Please enter tracker's name.", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyTrackerPinWarning = () => {
  toast.warn("Please enter tracker's pin.", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyTrackerConfirmPinWarning = () => {
  toast.warn("Please confirm tracker's pin.", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyConfirmPinLengthWarning = () => {
  toast.warn("Pin must be 4 digits long.", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyConfirmPinWarning = () => {
  toast.warn("Please enter tracker's new confirm pin.", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyConfirmPins = () => {
  toast.warn("Please ensure that Pin and Confirm Pin match.", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyTrackerNameExists = () => {
  toast.warn(
    "Tracker name already exists, please enter a different tracker name.",
    {
      position: "top-center",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  );
};

const notifyPinNumberValidation = () => {
  toast.warn("Pin must contain only numbers, please enter a valid pin.", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyOldPinWarning = () => {
  toast.warn("Please enter the actual pin!", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyNewPinWarning = () => {
  toast.warn("Please enter the new pin!", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyOldPinLengthWarning = () => {
  toast.warn("Actual pin must be 4 digits long!", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyNewPinLengthWarning = () => {
  toast.warn("New pin must be 4 digits long!", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyOldPinWrongWarning = () => {
  toast.warn("Actual pin is incorrect. Please enter the correct actual pin.", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyPinSameWarning = () => {
  toast.warn("New pin cannot be the same as the actual pin.", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyNewPinNumberValidation = () => {
  toast.warn("New pin must contain only numbers, please enter a valid pin.", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifySamePinsWarning = () => {
  toast.warn("New pin and confirm pin must match!", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyNoChanges = () => {
  toast.info("No changes have been made while managing splits!", {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export {
  notifyOldPinWarning,
  notifyTrackerFullNameWarning,
  notifyTrackerNameWarning,
  notifyTrackerPinWarning,
  notifyTrackerConfirmPinWarning,
  notifyConfirmPinLengthWarning,
  notifyConfirmPinWarning,
  notifyConfirmPins,
  notifyTrackerNameExists,
  notifyPinNumberValidation,
  notifyNewPinWarning,
  notifyOldPinLengthWarning,
  notifyNewPinLengthWarning,
  notifyOldPinWrongWarning,
  notifyPinSameWarning,
  notifyNewPinNumberValidation,
  notifySamePinsWarning,
  notifyNoChanges,
};
