// export const isAuthenticated = (state) => {
//     if (state.auth.auth.idToken) return true;
//     return false;
// };

export const isAuthenticated = (state) => {
  if (state.auth.token) {
    // console.log("Tokeni valid 2: ", state.auth.token);
    return true;
  }
  console.log("There is no token!");
  return false;
};

// export const isAuthenticated = (state) => {
//   const tokenDetailsString = localStorage.getItem("userDetails");
//   if (tokenDetailsString) {
//     return true;
//   }
//   return false;
// };
