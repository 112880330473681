import axios from "axios";

const getAllEvents = async () => {
  try {
    const response = await axios.get("/public/api/events");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch events", error);
  }
};

const getRaceByEventId = async (eventId) => {
  try {
    const response = await axios.get(`/public/api/event/${eventId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch event by eventId", error);
  }
};

const getAllRacesByEventId = async (eventId) => {
  try {
    const response = await axios.get(`/public/api/races/${eventId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch races", error);
  }
};

const addParticipant = async (raceId, formData) => {
  try {
    const response = await axios.post(
      `/public/api/race/${raceId}/participant`,
      formData
    );
    return response;
  } catch (error) {
    console.error("Failed to create participant", error);
    throw error; // Throw the error to be caught by the calling function
  }
};


export { getAllEvents, getRaceByEventId, getAllRacesByEventId, addParticipant };
