import axios from "axios";

const getAllRaces = async () => {
  try {
    const response = await axios.get("/races");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch races", error);
  }
};

const getAllRacesByEventId = async (eventId) => {
  try {
    const response = await axios.get(`/races/${eventId}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      // No trackers found, return an empty array
      return [];
    } else {
      // Other errors, log and rethrow
      console.error("Failed to fetch races", error);
    }
  }
};

const getRaceByEventId = async (eventId) => {
  try {
    const response = await axios.get(`/race/${eventId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch race by eventId", error);
  }
};

const getAllRacesByTrackerId = async (trackerId) => {
  try {
    const response = await axios.get(`/tracker-details/${trackerId}`);
    return response.data;
  } catch {
    console.error("Failed to fetch tracker details");
  }
};

const getRaceByIds = async (raceId, eventId) => {
  try {
    const response = await axios.get(`/race/${eventId}/${raceId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch race", error);
  }
};

const createRace = async (raceData) => {
  try {
    const response = await axios.post("/race/create", raceData);
    return response.data;
  } catch (error) {
    console.error("Failed to create race", error);
  }
};

const deleteRace = async (raceId) => {
  try {
    const response = await axios.delete(`/race/${raceId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to delete race", error);
  }
};

const editRace = async (raceId, raceData) => {
  try {
    const response = await axios.put(`/race/${raceId}`, raceData);
    return response.data;
  } catch (error) {
    console.error("Failed to edit tracker", error);
  }
};

const editSplits = async (trackerId, raceId, splitData) => {
  try {
    const response = await axios.put(
      `/race/${trackerId}/${raceId}/splits/edit`,
      splitData
    );
    return response.data;
  } catch (error) {
    console.error("Failed to edit splits", error);
  }
};

const addTrackerToRace = async (raceId, trackerData) => {
  try {
    const response = await axios.post(
      `/race/${raceId}/tracker/add`,
      trackerData
    );
    return response.data;
  } catch (error) {
    console.error("Failed to add tracker in race", error);
  }
};

const deleteTrackerFromRace = async (raceId, trackerData) => {
  try {
    const response = await axios.delete(
      `/race/${raceId}/tracker/delete`,
      trackerData
    );
    return response.data;
  } catch (error) {
    console.error("Failed to delete tracker from race, error");
  }
};

const generateConfirmedSplits = async (raceId, selectedSplits) => {
  try {
    const response = await axios.patch(`/confirm-splits/${raceId}`, {
      selectedSplits,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to generate confirmed splits", error);
  }
};

const updateRaceTime = async (raceId, currentTime) => {
  try {
    const response = await axios.post(`/updateRaceTime`, {
      raceId,
      dateTime: currentTime,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to start the race", error);
  }
};

export {
  getAllRaces,
  getAllRacesByEventId,
  getAllRacesByTrackerId,
  getRaceByIds,
  createRace,
  deleteRace,
  editRace,
  editSplits,
  addTrackerToRace,
  deleteTrackerFromRace,
  generateConfirmedSplits,
  getRaceByEventId,
  updateRaceTime,
};
