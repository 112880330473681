import React, { useEffect, useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useWebAPI from "../../../hooks/useWebAPI";

const EventDetailsModal = ({ event, show, onHide }) => {
  const [races, setRaces] = useState([]);
  const [loading, setLoading] = useState(true); // State for loading
  const { fetchRacesByEventId } = useWebAPI();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRaces = async () => {
      setLoading(true); // Set loading state to true
      try {
        const response = await fetchRacesByEventId(event._id);
        setRaces(response);
      } catch (error) {
        console.error("Failed to fetch", error);
      } finally {
        setLoading(false); // Set loading state to false after fetching
      }
    };
    fetchRaces();
  }, [event._id]);

  const handleApply = (raceId) => {
    navigate(`/race-registration/${event._id}/${raceId}`);
  };

  function normalDate(unixTime) {
    const timestampInMilliseconds = unixTime * 1000;
    const dateTime = new Date(timestampInMilliseconds);

    const day = dateTime.getDate().toString().padStart(2, "0");
    const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
    const year = dateTime.getFullYear();

    return `${day}/${month}/${year}`;
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Event Details - {event.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? ( // Check if loading
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" className="me-2" />
            <span>Loading races...</span>
          </div>
        ) : (
          <>
            <h5>Races:</h5>
            <ul className="list-group">
              {races.map((race) => (
                <li key={race._id} className="list-group-item">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6 className="mb-0">{race.raceName}</h6>
                      <small>Date: {normalDate(race.dateTime)}</small>
                    </div>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => handleApply(race._id)}
                    >
                      Apply
                    </Button>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EventDetailsModal;
