import React, { useEffect } from "react";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
import useAdmins from "../../hooks/useAdmins";
// image
const VerifyEmail = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const { verifyEmailChangeHandler } = useAdmins();
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await verifyEmailChangeHandler(token);

        swal({
          title: response.data.message,
          icon: response.status === 200 ? "success" : "error",
          buttons: {
            cancel: "OK",
          },
        }).then(() => {
          // Redirect to admin/login
          navigate("/dashboard");
        });
      } catch (error) {
        if (error.response.status === 401) {
          swal({
            title: "Error",
            text: "Token has expired.",
            icon: "error",
            buttons: {
              cancel: "OK",
            },
          }).then(() => {
            // Redirect to admin/login
            navigate("/dashboard");
          });
        }
        if (error.response.status === 404) {
          swal({
            title: "Error",
            text: "User not found.",
            icon: "error",
            buttons: ["OK"],
          }).then(() => {
            // Redirect to admin/login
            navigate("/dashboard");
          });
        }
        if (error.response.status === 400) {
          swal({
            title: "Error",
            text: "This is not the right URL.",
            icon: "error",
            buttons: ["OK"],
          }).then(() => {
            // Redirect to admin/login
            navigate("/dashboard");
          });
        }
      }
    };

    checkToken();
  }, [token]);
  return (
    <div
      className="authincation h-100 p-meddle"
      style={{ backgroundColor: "#222b40" }}
    >
      <div className="container h-100"></div>
    </div>
  );
};

export default VerifyEmail;
