import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/style.css"; // Your custom styles
import raceLogo from "../../constant/logo-2.png";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <div style={{ backgroundColor: "rgb(243, 240, 236)" }}>
      <Helmet>
        <title>Privacy Policy | NOVUS LLC</title>
        <meta name="description" content="Privacy Policy for NOVUS LLC" />
        <link
          href="/docs/5.0/dist/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
          crossorigin="anonymous"
        />
      </Helmet>

      {/* Navigation Bar */}
      <nav className="nav1">
        <img
          src={raceLogo}
          className="logo"
          alt="Race Logo"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
          }}
        />
        <div className="menu-items">
          <a href="/">Home</a>
          <a href="/">Gallery</a>
          <a href="/">About</a>
          <a href="/">Pricing</a>
          <a href="/">Events</a>
          <a href="/">Contact</a>
        </div>
      </nav>
      <hr className="section-divider1" />

      {/* Privacy Policy Content */}
      <div className="container mt-5 p-5">
        <h1 className="text-center mb-4">Privacy Policy</h1>
        <p>
          Welcome to NOVUS LLC's Privacy Policy. We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website.
        </p>
        <h2>Information We Collect</h2>
        <p>
          We collect personal information that you voluntarily provide to us when you register on our site, make a purchase, or contact us for support. This may include your name, email address, phone number, and payment information.
        </p>
        <h2>How We Use Your Information</h2>
        <p>
          We use your information to process transactions, provide customer support, and improve our services. We may also use your information to send you promotional materials and updates about our products and services.
        </p>
        <h2>Sharing Your Information</h2>
        <p>
          We do not sell or rent your personal information to third parties. We may share your information with service providers who assist us in operating our website or providing our services, but they are bound by confidentiality agreements.
        </p>
        <h2>Your Rights</h2>
        <p>
          You have the right to access, correct, or delete your personal information. If you wish to exercise these rights, please contact us at info@novus.consulting..
        </p>
        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us at info@novus.consulting.
        </p>
        
        {/* General Terms */}
        <h1 className="text-center mb-4">General Terms</h1>
        <p>
          These General Terms are applicable to the Syncrace Application available in Playstore and AppStore (hereinafter “Application”) and to its visitors to this Application (hereinafter 'User'). Please be informed that the owner of the Application is Novus LLC with its registered seat at Prishtinë (hereinafter ”Syncrace”). Using the Application means that the User acknowledges in full all the General Terms of the Application and Privacy and Data Security Policy. Syncrace reserves the right, whenever necessary, to amend the General Terms of the Application, Privacy, and Data Security Policy as well as any other terms, regulations, guidelines, or alerts.
        </p>
        <p>
          Through this Application, Syncrace provides data made accessible to the User. Syncrace shall have the right to change the presentation, layout, and location of the Application, as well as the Content and Terms, at any time.
        </p>
        <p>
          The use of the Application is free and under the sole responsibility of the user. By accessing the application, and other affiliated pages, including but not limited to social media pages, you accept all Terms of the Application in effect every time the User accesses the Application.
        </p>
        <p>
          Syncrace reserves the unconditional right to close down the Application, change the domain, suspend, interrupt, or cease to operate the site without any duty or liability to its visitors and other third parties.
        </p>
        <p>
          Syncrace does not make representations as to the continuity, accuracy, and reliability of the information contained in the Application. The content of the Application is for information purposes only and cannot be relied on by any party with respect to other purposes.
        </p>

        {/* Users Information */}
        <h2>Users Information</h2>
        <p>
          The User acknowledges and accepts that the access to the Application and/or the Contents is free and under their sole responsibility. The User agrees to make legal and appropriate use of this Application and its materials, pursuant to the legislation in force.
        </p>
        <p>
          The User shall refrain from:
        </p>
        <ul>
          <li>
            Making unauthorized use of the Application and trying to access restricted areas of this Application, without compliance with the conditions required for such access;
          </li>
          <li>
            Use of the Application and/or its Content for illegitimate or unlawful purposes, contrary to the terms set out in these General Terms, cause harm to the physical or logical structures of Syncrace by disseminating viruses in networks or some other physical or log system;
          </li>
          <li>
            Print, copy, transmit, allow access to the public through any form of media, alter or amend the Content, except where the holder of such rights or his legal representative authorizes the User to do so;
          </li>
          <li>
            Not respecting the industrial or intellectual property rights of the materials produced by Syncrace attempts to obtain the Material by means or procedures different from those means or procedures made available.
          </li>
        </ul>

        {/* Hyperlinks */}
        <h2>Hyperlinks</h2>
        <p>
          Users wishing to connect or hyperlink to the Application shall obtain prior authorization from Syncrace and shall be subject to comply with the following obligations:
        </p>
        <ul>
          <li>
            The link shall provide exclusive access to the Application, any reproduction shall be prohibited;
          </li>
          <li>
            No connections with other sites except the homepage of the Application.
          </li>
        </ul>

        {/* Trademarks and Copyright of the Author */}
        <h2>Trademarks and Copyright of the Author</h2>
        <p>
          All parties and users accessing the Application and other related pages, acknowledge and accept the industrial and intellectual property rights of Syncrace. All intellectual property and related rights on materials, including but not limited to brands, logos, trade names, text, images, chats, audio and video, database, and software, are held by Syncrace and are under the exclusive ownership of Novus Americas LLC.
        </p>
        <p>
          Access to the Application and other forms of communication with Syncrace under no circumstances, does grant a waiver, succession, license, or assignment of any rights related to such materials.
        </p>
        <p>
          Material and content made available through or developed by Novus LLC, including but not limited to text, graphics, button icons, images, charts, smartart, audio clips, and data compilations, is the property of Novus LLC and is protected by United States, and international copyright laws. Also, a compilation of such content or derivative products is the exclusive property of Novus LLC and protected by United States and international copyright laws.
        </p>
        <p>
          In addition, the trade name Syncrace, logo, graphics, icons, pictures, and related service names developed or made available through Syncrace are trademarks of Novus LLC with registered seat in United States. Trademarks may not be used by third parties without the prior written consent of Novus LLC and third parties may not associate such trademarks with the services that are not Novus Americas LLC.
        </p>
        <p>
          Trademarks made available through its Application, social media, and media in general, that are not developed or owned by the application are the property of their respective owners and presented made available through the specific legal arrangement.
        </p>
        <p>
          The User agrees to and recognizes all trademarks and intellectual property rights on the Content and/or any other things used on the Application. Entry to the Application shall under no circumstances grant a waiver, transfer, license, or assignment of any rights related to the materials of the Application.
        </p>

        {/* Limitation of Liability */}
        <h2>Limitation of Liability</h2>
        <p>
          The information contained in the Application and/or other connected pages is given with the purpose to be informative, with no express or implied warranty, including but not limited to the implied warranties of merchantability and fitness for a particular purpose.
        </p>
        <p>
          Under no circumstances shall Syncrace be liable for any direct, indirect, incidental, consequential, or punitive damages, including but not limited to the damages for loss of use, data, or profits, arising out of or in connection with the use of or inability to use the Application.
        </p>
        <p>
          Syncrace shall not be liable for any damages resulting from errors, omissions, interruptions, deletions, defects, delays in operation or transmission, or any failure of performance, whether or not caused by events beyond the control of Syncrace.
        </p>

        {/* Governing Law */}
        <h2>Governing Law</h2>
        <p>
          The General Terms of the Application shall be governed by and construed in accordance with the laws of the United States without giving effect to its conflict of laws principles. The courts of the United States shall have exclusive jurisdiction over any disputes arising out of or in connection with the use of the Application.
        </p>
      </div>

      {/* Footer */}
      <footer className="footer mt-5 p-4 text-center">
        <p>© {new Date().getFullYear()} NOVUS LLC. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
