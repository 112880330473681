import React, { useState, useEffect, useReducer } from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import swal from "sweetalert";
import { Row, Col, Card, Table, Modal, Button } from "react-bootstrap";
import ScrollToTop from "../../layouts/ScrollToTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { AiFillFilePdf } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import useEvents from "../../../hooks/useEvents";
import useRaces from "../../../hooks/useRaces";
import useResults from "../../../hooks/useResults";

const initialState = {
  pdfModal: false,
  csvModal: false,
};

const reducer = (state, active) => {
  switch (active.type) {
    case "pdfModal":
      return {
        ...state,
        pdfModal: !state.pdfModal,
      };
    case "csvModal":
      return {
        ...state,
        csvModal: !state.csvModal,
      };

    default:
      return state;
  }
};
const RaceResults = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { eventId, raceId } = useParams();
  const [results, setResults] = useState([]);
  const [raceData, setRaceData] = useState(null);
  const [eventData, setEventData] = useState(null);
  const [loadState, setLoadState] = useState(false);
  const [selectedColumnsPdf, setSelectedColumnsPdf] = useState([]);
  const [selectedColumnsCsv, setSelectedColumnsCsv] = useState([]);
  const navigate = useNavigate();
  const [event, setEvent] = useState([]);

  const { fetchEventById } = useEvents();

  const { fetchRaceByIds } = useRaces();

  const {
    fetchRaceResults,
    generateResultsExcelHandler,
    generateResultsPDFHandler,
  } = useResults();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadState(true);

        const resultsResponse = await fetchRaceResults(raceId);
        const raceResponse = await fetchRaceByIds(raceId, eventId);
        const eventResponse = await fetchEventById(eventId);

        console.log("resultsResponse: ", resultsResponse)
        console.log("raceResponse: ", raceResponse);
        console.log("eventResponse: ", eventResponse);

        setResults(resultsResponse);
        setRaceData(raceResponse.race);
        setEventData(eventResponse);
        // setResults(resultsResponse.data);
        setRaceData(raceResponse.race);
        setEventData(eventResponse.event);

        if (
          raceResponse.race.status !== 1 ||
          raceResponse.race.participants.length === 0
        ) {
          swal({
            title: "Race has no results yet.",
            text: "Please generate some results first and then come back to view them.",
            icon: "info",
            closeOnClickOutside: false,
            className: "text-center-swal",
            buttons: {
              later: {
                text: "Later",
                value: "later",
                className: "btn-later",
              },
              raceDetails: {
                text: "Race Details",
                value: "raceDetails",
                className: "btn-addNew",
              },
            },
          }).then((value) => {
            if (value === "later") {
              navigate(`/events/${eventId}/races`);
            } else if (value === "raceDetails") {
              navigate(`/events/${eventId}/races/${raceId}`);
            }
          });
        }
      } catch (error) {
        console.error("Failed to fetch data!", error);
        swal("Error", "Failed to fetch data!", "error");
      } finally {
        setLoadState(false);
      }
    };

    const fetchEvent = async () => {
      try {
        const response = await fetchEventById(eventId);
        setEvent(response.event);
      } catch (error) {
        swal("Failed!", "Event details error!", "error");
        console.error(error);
      }
    };

    fetchEvent();
    fetchData();
  }, [eventId, raceId]);

  function normalTime(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false, // Use 24-hour format
    };

    return date.toLocaleTimeString("en-US", options);
  }

  const openPdfModal = () => {
    dispatch({ type: "pdfModal" });
  };

  const openCsvModal = () => {
    dispatch({ type: "csvModal" });
  };

  const handleColumnCheckboxChangePdf = (column) => {
    if (selectedColumnsPdf.includes(column)) {
      setSelectedColumnsPdf(selectedColumnsPdf.filter((col) => col !== column));
    } else {
      setSelectedColumnsPdf([...selectedColumnsPdf, column]);
    }
  };

  const handleSelectAllColumnsPdf = () => {
    setSelectedColumnsPdf(headerCells);
  };

  const handleColumnCheckboxChangeCsv = (column) => {
    if (selectedColumnsCsv.includes(column)) {
      setSelectedColumnsCsv(selectedColumnsCsv.filter((col) => col !== column));
    } else {
      setSelectedColumnsCsv([...selectedColumnsCsv, column]);
    }
  };

  const handleSelectAllColumnsCsv = () => {
    setSelectedColumnsCsv(headerCells);
  };

  const predefinedHeaders = [
    "Place",
    "Name",
    "Surname",
    "Gender",
    "Bib",
    "Start",
  ];

  if (!results || results.length === 0 || !results[0].splits) {
    return null;
  }

  const skipFirstSplit = raceData.isGunRace;

  // Create a map to associate split IDs with split times
  const splitTimeMap = new Map();
  results[0].splits.forEach((split) => {
    splitTimeMap.set(split.splitId, split.splitTime);
  });

  const headerCells = [
    ...predefinedHeaders,
    ...(skipFirstSplit
      ? results[0].splitNames.slice(1).map((split) => split.name)
      : results[0].splitNames.map((split) => split.name)),
  ];

  // Add an empty header cell to account for the "Total" header
  headerCells.push("Total Time");

  // Create the table header row
  const headerRow = (
    <tr className="text-center">
      {headerCells.map((headerText, index) => (
        <th
          key={index}
          style={
            headerText === "Registration Date" || "Event"
              ? { width: "160px" }
              : headerText === "Total Time"
                ? { fontWeight: "bold" }
                : {}
          }
        >
          {headerText}
        </th>
      ))}
    </tr>
  );

  const bodyRows = results.map((result, rowIndex) => {
    // Create the body cells for splits for this participant
    const splitCells = (
      skipFirstSplit ? results[0].splitNames.slice(1) : results[0].splitNames
    ).map((splitName) => {
      const participantSplit = result.splits.find(
        (split) => split.splitId === splitName._id
      );
      return participantSplit ? normalTime(participantSplit.splitTime) : "";
    });

    // Calculate the number of additional empty cells required for the "Total" column
    const emptyCellsCount = results[0].splits.length - result.splits.length;

    const date = new Date(result.participant.registrationDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    const formattedHours = hours % 12 || 12;

    const formattedDate = `${day}/${month}/${year}, ${formattedHours}:${minutes} ${ampm}`;

    // Create the body cells
    const bodyCells = [
      result.place !== null ? result.place : "-",
      result.participant.firstName,
      result.participant.lastName,
      result.participant.gender,
      result.bibNumber,
      normalTime(result.startTime),
      ...splitCells,
      // ...Array(emptyCellsCount).fill(""),
      <b>{result.totalSplitTime}</b>,
    ];

    return (
      <tr className="text-center" key={rowIndex}>
        {bodyCells.map((cellText, cellIndex) => (
          <td className="text-center" key={cellIndex}>
            {cellText}
          </td>
        ))}
      </tr>
    );
  });

  const handleDownloadRaceResults = async () => {
    try {
      if (selectedColumnsCsv.length === 0) {
        toast.warn("Please select at least one column to download!");
        return;
      }
      const response = await generateResultsExcelHandler(
        eventId,
        raceId,
        selectedColumnsCsv
      );

      // Create a URL for the blob data
      const url = window.URL.createObjectURL(new Blob([response]));

      // Create a link and click it to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${raceData.raceName}_results.csv`); // Set desired file name and extension
      document.body.appendChild(link);
      link.click();

      // Clean up the URL and remove the link
      URL.revokeObjectURL(url);
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadRaceResultsPDF = async () => {
    try {
      if (selectedColumnsPdf.length === 0) {
        toast.warn("Please select at least one column to download!");
        return;
      }
      const reorderedSelectedColumnsPdf = headerCells.filter((header) =>
        selectedColumnsPdf.includes(header)
      );

      const response = await generateResultsPDFHandler(
        eventId,
        raceId,
        reorderedSelectedColumnsPdf
      );

      console.log("response: ", response)

      // Convert the blob response to a data URL
      const blob = new Blob([response], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      // Create a link and click it to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${raceData.raceName}_results.pdf`); // Set desired file name and extension
      document.body.appendChild(link);
      link.click();

      // Clean up the URL and remove the link
      URL.revokeObjectURL(url);
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      <ToastContainer />

      {loadState && (
        <div className="text-center mt-4">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden"></span>
          </div>
        </div>
      )}
      {raceData.status === 1 && (
        <div className="container-fluid">
          <React.Fragment>
            {!loadState ? (
              <Row>
                <Col lg={12}>
                  <Card>
                    <Card.Header>
                      <Card.Title
                        style={{
                          fontSize: "19px",
                        }}
                      >
                        {" "}
                        <Link to={`/events/${eventId}/races`}>
                          <b>{event.name}</b> Races
                        </Link>{" "}
                        &gt;{" "}
                        <Link to={`/events/${eventId}/races/${raceId}`}>
                          <b>{raceData.raceName}</b>
                        </Link>{" "}
                        Results
                      </Card.Title>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Button
                          onClick={openPdfModal}
                          variant="secondary"
                          className="btn-sm"
                          style={{
                            marginRight: "10px",
                            borderRadius: "5px",
                            backgroundColor: "rgb(192, 18, 18)",
                            padding: "5px",
                            border: "none",
                          }}
                          title="Download Results (.pdf)"
                        >
                          <AiFillFilePdf style={{ fontSize: "x-large" }} />
                        </Button>
                        <Button
                          onClick={openCsvModal}
                          variant="primary"
                          className="btn-sm"
                          title="Download Results (.csv)"
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "green",
                            border: "none",
                            padding: "5px 9px",
                          }}
                        >
                          <FontAwesomeIcon icon={faFileExcel} size="2x" />
                        </Button>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <Table responsive hover>
                        <thead>{headerRow}</thead>
                        <tbody>{bodyRows}</tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            ) : null}
          </React.Fragment>
          <Modal
            className="fade bd-example-modal-md"
            size="md"
            show={state.pdfModal}
            onHide={() => {
              dispatch({ type: "pdfModal" });
            }}
          >
            <Modal.Header>
              {raceData && (
                <Modal.Title>
                  Download <b>{raceData.raceName}</b> Results (PDF)
                </Modal.Title>
              )}
              <Button
                variant=""
                className="btn-close"
                onClick={() => dispatch({ type: "pdfModal" })}
              ></Button>
            </Modal.Header>
            <Modal.Body>
              {raceData && (
                <React.Fragment>
                  <div className="form-group">
                    <div className="row">
                      <div className="form-group mb-3 col-md-12">
                        <h6>Select the columns you want to download:</h6>
                      </div>
                    </div>
                    {headerCells.map((column, index) => (
                      <div className="form-check" key={index}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={column}
                          id={`columnCheckbox${index}`}
                          checked={selectedColumnsPdf.includes(column)}
                          onChange={() => handleColumnCheckboxChangePdf(column)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`columnCheckbox${index}`}
                        >
                          {column}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="text-end">
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={handleSelectAllColumnsPdf}
                    >
                      Select All
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="danger light"
                onClick={() => dispatch({ type: "pdfModal" })}
              >
                Close
              </Button>

              <Button
                variant="primary"
                onClick={() =>
                  handleDownloadRaceResultsPDF(eventData._id, raceData._id)
                }
              >
                Download
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            className="fade bd-example-modal-md"
            size="md"
            show={state.csvModal}
            onHide={() => {
              dispatch({ type: "csvModal" });
            }}
          >
            <Modal.Header>
              {raceData && (
                <Modal.Title>
                  Download <b>{raceData.raceName}</b> Results (CSV)
                </Modal.Title>
              )}
              <Button
                variant=""
                className="btn-close"
                onClick={() => dispatch({ type: "csvModal" })}
              ></Button>
            </Modal.Header>
            <Modal.Body>
              {raceData && (
                <React.Fragment>
                  <div className="form-group">
                    <div className="row">
                      <div className="form-group mb-3 col-md-12">
                        <h6>Select the columns you want to download:</h6>
                      </div>
                    </div>
                    {headerCells.map((column, index) => (
                      <div className="form-check" key={index}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={column}
                          id={`columnCheckbox${index}`}
                          checked={selectedColumnsCsv.includes(column)}
                          onChange={() => handleColumnCheckboxChangeCsv(column)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`columnCheckbox${index}`}
                        >
                          {column}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="text-end">
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={handleSelectAllColumnsCsv}
                    >
                      Select All
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="danger light"
                onClick={() => dispatch({ type: "csvModal" })}
              >
                Close
              </Button>

              <Button variant="primary" onClick={handleDownloadRaceResults}>
                Download
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </React.Fragment>
  );
};

export default RaceResults;
