// import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
// import {PostsReducer, toggleMenu} from './reducers/PostsReducer';
// import thunk from 'redux-thunk';
// import { AuthReducer } from './reducers/AuthReducer';
// import todoReducers from './reducers/Reducers';
// //import { reducer as reduxFormReducer } from 'redux-form';
// const middleware = applyMiddleware(thunk);

// const composeEnhancers =
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const reducers = combineReducers({
//     sideMenu: toggleMenu,
//     posts: PostsReducer,
//     auth: AuthReducer,
// 		todoReducers,
// 	//form: reduxFormReducer,

// });

// //const store = createStore(rootReducers);

// export const store = createStore(reducers,  composeEnhancers(middleware));

import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist"; // Import persistReducer and persistStore
import storage from "redux-persist/lib/storage"; // Import storage (localStorage or sessionStorage)
import { PostsReducer, toggleMenu } from "./reducers/PostsReducer";
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
import todoReducers from "./reducers/Reducers";

const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: "root", // Key for storage
  storage, // Storage type (localStorage or sessionStorage)
  // Add any blacklist or whitelist configuration here if needed
};

const reducers = combineReducers({
  sideMenu: toggleMenu,
  posts: PostsReducer,
  auth: AuthReducer,
  todoReducers,
});

const persistedReducer = persistReducer(persistConfig, reducers); // Wrap reducers with persistConfig

export const store = createStore(
  persistedReducer, // Use the persisted reducer
  composeEnhancers(middleware)
);

export const persistor = persistStore(store); // Create the persisted store
