import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useEvents from "../../../../hooks/useEvents";

const UpcomingBlog = () => {
  const [events, setEvents] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(true);

  const { fetchAllEvents } = useEvents();
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetchAllEvents();
        const allEvents = response.events;

        if (allEvents) {
          // Filter events based on the selected startDate
          const filteredEvents = allEvents.filter((event) => {
            const eventDate = new Date(event.dateTime * 1000);
            return (
              eventDate.getDate() === startDate.getDate() &&
              eventDate.getMonth() === startDate.getMonth() &&
              eventDate.getFullYear() === startDate.getFullYear()
            );
          });
          setEvents(filteredEvents);
        } else {
          // Handle case when no events are returned
          setEvents([]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [startDate]);

  const formatDateAndDay = (eventDate) => {
    const timestampInMilliseconds = eventDate * 1000;
    const dateTime = new Date(timestampInMilliseconds);

    const dayOfWeek = dateTime.toLocaleDateString("en-US", {
      weekday: "short",
    });
    const dayOfMonth = dateTime.getDate().toString().padStart(2, "0");
    const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");

    return { dayOfWeek, dayOfMonth, month };
  };

  const formatTime = (eventDate) => {
    const timestampInMilliseconds = eventDate * 1000;
    const dateTime = new Date(timestampInMilliseconds);
    const time = dateTime.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return time;
  };

  return (
    <React.Fragment>
      {loading ? (
        <div className="text-center mt-4">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden"></span>
          </div>
        </div>
      ) : (
        <div className="card my-calendar">
          <div className="card-body schedules-cal p-2 dz-calender">
            <DatePicker
              selected={startDate}
              className="form-control"
              onChange={(date) => setStartDate(date)}
              dateFormat="MM-dd-yyyy"
              inline
            />
            <div className="events">
              <h6>Events</h6>
              <div
                className="dz-scroll event-scroll"
                style={{ height: "115px" }}
              >
                {events.length > 0 ? (
                  events?.map((event, index) => {
                    const { dayOfWeek, dayOfMonth, month } = formatDateAndDay(
                      event.dateTime
                    );
                    return (
                      <div className={`event-media`} key={index}>
                        <div className="d-flex align-items-center">
                          <div className="event-box">
                            <h5 className="mb-0" style={{ fontSize: "18px" }}>
                              {dayOfMonth}/{month}
                            </h5>
                            <span>{dayOfWeek}</span>
                          </div>
                          <div className="event-data ms-2">
                            <h5 className="mb-0">
                              <Link to={`/events/${event._id}/races`}>
                                {event.name}
                              </Link>
                            </h5>
                          </div>
                        </div>
                        <span>{formatTime(event.dateTime)}</span>
                      </div>
                    );
                  })
                ) : (
                  <h3 style={{ color: "grey", textAlign: "center" }}>
                    No events for this date yet. Choose another date!
                  </h3>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default UpcomingBlog;
