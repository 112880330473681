import {
  getAllTrackers,
  createTracker,
  deleteTracker,
  getTrackerById,
  pinCheck,
  editTracker,
} from "./requests/trackerRequests";
const useTrackers = () => {
  const createTrackerHandler = async (trackerData) => {
    try {
      const response = await createTracker(trackerData);
      return response;
    } catch (error) {
      console.error("Failed to create tracker", error);
      throw error;
    }
  };

  const fetchAllTrackers = async () => {
    try {
      const response = await getAllTrackers();
      return response;
    } catch (error) {
      console.error("Failed to fetch trackers", error);
    }
  };

  const deleteTrackerHandler = async (trackerId) => {
    try {
      const response = await deleteTracker(trackerId);
      return response;
    } catch (error) {
      console.error("Failed to delete tracker", error);
    }
  };

  const fetchTrackerById = async (trackerId) => {
    try {
      const response = await getTrackerById(trackerId);
      return response;
    } catch (error) {
      console.error("Failed to fetch tracker", error);
    }
  };

  const checkPinHandler = async (trackerId, oldPin, newPin) => {
    try {
      const response = await pinCheck(trackerId, oldPin, newPin);
      return response;
    } catch (error) {
      console.error("Failed to check pin", error);
    }
  };

  const editTrackerHandler = async (trackerId, trackerData) => {
    try {
      const response = await editTracker(trackerId, trackerData);
      return response;
    } catch (error) {
      console.error("Failed to edit tracker", error);
    }
  };

  return {
    createTrackerHandler,
    deleteTrackerHandler,
    fetchAllTrackers,
    fetchTrackerById,
    checkPinHandler,
    editTrackerHandler,
  };
};

export default useTrackers;
