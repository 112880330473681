import React, { useState, useEffect, useRef, useMemo } from "react";
import Select from "react-select";
import swal from "sweetalert";
import { Button, Modal, Form, Card, OverlayTrigger } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { useParams, Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "./customCss/xclx.css";
import ScrollToTop from "../../layouts/ScrollToTop";
import useParticipants from "../../../hooks/useParticipants";
import useRaces from "../../../hooks/useRaces";
import { useTable, useSortBy } from "react-table";
import {
  notifyWarning,
  notifyFirstNameWarning,
  notifyLastNameWarning,
  notifyAgeValidation,
  notifyBibValidation,
  notifyGenderWarning,
} from "../../notifyHooks/notifyNewParticipant";
import axios from "axios";

const NewParticipant = () => {
  const { raceId, eventId } = useParams();
  const [firstName, setParticipantFirstName] = useState("");
  const [lastName, setParticipantLastName] = useState("");
  const [email, setParticipantEmail] = useState("");
  const [age, setParticipantAge] = useState("");
  const [gender, setParticipantGender] = useState("");
  const [bib, setParticipantBib] = useState("");
  const [file, setFile] = useState(null);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [race, setRace] = useState([]);
  const inputFile = useRef(null);
  const genders = ["Male", "Female"];
  const [participantCount, setParticipantCount] = useState(0);
  const [capacity, setCapacity] = useState(0);
  const [startingBib, setStartingBib] = useState("");
  const [bibInDb, setBibInDb] = useState(false);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [existingBibs, setExistingBibs] = useState(false);
  const [minExistingBib, setMinExistingBib] = useState(null);
  const [isBibInputVisible, setIsBibInputVisible] = useState(false);
  const [tempBib, setTempBib] = useState("");
  const [selectedParticipantId, setSelectedParticipantId] = useState(null);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [bibNumber, setBibNumber] = useState("");
  const [approveVisible, setApproveVisible] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [nonApprovedParticipants, setNonApprovedParticipants] = useState([]);
  const participants_COLUMNS = [
    {
      Header: "Bib Number",
      accessor: "bibNumber",
    },
    {
      Header: "Name",
      accessor: "firstName",
    },
    {
      Header: "Surname",
      accessor: "lastName",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Age",
      accessor: "age",
    },
    {
      Header: "Gender",
      accessor: "gender",
    },
  ];
  const columns = useMemo(() => participants_COLUMNS, []);

  const tableInstance = useTable({ columns, data }, useSortBy);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const { fetchRaceByIds } = useRaces();
  const {
    addParticipantHandler,
    uploadParticipantsHandler,
    editParticipantBibHandler,
    deleteParticipantHandler,
  } = useParticipants();

  useEffect(() => {
    const fetchRace = async () => {
      try {
        const response = await fetchRaceByIds(raceId, eventId);

        setRace(response.race);
        const participants = response.race.participants
          .filter((participant) => participant.isApproved === false)
          .map((participant) => ({
            ...participant,
            age: participant.age || "N/A",
            email: participant.email || "N/A",
          }));


        setNonApprovedParticipants(participants);
        setData(participants || []);
        if (response.race.bibNumbers.length > 0) {
          setBibInDb(true);
        }
        const { participantCount, capacity } = response;
        setParticipantCount(participantCount);
        setCapacity(capacity);
        setExistingBibs(response.race.bibNumbers);
        if (response.race.status === 1) {
          swal({
            title: "Race is already finished.",
            text: "No more participants can be added anymore.",
            icon: "info",
            closeOnClickOutside: false,
            className: "text-center-swal",
            buttons: {
              raceDetails: {
                text: "Race Details",
                value: "raceDetails",
                className: "btn-addNew",
              },
            },
          }).then((value) => {
            if (value === "raceDetails") {
              navigate(`/events/${eventId}/races/${raceId}`);
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchRace();
  }, [raceId]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleFirstNameParticipantChange = (e) => {
    setParticipantFirstName(e.target.value);
  };

  const handleLastNameParticipantChange = (e) => {
    setParticipantLastName(e.target.value);
  };

  const handleParticipantEmailChange = (e) => {
    setParticipantEmail(e.target.value);
  };

  const handleParticipantAgeChange = (e) => {
    setParticipantAge(e.target.value);
  };

  const handleParticipantGenderChange = (selectedOption) => {
    setParticipantGender(selectedOption);
  };

  const handleParticipantBibChange = (e) => {
    setParticipantBib(e.target.value);
  };

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setIsFileSelected(true);
  };

  const handleReset = () => {
    if (inputFile.current) {
      inputFile.current.value = "";
      inputFile.current.type = "text";
      inputFile.current.type = "file";
      setFile(null);
      setIsFileSelected(false);
    }
  };

  const handleApproveClick = (id) => {
    setCurrentId(id);
    approveParticipant(id);
    setShowModal(true);
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    await confirmApproveParticipant();
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
    setApproveVisible((prev) => ({ ...prev, [currentId]: false }));
    setIsBibInputVisible(false);
  };

  const approveParticipant = async (participantId) => {
    setIsBibInputVisible(true);
    setSelectedParticipantId(participantId);
    setBibNumber("");
    // setApproveVisible({ ...approveVisible, [participantId]: true });
  };

  const confirmApproveParticipant = async () => {
    if (!bibNumber) {
      notifyBibValidation();
      return;
    }
    try {
      const isApproved = true;
      const bib = bibNumber;

      if (bib) {
        if (!/^[0-9]+$/.test(bib)) {
          notifyBibValidation();
          return;
        }
        const existingBib = race.bibNumbers.includes(bib);
        if (existingBib) {
          swal({
            title: `Please try another bib number!`,
            text: `Bib number "${bib}" is already taken!`,
            icon: "warning",
            dangerMode: false,
            className: "text-center-swal",
          });
          return;
        }
      }

      await editParticipantBibHandler(raceId, selectedParticipantId, {
        isApproved,
        bib,
      });

      const response = await fetchRaceByIds(raceId, eventId);
      const updatedParticipants = response.race.participants
        .filter((participant) => participant.isApproved === false)
        .map((participant) => ({
          ...participant,
          age: participant.age || "N/A",
          email: participant.email || "N/A",
        }));
      setNonApprovedParticipants(updatedParticipants || []);
      const updatedRace = response.race;

      // Update the local state with the new participants
      setRace(updatedRace);
      setData(updatedParticipants || []);

      setIsBibInputVisible(false);
      setTempBib("");
      swal(
        "Participant is officially added and removed from the waiting list!",
        {
          icon: "success",
          className: "text-center-swal",
        }
      );
    } catch (error) {
      console.error("Failed to approve participant!", error);
    }
  };

  const rejectParticipant = async (participantId) => {
    try {
      swal({
        title: "Are you sure?",
        text: "Once removed, you will not be able to recover this participant again!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        className: "text-center-swal",
      }).then(async (willDelete) => {
        if (willDelete) {
          await deleteParticipantHandler(raceId, participantId);
          const response = await fetchRaceByIds(raceId, eventId);
          const updatedParticipants = response.race.participants
            .filter((participant) => participant.isApproved === false)
            .map((participant) => ({
              ...participant,
              age: participant.age || "N/A",
              email: participant.email || "N/A",
            }));
          const updatedRace = response.race;

          // Update the local state with the new participants
          setRace(updatedRace);
          setData(updatedParticipants || []);

          swal("Participant is removed from the waiting list!", {
            icon: "success",
          });
        } else {
          swal("Participant is still in the waiting list!");
        }
      });
    } catch (error) {
      console.error("Failed to delete participant!", error);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (firstName.trim() === "") {
      notifyFirstNameWarning();
      return;
    }

    if (lastName.trim() === "") {
      notifyLastNameWarning();
      return;
    }

    if (age && !/^[0-9]+$/.test(age)) {
      notifyAgeValidation();
      return;
    }

    if (bib) {
      if (!/^[0-9]+$/.test(bib)) {
        notifyBibValidation();
        return;
      }
    }

    if (!gender || gender.label.trim() === "") {
      notifyGenderWarning();
      return;
    }

    try {
      // const isFull = participantCount >= capacity;

      // if (isFull) {
      //   swal(
      //     "Full of participants!",
      //     "If you want to add new participants, please edit the race to increase the capacity!",
      //     "warning"
      //   );
      //   return;
      // }

      // Check if the email already exists in the list of participants
      const existingParticipant = race.participants.find(
        (participant) => participant.email === email
      );

      const existingBib = race.bibNumbers.includes(bib);
      if (race.bibNumbers.length > 0) {
        setMinExistingBib(Math.min(...race.bibNumbers));
      }

      const minBibDb = Math.min(...race.bibNumbers) || null;

      if (email !== "") {
        if (existingParticipant) {
          swal({
            title: `Please try another email!`,
            text: `Email "${email}" already exists!`,
            icon: "warning",
            dangerMode: false,
            className: "text-center-swal",
          });
          return;
        }
      }

      if (race.bibNumbers.length > 0) {
        if (bib && bib < minBibDb) {
          swal({
            title: `Please try another bib number!`,
            text: `Bib number "${bib}" is below the minimum existing bib (${minBibDb})!`,
            icon: "warning",
            dangerMode: false,
            className: "text-center-swal",
          });
          return;
        }
      }

      if (existingBib) {
        swal({
          title: `Please try another bib number!`,
          text: `Bib number "${bib}" is already taken!`,
          icon: "warning",
          dangerMode: false,
          className: "text-center-swal",
        });
        return;
      }

      const participantData = {
        firstName: firstName,
        lastName: lastName,
        email,
        age,
        bib,
        gender: gender.label,
      };

      const response = await addParticipantHandler(raceId, participantData);

      swal("Success!", "Participant added successfully!", "success");

      setParticipantFirstName("");
      setParticipantLastName("");
      setParticipantEmail("");
      setParticipantBib("");
      setParticipantAge("");
      setParticipantGender("");
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

      const updatedRace = await fetchRaceByIds(raceId, eventId);
      setRace(updatedRace.race);
    } catch (error) {
      swal("Error", "Failed to add participant!", "error");
    }
  };

  const handleExcelSubmit = async (e) => {
    e.preventDefault();
    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("startingBib", startingBib);

        const response = await uploadParticipantsHandler(raceId, formData);

        if (response.validationErrors && response.validationErrors.length > 0) {
          const formattedValidationErrors = response.validationErrors
            .map((error) => `Note: ${error.error}`)
            .join("\n");

          swal({
            title: "Validation Error",
            text: `${formattedValidationErrors}`,
            icon: "warning",
            content: "pre",
            className: "text-center-swal",
          });
        } else if (
          response.duplicateEmails &&
          response.duplicateEmails.length > 0
        ) {
          const formattedDuplicateEmails = response.duplicateEmails
            .map((email, index) => `${index + 1}. ${email}`)
            .join("\n");

          swal({
            title: "Duplicate Emails Found",
            text: `The following emails are duplicates:\n${formattedDuplicateEmails}\n\nPlease remove the duplicates and try again!`,
            icon: "warning",
            content: "pre",
          });
        } else {
          swal("Success!", "Participants added successfully!", "success");
        }

        setFile(null);
        inputFile.current.value = "";
        setIsFileSelected(false);
      } catch (error) {
        swal("Error", "Failed to upload the file!", "error");
      }
    } else {
      notifyWarning();
    }
  };

  const downloadTemplate = async () => {
    try {
      const response = await axios.get('/download-template', { responseType: 'blob' });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'participants_template.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      <ToastContainer />

      {race.status !== 1 && (
        <div className="container-fluid">
          <Card>
            <Card.Header style={{ height: "75px" }}>
              <Card.Title style={{ fontSize: "19px" }}>
                {race.raceName && (
                  <React.Fragment>
                    <Link to={`/events/${eventId}/races/${raceId}`}>
                      <b>{race.raceName}</b> Race{" "}
                    </Link>{" "}
                    &gt; Add New Participant{" "}
                  </React.Fragment>
                )}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="row">
                <div className="col-8">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Add Single Participant</h3>{" "}
                        {!bibInDb && (
                          <OverlayTrigger
                            trigger="click"
                            placement="left"
                            responsive={true}
                            overlay={
                              <h3
                                className="popover-header"
                                style={{
                                  marginRight: "1.5px",
                                  borderRadius: "5.5px",
                                  fontFamily: "sans-serif",
                                  backgroundColor: "#f3f0ec",
                                  color: "black",
                                  border: "groove",
                                  borderBlockColor: "#3b257a",
                                  borderInlineColor: "#3b257a",
                                }}
                              >
                                If not specified, first participant's bib will
                                be automatically assigned to 100
                              </h3>
                            }
                          >
                            <Button
                              variant="primary"
                              style={{
                                padding: "0rem 0.2rem",
                              }}
                              className="rounded-circle"
                            >
                              <i className="bi bi-info-circle"></i>{" "}
                            </Button>
                          </OverlayTrigger>
                        )}
                      </div>
                      <div className="card-body">
                        <form onSubmit={handleFormSubmit}>
                          <div className="basic-form">
                            <div className="row">
                              <div className="form-group mb-3 col-md-4">
                                <label>Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name"
                                  value={firstName}
                                  onChange={handleFirstNameParticipantChange}
                                />
                              </div>
                              <div className="form-group mb-3 col-md-4">
                                <label>Surname</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name"
                                  value={lastName}
                                  onChange={handleLastNameParticipantChange}
                                />
                              </div>

                              <div className="form-group mb-3 col-md-4">
                                <label>Email (optional)</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Email"
                                  value={email}
                                  onChange={handleParticipantEmailChange}
                                />
                              </div>
                              <div className="form-group mb-3 col-md-4">
                                <label>Age (optional)</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Age"
                                  value={age}
                                  onChange={handleParticipantAgeChange}
                                />
                              </div>
                              <div className="form-group mb-3 col-md-4">
                                <label>Bib Number (optional)</label>
                                <input
                                  type="text"
                                  name="bib"
                                  className="form-control"
                                  placeholder="Bib"
                                  value={bib}
                                  onChange={handleParticipantBibChange}
                                />
                              </div>
                              <div className="form-group mb-3 col-md-4">
                                <label>Gender</label>
                                <Select
                                  style={{
                                    lineHeight: "40px",
                                    color: "#7e7e7e",
                                    paddingLeft: "15px",
                                  }}
                                  value={gender}
                                  onChange={handleParticipantGenderChange}
                                  options={genders.map((gender) => ({
                                    value: gender,
                                    label: gender,
                                  }))}
                                />
                              </div>
                            </div>
                            <div className="text-end mb-2">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                style={{ marginTop: "28px" }}
                              >
                                Add Participant
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div className="col-4">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">
                          Add Participants from Excel
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className="basic-form">
                          <form onSubmit={handleExcelSubmit}>
                            {!isInputVisible && (
                              <div className={`text-center`}>
                                <Button
                                  variant="primary light"
                                  onClick={() => setIsInputVisible(true)}
                                  disabled={bibInDb}
                                  cursor={bibInDb ? "not-allowed" : "auto"}
                                  style={{
                                    pointerEvents: "all",
                                    cursor: bibInDb ? "not-allowed" : "",
                                  }}
                                >
                                  Add by Entering Starting Bib
                                </Button>
                              </div>
                            )}
                            <div
                              className={`form-group mb-3 d-flex align-items-center ${isInputVisible ? "fade-in" : "fade-out"
                                }`}
                            >
                              {bibInDb && (
                                <OverlayTrigger
                                  trigger="click"
                                  placement="right"
                                  responsive={true}
                                  overlay={
                                    <h3
                                      className="popover-header"
                                      style={{
                                        marginLeft: "1.5px",
                                        borderRadius: "5.5px",
                                        fontFamily: "sans-serif",
                                        backgroundColor: "#f3f0ec",
                                        color: "black",
                                        border: "groove",
                                        borderBlockColor: "#3b257a",
                                        borderInlineColor: "#3b257a",
                                      }}
                                    >
                                      Current participants available
                                    </h3>
                                  }
                                >
                                  <Button
                                    variant="primary"
                                    style={{
                                      padding: "0rem 0.2rem",
                                    }}
                                    className="rounded-circle"
                                  >
                                    <i className="bi bi-info-circle"></i>{" "}
                                  </Button>
                                </OverlayTrigger>
                              )}
                              <input
                                type="text"
                                className="form-control col-9"
                                placeholder="Starting Bib"
                                value={startingBib}
                                onChange={(e) => setStartingBib(e.target.value)}
                                disabled={bibInDb}
                                style={{
                                  cursor: bibInDb ? "not-allowed" : "auto",
                                }}
                              />
                              <Button
                                className="col-3 ml-2"
                                variant="secondary light"
                                onClick={() => setIsInputVisible(false)}
                                style={{ borderRadius: "6px" }}
                              >
                                Hide
                              </Button>
                            </div>

                            <div className="form-group mb-3 input-with-clear">
                              <label>Upload Excel File</label>
                              <div className="input-container d-flex align-items-center">
                                <input
                                  type="file"
                                  accept=".xlsx, .xls"
                                  onChange={handleFileUpload}
                                  className="form-control"
                                  ref={inputFile}
                                />
                                <span
                                  className={`clear-file ${isFileSelected ? "visible" : "hidden"
                                    }`}
                                  style={{
                                    marginTop: "-7px",
                                    marginRight: "-10px",
                                  }}
                                  onClick={handleReset}
                                >
                                  &#x2715;
                                </span>
                              </div>
                            </div>
                            <div className="mt-4 mb-2">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                style={{
                                  width: "-webkit-fill-available",
                                  marginBottom: "5px",
                                  // marginTop: "26px",
                                }}
                                onClick={downloadTemplate}
                              >
                                Download Template
                              </button>
                              <button
                                type="submit"
                                className="btn btn-primary"
                                style={{ width: "-webkit-fill-available" }}
                              >
                                Add Participants
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
              {nonApprovedParticipants.length > 0 && (
                <div className="row">
                  <div className="col-12">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">
                            Add Participants who are waiting to join the race
                          </h3>{" "}
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <div className="dataTables_wrapper">
                              <table
                                {...getTableProps()}
                                className="table dataTable display"
                              >
                                <thead>
                                  {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                      {headerGroup.headers.map((column) => (
                                        <th
                                          className="text-center"
                                          {...column.getHeaderProps(
                                            column.getSortByToggleProps()
                                          )}
                                        >
                                          {column.render("Header")}
                                          <span className="ml-1">
                                            {column.isSorted ? (
                                              column.isSortedDesc ? (
                                                <i
                                                  className="fa fa-arrow-down ms-2 fs-14"
                                                  style={{ opacity: "0.7" }}
                                                />
                                              ) : (
                                                <i
                                                  className="fa fa-arrow-up ms-2 fs-14"
                                                  style={{ opacity: "0.7" }}
                                                />
                                              )
                                            ) : (
                                              <i
                                                className="fa fa-sort ms-2 fs-14"
                                                style={{ opacity: "0.3" }}
                                              />
                                            )}
                                          </span>
                                        </th>
                                      ))}
                                      <th className="text-center">Actions</th>
                                    </tr>
                                  ))}
                                </thead>

                                <>
                                  <tbody {...getTableBodyProps()}>
                                    {rows.map((row) => {
                                      prepareRow(row);
                                      const id = row.original._id;
                                      return (
                                        <tr {...row.getRowProps()} key={id}>
                                          {row.cells.map((cell, index) => (
                                            <td
                                              key={index}
                                              className="text-center"
                                              {...cell.getCellProps()}
                                            >
                                              {cell.render("Cell")}
                                            </td>
                                          ))}

                                          <td className="col-1 text-center">
                                            {!approveVisible[id] ? (
                                              <>
                                                <Button
                                                  variant="primary"
                                                  className="shadow btn-xs sharp me-1"
                                                  title="Approve"
                                                  onClick={() =>
                                                    handleApproveClick(id)
                                                  }
                                                >
                                                  <i className="fas fa-check"></i>
                                                </Button>
                                                <Button
                                                  variant="danger"
                                                  className="shadow btn-xs sharp"
                                                  title="Reject"
                                                  onClick={() =>
                                                    rejectParticipant(id)
                                                  }
                                                >
                                                  <i className="fa fa-remove"></i>
                                                </Button>
                                              </>
                                            ) : null}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                  <Modal
                                    show={showModal}
                                    onHide={handleClose}
                                    centered
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>
                                        Confirm Participant
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                      <Form onSubmit={handleConfirm}>
                                        <Form.Group controlId="formBibNumber">
                                          <label>Bib Number</label>
                                          <Form.Control
                                            type="text"
                                            placeholder="Bib"
                                            value={bibNumber}
                                            onChange={(e) =>
                                              setBibNumber(e.target.value)
                                            }
                                            required
                                          />
                                        </Form.Group>
                                        <div className="d-flex justify-content-end">
                                          {" "}
                                          {/* Align buttons to the right */}
                                          <Button
                                            variant="secondary"
                                            onClick={handleClose}
                                            className="mt-3 me-2"
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            variant="primary"
                                            type="submit"
                                            className="mt-3"
                                          >
                                            Confirm
                                          </Button>
                                        </div>
                                      </Form>
                                    </Modal.Body>
                                  </Modal>
                                </>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      )}
    </React.Fragment>
  );
};

export default NewParticipant;
