import React, { useState } from "react";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import "./customCss/NewTracker.css";
import ScrollToTop from "../../layouts/ScrollToTop";
import { Link } from "react-router-dom";
import { createTracker } from "../../../hooks/requests/trackerRequests";
import {
  notifyTrackerFullNameWarning,
  notifyTrackerNameWarning,
  notifyTrackerPinWarning,
  notifyTrackerConfirmPinWarning,
  notifyConfirmPinLengthWarning,
  notifyConfirmPins,
  notifyTrackerNameExists,
  notifyPinNumberValidation,
} from "../../notifyHooks/notifyTrackers";

const NewTracker = () => {
  const [tracker, setTracker] = useState("");
  const [trackerName, setTrackerName] = useState("");
  const [pin, setTrackerPin] = useState("");
  const [confirmPin, setConfirmTrackerPin] = useState("");
  const [showPin, setShowPin] = useState(false); // Separate state for "Pin" visibility
  const [showConfirmPin, setShowConfirmPin] = useState(false); // Separate state for "Confirm Pin" visibility

  const handleTrackerChange = (e) => {
    setTracker(e.target.value);
  };

  const handleTrackerNameChange = (e) => {
    setTrackerName(e.target.value);
  };

  const handleTrackerPinChange = (e) => {
    setTrackerPin(e.target.value);
  };

  const handleConfirmTrackerPinChange = (e) => {
    setConfirmTrackerPin(e.target.value);
  };

  const togglePin = () => {
    setShowPin(!showPin);
  };

  const toggleConfirmPin = () => {
    setShowConfirmPin(!showConfirmPin);
  };

  const generatePin = (length) => {
    const charset = "0123456789";
    let newPin = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      newPin += charset.charAt(randomIndex);
    }
    return newPin;
  };

  const generateNewPin = () => {
    const newPin = generatePin(4);
    setTrackerPin(newPin);
    setShowPin(true);
  };

  const handleForm1Submit = async (e) => {
    e.preventDefault();

    if (tracker.trim() === "") {
      notifyTrackerFullNameWarning();
      return;
    }

    if (trackerName.trim() === "") {
      notifyTrackerNameWarning();
      return;
    }

    if (pin.trim() === "") {
      notifyTrackerPinWarning();
      return;
    }

    if (pin.length < 4) {
      notifyConfirmPinLengthWarning();
      return;
    }

    if (!/^[0-9]+$/.test(pin)) {
      notifyPinNumberValidation();
      return;
    }

    if (confirmPin.trim() === "") {
      notifyTrackerConfirmPinWarning();
      return;
    }

    if (confirmPin !== pin) {
      notifyConfirmPins();
      return;
    }

    try {
      const response = createTracker({
        fullName: tracker,
        trackerName: trackerName,
        pin,
        confirmPin,
      });

      setTracker("");
      setTrackerName("");
      setTrackerPin("");
      setConfirmTrackerPin("");
      setShowPin(false);
      setShowConfirmPin(false);

      swal("Success!", "Tracker created successfully!", "success");
    } catch (error) {
      if (error.response.status === 401) {
        notifyConfirmPins();
      } else if (error.response.status === 409) {
        notifyTrackerNameExists();
      } else {
        swal("Oops", "Failed to create tracker!", error);
      }
    }
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      <ToastContainer />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title" style={{ fontSize: "19px" }}>
                  <Link to={`/trackers`}>All Trackers</Link> &gt; Add New
                  Tracker
                </h3>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <form onSubmit={handleForm1Submit}>
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label>Full Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Full Name"
                          value={tracker}
                          onChange={handleTrackerChange}
                        />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label>Tracker Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Tracker Name"
                          value={trackerName}
                          onChange={handleTrackerNameChange}
                        />
                      </div>
                      <div className="form-group mb-3 col-md-6 input-container">
                        <label>Pin</label>
                        <input
                          type={showPin ? "text" : "password"}
                          className="form-control"
                          placeholder="Pin"
                          name="pin"
                          value={pin}
                          maxLength={4}
                          onChange={handleTrackerPinChange}
                        />
                        <span className="clear-file" onClick={togglePin}>
                          {showPin ? (
                            <i className="bi bi-eye-slash"></i>
                          ) : (
                            <i className="bi bi-eye"></i>
                          )}
                        </span>
                      </div>
                      <div className="form-group mb-3 col-md-6 input-container">
                        <label>Confirm Pin</label>
                        <input
                          type={showConfirmPin ? "text" : "password"}
                          className="form-control"
                          placeholder="Confirm Pin"
                          name="confirmPin"
                          value={confirmPin}
                          maxLength={4}
                          onChange={handleConfirmTrackerPinChange}
                        />
                        <span className="clear-file" onClick={toggleConfirmPin}>
                          {showConfirmPin ? (
                            <i className="bi bi-eye-slash"></i>
                          ) : (
                            <i className="bi bi-eye"></i>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={generateNewPin}
                      >
                        Generate Pin
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ marginLeft: "20px" }}
                      >
                        Create Tracker
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewTracker;
