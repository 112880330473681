import React, { useState } from "react";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import useWebAPI from "../../../hooks/useWebAPI";

const RaceRegistrationForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    gender: "Male",
    age: "",
  });
  const { raceId } = useParams();
  const { addParticipantHandler } = useWebAPI();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await addParticipantHandler(raceId, formData);

      // If the response is successful, show a success message
      swal(
        "Success!",
        "You have successfully requested to join the race!",
        "success"
      );

      // Clear form fields
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        gender: "Male",
        age: "",
      });
    } catch (error) {
      // Handle errors
      console.error("Error creating participant:", error);

      // Check if error response is received and contains the expected status
      if (error.response && error.response.status === 400) {
        swal("Warning", `The email has already been used for registering!`, "warning");
      } else {
        swal(
          "Error",
          "Failed to register for the race. Please try again later.",
          "error"
        );
      }
    }
  };

  return (
    <div className="container my-5 mx-auto">
      <div className="row justify-content-center">
        <div className="col-lg-8 col-sm-12 col-md-10">
          <div className="card shadow">
            <div className="card-body">
              <h2 className="text-center mb-4">Race Registration Form</h2>
              <p className="text-center mb-4">
                Please fill out the form below to register for the race.
              </p>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="firstName" className="form-label">
                    <b>First Name:</b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="lastName" className="form-label">
                    <b>Last Name:</b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    <b>Email (optional):</b>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="age" className="form-label">
                    <b>Age (optional):</b>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="age"
                    name="age"
                    value={formData.age}
                    onChange={handleChange}
                    min="5" // Set the minimum age to 5
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="gender" className="form-label">
                    <b>Gender:</b>
                  </label>
                  <select
                    className="form-control"
                    id="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    required
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="d-flex w-100 justify-content-center">
                  <button
                    type="submit"
                    className="btn-lg btn-block custom-button1"
                  >
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaceRegistrationForm;
