import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, Logout } from "../store/actions/AuthActions";

export async function register(fullName, username, signEmail, signPassword) {
  const postData = {
    fullName,
    username,
    signEmail,
    signPassword,
  };
  try {
    const response = await axios.post(`/api/auth/register`, postData);
    // Assuming your server responds with relevant information on successful registration

    return response;
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = error.response.data.message;
      if (errorMessage === "Email already exists") {
        swal(
          "Oops",
          "Email is already registered. Please use another email.",
          "error"
        );
      } else {
        console.error("Error calling registration API:", error);
        swal(
          "Oops",
          "An error occurred during registration. Please try again.",
          "error"
        );
      }
    } else {
      console.error("Error calling registration API:", error);
      swal(
        "Oops",
        "An error occurred during registration. Please try again.",
        "error"
      );
    }
  }
}

export async function login(email, password) {
  const postData = {
    email,
    password,
  };
  try {
    const response = await axios.post(`/api/auth/login`, postData);
    return response;
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = error.response.data.message;
      if (errorMessage === "Invalid email") {
        swal(
          "Sorry, your email was incorrect.",
          "Please double-check your email.",
          "error"
        );
      } else if (errorMessage === "Invalid password") {
        swal(
          "Sorry, your password was incorrect.",
          "Please double-check your password.",
          "error"
        );
      } else {
        console.error("Error calling login API:", error);
        swal(
          "Oops",
          "An error occurred during login. Please try again.",
          "error"
        );
      }
    } else {
      console.error("Error calling login API:", error);
      swal(
        "Oops",
        "An error occurred during login. Please try again.",
        "error"
      );
    }
  }
}

export function formatError(errorResponse) {
  switch (errorResponse.error.message) {
    case "EMAIL_EXISTS":
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expireDate = new Date(
    new Date().getTime() + tokenDetails.expiresIn * 1000
  );
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    //dispatch(Logout(history));
    dispatch(Logout(navigate));
  }, timer);
}

// export function checkAutoLogin(state, dispatch, navigate) {
//   const tokenDetailsString = state.auth.token;
//   console.log("authservise: ", state.auth.token);
//   let tokenDetails = "";
//   if (!tokenDetailsString) {
//     dispatch(Logout(navigate));
//     return;
//   }

//   tokenDetails = JSON.parse(tokenDetailsString);
//   let expireDate = new Date(tokenDetails.expireDate);
//   let todaysDate = new Date();

//   // if (todaysDate > expireDate) {
//   //   dispatch(Logout(navigate));
//   //   return;
//   // }

//   dispatch(loginConfirmedAction(tokenDetails));

//   const timer = expireDate.getTime() - todaysDate.getTime();
//   runLogoutTimer(dispatch, timer, navigate);
// }

export function isLogin(state) {
  const tokenDetailsString = state.auth.token;

  if (tokenDetailsString) {
    // return false here if token is not expired

    return true;
  } else {
    return false;
  }
}
