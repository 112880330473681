import axios from "axios";

const getAllSplitTimesForRaceGenerateResults = async (raceId) => {
  try {
    const response = await axios.get(`/splitTimes/${raceId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch split times", error);
  }
};

const getAllSplitTimesForEditConfirmedSplits = async (raceId) => {
  try {
    const response = await axios.get(`/edit/splitTimes/${raceId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch split times", error);
  }
};
const updateParticipantId = async (splitMap) => {
  try {
    const response = await axios.put(`/updateParticipantId`, splitMap);
    return response;
  } catch (error) {
    console.error("Failed to update participant id", error);
  }
};
export {
  getAllSplitTimesForRaceGenerateResults,
  getAllSplitTimesForEditConfirmedSplits,
  updateParticipantId,
};
