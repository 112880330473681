import React, { lazy, Suspense, useState } from "react";

/// Components
import Index from "./jsx";
import { connect } from "react-redux";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  Navigate,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react"; // Import PersistGate
import { persistor } from "./store/store"; // Import the store and persistor

// action
// import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
import ResetPassword from "./jsx/pages/ResetPassword";
/// Style
import "./jsx/other/swiper/swiper-bundle.min.css";
import "./jsx/other/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

import VerifyEmail from "./jsx/pages/VerifyEmail";

const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/AuthPage")), 500);
  });
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }
  return ComponentWithRouterProp;
}
// const reducer = (state = {}, action) => {
//   switch (action.type) {
//     case "SET_SUBSCRIPTION":
//       return {
//         ...state,
//         subscription: action.payload,
//       };
//     default:
//       return state;
//   }
// };

function App(props) {
  const [isLoading, setIsLoading] = useState(false);

  let routeblog = (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path={`/change-password/:token`} element={<ResetPassword />} />
      <Route path={`/verify-email/:token`} element={<VerifyEmail />} />
      {/* <Route path={`/packages`} element={<Packages />} /> */}
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  if (props.isAuthenticated) {
    return (
      <PersistGate loading={null} persistor={persistor}>
        {" "}
        {/* Wrap with PersistGate */}
        <React.Fragment>
          <Suspense
            fallback={
              <div id="preloader">
                <div className="sk-three-bounce">
                  <div className="sk-child sk-bounce1"></div>
                  <div className="sk-child sk-bounce2"></div>
                  <div className="sk-child sk-bounce3"></div>
                </div>
              </div>
            }
          >
            <Index />
          </Suspense>
        </React.Fragment>
      </PersistGate>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routeblog}
        </Suspense>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

//export default connect((mapStateToProps)(App));
export default withRouter(connect(mapStateToProps)(App));
