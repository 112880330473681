import {
  getAllEvents,
  getRaceByEventId,
  getAllRacesByEventId,
  addParticipant,
} from "./requests/webRequests";

const useWebAPI = () => {
  const fetchAllEvents = async () => {
    try {
      const fetchedEvents = await getAllEvents();
      return fetchedEvents;
    } catch (error) {
      console.error("Failed to fetch events", error);
    }
  };

  const fetchRaceByEventId = async (eventId) => {
    try {
      const fetchedRace = await getRaceByEventId(eventId);
      return fetchedRace;
    } catch (error) {
      console.error("Failed to fetch event by eventId", error);
    }
  };

  const fetchRacesByEventId = async (eventId) => {
    try {
      const fetchedRaces = await getAllRacesByEventId(eventId);
      return fetchedRaces;
    } catch (error) {
      console.error("Failed to fetch races by eventId", error);
    }
  };
  const addParticipantHandler = async (raceId, formData) => { // Changed formDate to formData
    try {
      const response = await addParticipant(raceId, formData);
      return response;
    } catch (error) {
      console.error("Failed to create participant", error);
      throw error; // Make sure to throw the error
    }
  };

  return {
    fetchAllEvents,
    fetchRaceByEventId,
    fetchRacesByEventId,
    addParticipantHandler,
  };
};

export default useWebAPI;
