import React, { useContext, useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
/// React router dom
import { Routes, Route, Outlet, useNavigate } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";

/// Dashboard
import Home from "./components/Dashboard/Home";
import Login from "./pages/AuthPage";
import EventDetails from "./components/Dashboard/EventDetails";
import RaceDetails from "./components/Dashboard/RaceDetails";
import NewRace from "./components/Dashboard/NewRace";
import NewPartcipant from "./components/Dashboard/NewParticipant";
import Events from "./components/Dashboard/Events";
import RaceGenerateResults from "./components/Dashboard/RaceGenerateResults";
import EditConfirmedSplits from "./components/Dashboard/EditConfirmedSplits";
import NewEvent from "./components/Dashboard/NewEvent";
import RaceResults from "./components/Dashboard/RaceResults";
import Trackers from "./components/Dashboard/Trackers";
import NewTracker from "./components/Dashboard/NewTracker";
import TrackerDetails from "./components/Dashboard/TrackerDetails";
import Results from "./components/Dashboard/Results";
import EditAdmin from "./layouts/nav/EditAdmin";
import ResetPassword from "./pages/ResetPassword";
import VerifyEmail from "./pages/VerifyEmail";
import Packages from "./pages/Packages";
/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
import RechartJs from "./components/charts/rechart";

/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";

/// Pages
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error/Error400";
import Error403 from "./pages/Error/Error403";
import Error404 from "./pages/Error/Error404";
import Error500 from "./pages/Error/Error500";
import Error503 from "./pages/Error/Error503";
import FailedPayment from "./pages/Error/FailedPayment";

import { ThemeContext } from "../context/ThemeContext";

const allroutes = [
  // Dashboard
  // { url: "", component: <Home /> },
  // { url: "payment", component: <PaymentView />},
  { url: "dashboard", component: <Home /> },
  { url: "events/:eventId/races/:raceId/results", component: <RaceResults /> },
  { url: "events/:eventId/races/:raceId/generate-results", component: <RaceGenerateResults /> },
  { url: "events/:eventId/races/new", component: <NewRace /> },
  {
    url: "events/:eventId/races/:raceId/edit-results",
    component: <EditConfirmedSplits />,
  },
  { url: "events/:eventId/races/:raceId", component: <RaceDetails /> },
  { url: "events/:eventId/races", component: <EventDetails /> },
  { url: "events/:eventId/races/:raceId/new-participant", component: <NewPartcipant /> },
  { url: "events", component: <Events /> },
  { url: "events/new", component: <NewEvent /> },
  { url: "trackers", component: <Trackers /> },
  { url: "trackers/new", component: <NewTracker /> },
  { url: "trackers/:trackerId", component: <TrackerDetails /> },
  { url: "results", component: <Results /> },
 
  { url: "edit-admin", component: <EditAdmin /> },
  // { url: "packages", component: <Packages />},

  // Chart
  { url: "chart-sparkline", component: <SparklineChart /> },
  { url: "chart-chartjs", component: <ChartJs /> },
  { url: "chart-rechart", component: <RechartJs /> },

  // table
  { url: "table-sorting", component: <SortingTable /> },
];

function NotFound() {
  const url = allroutes.map((route) => route.url);
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  if (url.indexOf(path) <= 0) {
    return <Error404 />;
  }
}

const Markup = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/page-lock-screen" element={<LockScreen />} />
        <Route path="/page-error-400" element={<Error400 />} />
        <Route path="/page-error-403" element={<Error403 />} />
        <Route path="/page-error-404" element={<Error404 />} />
        <Route path="/page-error-500" element={<Error500 />} />
        <Route path="/page-error-503" element={<Error503 />} />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path={`/change-password/:token`} element={<ResetPassword />} />
        <Route path={`/verify-email/:token`} element={<VerifyEmail />} />
        <Route path={`/packages`} element={<Packages />} />
        <Route path={`/failed-payment`} element={<FailedPayment />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      <ScrollToTop />
    </React.Fragment>
  );
};

function MainLayout() {
  const { sidebariconHover } = useContext(ThemeContext);
  const [contentHeight, setContentHeight] = useState(0);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Function to update content height when window is resized
    const updateContentHeight = () => {
      setContentHeight(window.innerHeight - 75); // Adjusted offset
    };

    // Call the function initially
    updateContentHeight();

    // Add event listener to update content height on window resize
    window.addEventListener("resize", updateContentHeight);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateContentHeight);
    };
  }, []); // Empty dependency array ensures the effect runs only once on component mount
  const checkSubscriptionStatus = useCallback(async () => {
    try {
      const response = await fetch("/check-subscription");
      if (response.status === 200) {
        // Subscription is active, you can proceed with your logic
      } else if (response.status === 403) {
        // Subscription not active, redirect to login
        navigate("/packages");
      } else {
        // Handle other HTTP status codes or unexpected responses
        console.error("Unexpected response:", response);
        navigate("/error");
      }
    } catch (error) {
      console.error("Error checking subscription:", error);
      // Handle the error (e.g., redirect to an error page)
      navigate("/error");
    } finally {
      setIsLoading(false); // Set loading state to false when the check is complete
    }
  }, [navigate]);

  useEffect(() => {
    checkSubscriptionStatus();
  }, [checkSubscriptionStatus]);

  const sideMenu = useSelector((state) => state.sideMenu);
  // const handleToogle = () => {
  //   dispatch(navtoggle());
  // };
  return (
    <div
      id="main-wrapper"
      className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${
        sideMenu ? "menu-toggle" : ""
      }`}
    >
      {isLoading ? (
        // Show loading indicator while the page is loading
        // Footer is not rendered here because it is not needed (if rendered, after <Outlet /> </div> ...)
        <div></div>
      ) : (
        // Render the actual content when the page is loaded
        <React.Fragment>
          <Nav />
          <div
            className="content-body"
            style={{ minHeight: contentHeight }} // changed from 75 to contentHeight
          >
            <Outlet />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default Markup;