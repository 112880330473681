import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAdmins from "../../hooks/useAdmins";
const ResetPassword = () => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isReady, setIsReady] = useState(false);
  const { token } = useParams();

  let errorsObj = {
    newPassword: "",
    confirmPassword: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const { resetPasswordHandler, resetPasswordChangeHandler } = useAdmins();
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await resetPasswordHandler(token);

        if (response.status === 200) {
          setIsReady(true);
        }
      } catch (error) {
        if (error.response.status === 401) {
          swal({
            title: "Error",
            text: "Token has expired.",
            icon: "error",
            buttons: {
              cancel: "OK",
            },
          }).then(() => {
            // Redirect to admin/login
            navigate("/admin/login");
          });
        }
        if (error.response.status === 404) {
          swal({
            title: "Error",
            text: "User not found.",
            icon: "error",
            buttons: ["OK"],
          }).then(() => {
            // Redirect to admin/login
            navigate("/admin/login");
          });
        }
        if (error.response.status === 400) {
          swal({
            title: "Error",
            text: "This is not the right URL.",
            icon: "error",
            buttons: ["OK"],
          }).then(() => {
            // Redirect to admin/login
            navigate("/admin/login");
          });
        }
      }
    };

    checkToken();
  }, [token]);

  const onSubmit = async (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };

    if (!newPassword) {
      errorObj.newPassword = "New Password is Required";
      error = true;
    }

    if (newPassword && newPassword.length < 6) {
      errorObj.newPassword = "New password must be at least 6 characters";
      error = true;
    }

    if (newPassword && !/[A-Z]/.test(newPassword)) {
      errorObj.newPassword =
        "New password must contain at least one uppercase letter";
      error = true;
    }

    if (!confirmPassword) {
      errorObj.confirmPassword = "Confirm Password is Required";
      error = true;
    }

    if (confirmPassword && confirmPassword !== newPassword) {
      errorObj.confirmPassword = "Passwords do not match";
      errorObj.newPassword = "Passwords do not match";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    try {
      const response = await resetPasswordChangeHandler({
        confirmPassword,
        newPassword,
        token,
      });

      if (response.status === 200) {
        swal({
          title: "Success!",
          text: "Password changed successfully! Please log in with your new credentials.",
          icon: "success",
          className: "text-center-swal",
        })
        navigate("/login");
      }
    } catch (error) {
      swal("Oops", "Failed to change password!", "error");
      console.error("Error changing password:", error);
    }
  };
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        {!isReady ? (
          <div className="text-center mt-4">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden"></span>
            </div>
          </div>
        ) : (
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <h3 className="text-center mb-4">Reset Password</h3>
                      <form onSubmit={(e) => onSubmit(e)}>
                        <div className="form-group">
                          <label>
                            <strong>New Password</strong>
                          </label>
                          <input
                            type="password"
                            className={`form-control ${errors.newPassword ? "border-danger" : ""
                              }`}
                            placeholder="Enter your new password"
                            onChange={(e) => setNewPassword(e.target.value)}
                            value={newPassword}
                          />
                          {errors.newPassword && (
                            <div className="text-danger fs-12">
                              {errors.newPassword}
                            </div>
                          )}
                          <label style={{ marginTop: "15px" }}>
                            <strong>Confirm Password</strong>
                          </label>
                          <input
                            type="password"
                            className={`form-control ${errors.confirmPassword ? "border-danger" : ""
                              }`}
                            placeholder="Confirm your new password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                          />
                          {errors.confirmPassword && (
                            <div className="text-danger fs-12">
                              {errors.confirmPassword}
                            </div>
                          )}
                        </div>

                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            Reset your password
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
