import React, { Fragment, useState, useEffect, useReducer } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { Row, Col, Button, Card, Table, Modal } from "react-bootstrap";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "../../layouts/ScrollToTop";
import "./customCss/xclx.css";
import useTrackers from "../../../hooks/useTrackers";
import {
  notifyOldPinWarning,
  notifyTrackerFullNameWarning,
  notifyTrackerNameWarning,
  notifyTrackerPinWarning,
  notifyTrackerConfirmPinWarning,
  notifyConfirmPinLengthWarning,
  notifyConfirmPinWarning,
  notifyConfirmPins,
  notifyTrackerNameExists,
  notifyPinNumberValidation,
  notifyNewPinWarning,
  notifyOldPinLengthWarning,
  notifyNewPinLengthWarning,
  notifyOldPinWrongWarning,
  notifyPinSameWarning,
  notifyNewPinNumberValidation,
  notifySamePinsWarning,
} from "../../notifyHooks/notifyTrackers";

const initialState = {
  editTracker: false,
  createTracker: false,
  selectedTracker: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "editTracker":
      return {
        ...state,
        editTracker: !state.editTracker,
        selectedTracker: action.payload || null,
      };
    case "createTracker":
      return {
        ...state,
        createTracker: !state.createTracker,
      };

    default:
      return state;
  }
};

const Trackers = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [trackers, setTrackers] = useState([]);
  const [selectedTracker, setSelectedTracker] = useState();
  const [editedTrackerData, setEditedTrackerData] = useState({
    fullName: "",
    trackerName: "",
    pin: "",
    oldPin: "",
    confirmNewPin: "",
  });
  const [loadState, setLoadState] = useState(false);
  const [showActualPin, setShowActualPin] = useState(false);
  const [showNewPin, setShowNewPin] = useState(false);
  const [showConfirmNewPin, setShowConfirmNewPin] = useState(false);
  const [tracker, setTracker] = useState("");
  const [trackerName, setTrackerName] = useState("");
  const [pin, setTrackerPin] = useState("");
  const [confirmPin, setConfirmTrackerPin] = useState("");
  const [showPin, setShowPin] = useState(false); // Separate state for "Pin" visibility
  const [showConfirmPin, setShowConfirmPin] = useState(false); // Separate state for "Confirm Pin" visibility
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const navigate = useNavigate();

  const {
    fetchAllTrackers,
    createTrackerHandler,
    deleteTrackerHandler,
    fetchTrackerById,
    checkPinHandler,
  } = useTrackers();
  useEffect(() => {
    const fetchTrackers = async () => {
      try {
        setLoadState(true);
        const response = await fetchAllTrackers();

        if (Array.isArray(response.trackers)) {
          setTrackers(response.trackers);
        } else if (response.message) {
          setTrackers([]);
          swal({
            title: "No trackers found!",
            text: response.message,
            icon: "info",
            buttons: {
              later: {
                text: "Later",
                value: "later",
                className: "btn-later",
              },
              addNewTracker: {
                text: "Add New Tracker",
                value: "addNewTracker",
                className: "btn-addNew",
              },
            },
            closeOnClickOutside: false,
          }).then((value) => {
            if (value === "later") {
              navigate(`/dashboard`);
            } else if (value === "addNewTracker") {
              navigate(`/trackers/new`);
            }
          });
        } else {
          setTrackers([]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadState(false);
      }
    };

    fetchTrackers();
  }, []);

  const handleTrackerChange = (e) => {
    setTracker(e.target.value);
  };

  const handleTrackerNameChange = (e) => {
    setTrackerName(e.target.value);
  };

  const handleTrackerPinChange = (e) => {
    setTrackerPin(e.target.value);
  };

  const handleConfirmTrackerPinChange = (e) => {
    setConfirmTrackerPin(e.target.value);
  };

  const togglePin = () => {
    setShowPin(!showPin);
  };

  const toggleConfirmPin = () => {
    setShowConfirmPin(!showConfirmPin);
  };

  const toggleActualPin = () => {
    setShowActualPin(!showActualPin);
  };

  const toggleNewPin = () => {
    setShowNewPin(!showNewPin);
  };

  const toggleConfirmNewPin = () => {
    setShowConfirmNewPin(!showConfirmNewPin);
  };

  const generatePin = (length) => {
    const charset = "0123456789";
    let newPin = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      newPin += charset.charAt(randomIndex);
    }
    return newPin;
  };

  const generateNewPin = () => {
    const newPin = generatePin(4);
    setTrackerPin(newPin);
    setShowPin(true); // Show the "Pin" input field
  };

  const handleCreateTracker = async (e) => {
    if (tracker.trim() === "") {
      notifyTrackerFullNameWarning();
      return;
    }

    if (trackerName.trim() === "") {
      notifyTrackerNameWarning();
      return;
    }

    if (pin.trim() === "") {
      notifyTrackerPinWarning();
      return;
    }

    if (pin.length < 4) {
      notifyConfirmPinLengthWarning();
      return;
    }

    if (!/^[0-9]+$/.test(pin)) {
      notifyPinNumberValidation();
      return;
    }

    if (confirmPin.trim() === "") {
      notifyTrackerConfirmPinWarning();
      return;
    }

    if (confirmPin !== pin) {
      notifyConfirmPins();
      return;
    }

    try {
      const response = await createTrackerHandler({
        fullName: tracker,
        trackerName: trackerName,
        pin: pin,
        confirmPin: confirmPin,
      });

      setTracker("");
      setTrackerName("");
      setTrackerPin("");
      setConfirmTrackerPin("");
      setShowPin(false);
      setShowConfirmPin(false);
      dispatch({ type: "createTracker" });

      swal("Success!", "Tracker created successfully!", "success");
      const updatedTrackers = await fetchAllTrackers();
      setTrackers(updatedTrackers.trackers);
    } catch (error) {
      if (error.statusCode === 400) {
        notifyConfirmPins();
      } else if (error.statusCode === 409) {
        notifyTrackerNameExists();
      } else {
        swal("Oops", "Failed to create tracker!", error);
      }
    }
  };

  const clearNewTrackerFields = () => {
    setTracker("");
    setTrackerName("");
    setTrackerPin("");
    setConfirmTrackerPin("");
    setShowPin(false);
    setShowConfirmPin(false);
  };

  const handleTrackerDelete = async (trackerId) => {
    try {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this tracker!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        className: "text-center-swal",
      }).then(async (willDelete) => {
        if (willDelete) {
          // Send a DELETE request to the server
          await deleteTrackerHandler(trackerId);

          // Update the state to remove the deleted tracker from local state
          setTrackers((prevTrackers) =>
            prevTrackers.filter((tracker) => tracker._id !== trackerId)
          );

          // Show a success SweetAlert if the tracker is deleted
          swal("Tracker has been deleted!", {
            icon: "success",
          });
        } else {
          // Show a message if the tracker cancels the delete action
          swal("Tracker is still active!");
        }
      });
    } catch (error) {
      console.error("Failed to delete tracker!", error);
      // Handle error cases
    }
  };

  const fetchTrackerByIdSecond = async (trackerId) => {
    try {
      // Make a GET request to your backend API endpoint for fetching tracker by ID
      const response = await fetchTrackerById(trackerId);

      if (response.tracker) {
        // Set initial state for full name and trackerName
        setEditedTrackerData({
          fullName: response.tracker.fullName,
          trackerName: response.tracker.trackerName,
          pin: "",
          oldPin: "",
          confirmNewPin: "",
        });
      }

      return response;
    } catch (error) {
      console.error("Failed to fetch tracker data for editing", error);
      // Handle errors if the request fails
    }
  };

  const handleTrackerEdit = async (trackerId) => {
    try {
      const response = await fetchTrackerByIdSecond(trackerId);

      if (response.tracker) {
        const selectedTrackerData = response.tracker;
        setEditedTrackerData({
          ...selectedTrackerData,
          pin: "",
          oldPin: "",
          confirmNewPin: "",
        });

        // Open the modal
        dispatch({ type: "editTracker", payload: response.tracker });
        setSelectedTracker(selectedTrackerData);
      } else {
        // Handle the case where the tracker is not found
        console.error("tracker not found");
      }
    } catch (error) {
      console.error("Failed to fetch tracker data for editing", error);
    }
  };

  const handleTrackerCreateModal = async (trackerId) => {
    try {
      dispatch({ type: "createTracker" });
    } catch (error) {
      console.error("Failed to fetch tracker data for editing", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedTrackerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveChanges = async () => {
    setButtonIsLoading(true);

    try {
      const editedData = {
        _id: selectedTracker._id,
        fullName: editedTrackerData.fullName,
        trackerName: editedTrackerData.trackerName,
      };

      if (
        editedTrackerData.oldPin ||
        editedTrackerData.pin ||
        editedTrackerData.confirmNewPin
      ) {
        if (!editedTrackerData.oldPin) {
          notifyOldPinWarning();
          return;
        }

        if (editedTrackerData.oldPin.length < 4) {
          notifyOldPinLengthWarning();
          return;
        }

        if (!editedTrackerData.pin) {
          notifyNewPinWarning();
          return;
        }

        if (editedTrackerData.pin.length < 4) {
          notifyNewPinLengthWarning();
          return;
        }

        if (!/^[0-9]+$/.test(editedTrackerData.pin)) {
          notifyNewPinNumberValidation();
          return;
        }

        if (!editedTrackerData.confirmNewPin) {
          notifyConfirmPinWarning();
          return;
        }

        if (editedTrackerData.confirmNewPin !== editedTrackerData.pin) {
          notifySamePinsWarning();
          return;
        }

        // Send a POST request to compare the old pin with the actual pin on the backend
        const pinCheckResponse = await axios.post(
          `/tracker/${selectedTracker._id}/check-pin`,
          { oldPin: editedTrackerData.oldPin, newPin: editedTrackerData.pin }
        );
        // const pinCheckResponse = await checkPinHandler(selectedTracker._id, editedTrackerData.oldPin, editedTrackerData.pin);

        editedData.oldPin = editedTrackerData.oldPin;
        editedData.pin = editedTrackerData.pin;
      }

      // Send a PUT request to update the tracker's pin on the backend using editedTrackerData
      await axios.put(`/tracker/${selectedTracker._id}`, editedData);

      // Update the tracker's information on the frontend
      setTrackers((prevTrackers) =>
        prevTrackers?.map((tracker) =>
          tracker._id === selectedTracker._id ? editedData : tracker
        )
      );

      // After successful update, close the modal or perform any other actions
      dispatch({ type: "editTracker" });

      setSelectedTracker(null);

      swal("Tracker has been updated!", {
        icon: "success",
      });
    } catch (error) {
      // Handle other errors
      if (error.response && error.response.status === 401) {
        notifyOldPinWrongWarning();
      } else if (error.response.status === 409) {
        notifyPinSameWarning();
      } else {
        console.error("Failed to update tracker", error);
        swal("Oops", "Failed to update tracker!", "error");
      }
    } finally {
      setButtonIsLoading(false);
    }
  };

  return (
    <Fragment>
      <ScrollToTop />
      <ToastContainer />

      <div className="container-fluid">
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title style={{ fontSize: "19px" }}>
                  All Trackers
                </Card.Title>
                <Button variant="primary" onClick={handleTrackerCreateModal}>
                  Add New Tracker
                </Button>
              </Card.Header>
              <Card.Body>
                {loadState && (
                  <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                )}
                {!loadState ? (
                  <Table responsive className="table">
                    <thead>
                      <tr>
                        <th className="text-center">
                          <strong>#</strong>
                        </th>
                        <th className="text-center">
                          <strong>FULL NAME</strong>
                        </th>
                        <th className="text-center">
                          <strong>TRACKER NAME</strong>
                        </th>
                        <th className="text-center">
                          <strong>PROFILE</strong>
                        </th>
                        <th className="text-end">
                          <strong>ACTION</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {trackers?.map((tracker, index) => (
                        <tr key={index}>
                          <td className="text-center">
                            <strong>{index + 1}</strong>
                          </td>
                          <td className="text-center">{tracker.fullName}</td>
                          <td className="text-center">{tracker.trackerName}</td>
                          <td className="text-center">
                            {
                              <NavLink to={`/trackers/${tracker._id}`}>
                                <Button className="btn btn-primary btn-xs btn btn-info light">
                                  View Tracker
                                </Button>
                              </NavLink>
                            }
                          </td>
                          <td>
                            <div className="d-flex justify-content-end !important">
                              <Button
                                variant="primary"
                                className="shadow btn-xs sharp me-1"
                                onClick={() => handleTrackerEdit(tracker._id)}
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </Button>
                              <Button
                                variant="danger"
                                className="shadow btn-xs sharp"
                                onClick={() => handleTrackerDelete(tracker._id)}
                              >
                                <i className="fa fa-trash"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : null}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modal
          className="fade"
          show={state.editTracker}
          onHide={() => {
            dispatch({ type: "editTracker" });
            setSelectedTracker(null);
          }}
        >
          <Modal.Header>
            <Modal.Title>Edit Tracker</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => dispatch({ type: "editTracker" })}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            {selectedTracker ? (
              <React.Fragment>
                <div className="form-group mb-3 col-md-12">
                  <label className="form-group-text w-25">
                    <b>Full Name</b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={editedTrackerData.fullName}
                    onChange={handleInputChange}
                    name="fullName"
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label className="form-group-text w-25">
                    <b>Tracker Name</b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={editedTrackerData.trackerName}
                    onChange={handleInputChange}
                    name="trackerName"
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label className="form-group-text w-25">
                    <b>Actual Pin</b>
                  </label>
                  <input
                    type={showActualPin ? "text" : "password"}
                    className="form-control"
                    value={editedTrackerData.oldPin}
                    onChange={handleInputChange}
                    maxLength={4}
                    name="oldPin"
                  />
                  <span className="clear-file" onClick={toggleActualPin}>
                    {showActualPin ? (
                      <i className="bi bi-eye-slash"></i>
                    ) : (
                      <i className="bi bi-eye"></i>
                    )}
                  </span>
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label className="form-group-text w-50">
                    <b>New Pin</b>
                  </label>
                  <input
                    type={showNewPin ? "text" : "password"}
                    className="form-control"
                    value={editedTrackerData.pin}
                    onChange={handleInputChange}
                    maxLength={4}
                    name="pin"
                  />
                  <span className="clear-file" onClick={toggleNewPin}>
                    {showNewPin ? (
                      <i className="bi bi-eye-slash"></i>
                    ) : (
                      <i className="bi bi-eye"></i>
                    )}
                  </span>
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label className="form-group-text w-50">
                    <b>Confirm New Pin</b>
                  </label>
                  <input
                    type={showConfirmNewPin ? "text" : "password"}
                    className="form-control"
                    name="confirmNewPin"
                    value={editedTrackerData.confirmNewPin}
                    maxLength={4}
                    onChange={handleInputChange}
                  />
                  <span className="clear-file" onClick={toggleConfirmNewPin}>
                    {showConfirmNewPin ? (
                      <i className="bi bi-eye-slash"></i>
                    ) : (
                      <i className="bi bi-eye"></i>
                    )}
                  </span>
                </div>
              </React.Fragment>
            ) : (
              <p>No tracker selected.</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger light"
              onClick={() => dispatch({ type: "editTracker" })}
            >
              Close
            </Button>
            <Button
              variant="primary mr-3"
              disabled={buttonIsLoading}
              style={{
                pointerEvents: "all",
                cursor: buttonIsLoading ? "not-allowed" : "",
              }}
              onClick={() => handleSaveChanges(editedTrackerData)}
            >
              {buttonIsLoading ? "Saving Changes..." : "Save Changes"}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="fade bd-example-modal-lg"
          size="lg"
          show={state.createTracker}
          onHide={() => {
            dispatch({ type: "createTracker" });
            setSelectedTracker(null);
            clearNewTrackerFields();
          }}
        >
          <Modal.Header>
            <Modal.Title>Create New Tracker</Modal.Title>
            <Button
              variant="lg"
              className="btn-close"
              onClick={() => dispatch({ type: "createTracker" })}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <React.Fragment>
              <div className="form-group">
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>Full Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={tracker}
                      onChange={handleTrackerChange}
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Tracker Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Tracker Name"
                      value={trackerName}
                      onChange={handleTrackerNameChange}
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Pin</label>
                    <input
                      type={showPin ? "text" : "password"}
                      className="form-control"
                      placeholder="Pin"
                      name="pin"
                      value={pin}
                      maxLength={4}
                      onChange={handleTrackerPinChange}
                    />
                    <span className="clear-file" onClick={togglePin}>
                      {showPin ? (
                        <i className="bi bi-eye-slash"></i>
                      ) : (
                        <i className="bi bi-eye"></i>
                      )}
                    </span>
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Confirm Pin</label>
                    <input
                      type={showConfirmPin ? "text" : "password"}
                      className="form-control"
                      placeholder="Confirm Pin"
                      name="confirmPin"
                      value={confirmPin}
                      maxLength={4}
                      onChange={handleConfirmTrackerPinChange}
                    />
                    <span className="clear-file" onClick={toggleConfirmPin}>
                      {showConfirmPin ? (
                        <i className="bi bi-eye-slash"></i>
                      ) : (
                        <i className="bi bi-eye"></i>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={generateNewPin}
                >
                  Generate Pin
                </button>
              </div>
            </React.Fragment>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger light"
              onClick={() => {
                dispatch({ type: "createTracker" });
                clearNewTrackerFields();
              }}
            >
              Close
            </Button>
            <Button variant="primary" onClick={() => handleCreateTracker()}>
              Create Tracker
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Fragment>
  );
};
export default Trackers;
