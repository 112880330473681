import { cancelSubscription, getAllProducts } from "./requests/paymentRequests";

const usePayments = () => {
    const cancelSubscriptionHandler = async () => {
        try {
            const response = await cancelSubscription();
            return response;
        }
        catch (error) {
            console.error("Failed to cancel subscription", error);
        }
    }
    const fetchAllPayments = async () => {
        try {
            const response = await getAllProducts();
            return response;
        }
        catch (error) {
            console.error("Failed to fetch payments", error);
        }
    }
    return { cancelSubscriptionHandler, fetchAllPayments };
}   
export default usePayments;