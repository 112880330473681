import emailjs from "@emailjs/browser";
import React, { useRef, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/style.css"; // Your custom styles
import stopWatch from "./stopwatch.png";
import recordTime from "./recordtime.png";
import easyUse from "./easyuse.png";
import test1 from "./2k.jpg";
import test2 from "./3k.jpg";
import test3 from "./4k.jpg";
import EventDetailsModal from "./EventDetailsModal"; // Import the EventDetailsModal component
import useWebAPI from "../../../hooks/useWebAPI";
import raceLogo from "../../constant/logo-2.png";
import { Helmet } from "react-helmet";
import axios from "axios";
import swal from "sweetalert";

const Index = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [events, setEvents] = useState([{}]);
  const [selectedEvent, setSelectedEvent] = useState(null); // State to track selected event
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const images = [test1, test2, test3];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    email: "",
  });

  const { fetchAllEvents } = useWebAPI();

  const handleSeeDetails = (event) => {
    setSelectedEvent(event); // Set the selected event
    setShowModal(true); // Show the modal
  };

  // const handleApply = () => {
  //   // Redirect to RaceRegistrationForm with selected event ID
  //   // Implement the redirection logic based on your router setup
  // };

  useEffect(() => {
    // Fetch data from the server
    const fetchData = async () => {
      try {
        const response = await fetchAllEvents();
        setEvents(response.events);
      } catch (error) {
        console.error("Failed to fetch events:", error);
      }
    };

    fetchData();
  }, []);

  const nextSlide = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateEmail = (email) => {
    // A simple email validation regex
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  function normalDate(unixTime) {
    const timestampInMilliseconds = unixTime * 1000;
    const dateTime = new Date(timestampInMilliseconds);

    const day = dateTime.getDate().toString().padStart(2, "0");
    const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
    const year = dateTime.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }

  const form = useRef();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("public/api/send-mail", formData); // Assuming the backend runs on port 5000

      if (response.status === 200) {
        // Handle success
        swal("Success!", "Your message has been sent successfully!", "success");
        // Clear form fields
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      } else {
        // Handle failure
        swal("Error!", "Failed to send message. Please try again later.", "error");
      }
    } catch (error) {
      // Handle error
      console.error("Error sending message:", error);
      alert("Failed to send message. Please try again later.");
    }
  };

  useEffect(() => {
    // Auto-slide every 5 seconds (adjust the interval as needed)
    const intervalId = setInterval(() => {
      nextSlide();
    }, 5000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [currentIndex]);

  return (
    <div style={{ backgroundColor: "rgb(243, 240, 236)" }}>
      <Helmet>
        <link
          rel="canonical"
          href="https://getbootstrap.com/docs/5.0/examples/carousel/"
        />
        <link
          href="/docs/5.0/dist/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
          crossorigin="anonymous"
        />
      </Helmet>

      {/* Navigation Bar */}
      <nav className={`nav1 ${isMobileMenuOpen ? "mobile-menu-open" : ""}`}>
        <img
          src={raceLogo}
          className="logo"
          alt="Race Logo"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
          }}
        />
        <div
          className={`menu-icon ${isMobileMenuOpen ? "open" : ""}`}
          onClick={toggleMobileMenu}
        >
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
        <div className={`menu-items ${isMobileMenuOpen ? "active" : ""}`}>
          <a href="#home">Home</a>
          <a href="#features">Features</a>
          <a href="#gallery">Gallery</a>
          <a href="#pricing">Pricing</a>
          <a href="#events">Events</a>
          <a href="#contact">Contact</a>
        </div>
      </nav>
      <hr className="section-divider1" />

      {/* Home Section */}
      <div id="home" className="home-section1 text-center p-5">
        <div className="content1">
          <h1 className="mb-4 display-6">Welcome to Our Innovative Racing Platform</h1>
          <p className="mb-4">
            <b>Gear up for an exhilarating experience!</b> Discover the power of our advanced tools designed to enhance your racing experience and optimize performance. Unleash your potential and explore endless possibilities on the track with our state-of-the-art technology.
          </p>

          {/* Login and Register Buttons */}
          <div className="buttons-container1">
            <a
              href="/admin/login"
              className="btn btn-primary me-2 advanced-btn1 custom-button"
            >
              Login
            </a>
            {/* <a
              href="/admin/login?register=true"
              className="btn btn-secondary advanced-btn1"
            > 
              Register
            </a> */}
          </div>
        </div>
      </div>
      <hr className="section-divider1" />


      {/* Features Section */}
      <div id="features" className="features-section1 mt-5 p-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="text-gray-700 mb-4 fw-bold display-6">
                Discover the Power of Our Racing Trackers App
              </h2>
              <p className="text-gray-700">
                Revolutionize your racing experience with our app. Here's what
                you can do:
              </p>
              <br />
              <br />
            </div>
          </div>
          <div className="row">
            {/* First Process */}
            <div className="col-md-4 text-center">
              <img
                src={stopWatch}
                alt="Process 1"
                className="img-fluid mb-3 custom-img1"
              />
              <h3 className="text-color11 mb-3">Track Performance</h3>
              <p className="text-color11">
                Monitor and analyze your racing performance with precision.
              </p>
            </div>

            {/* Second Process */}
            <div className="col-md-4 text-center">
              <img
                src={recordTime}
                alt="Process 2"
                className="img-fluid mb-3 custom-img1"
              />
              <h3 className="text-color11 mb-3">Record Lap Times</h3>
              <p className="text-color11">
                Keep precise records of your lap times for continuous
                improvement.
              </p>
            </div>

            {/* Third Process */}
            <div className="col-md-4 text-center">
              <img
                src={easyUse}
                alt="Process 3"
                className="img-fluid mb-3 custom-img1"
              />
              <h3 className="text-color11 mb-3">Easy to manage</h3>
              <p className="text-color11">
                Join a vibrant community, share experiences, and learn from
                fellow racers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr className="section-divider1 my-5" />

      {/* Gallery Section */}
      <div id="gallery" className="features-section text-center mt-5 p-5">
        <div className="container">
          <div className="row d-flex align-items-stretch" style={{ height: '100%' }}>
            <div className="col-md-6 d-flex flex-column justify-content-center">
              <h2 className="text-color111 mb-4 fw-bold display-6">Captured Moments</h2>
              <p className="text-gray-700">
                Step into the adrenaline-fueled world of racing through our collection of captivating snapshots. Each image immerses you in the thrill of the track, capturing heart-pounding action, intense competition, and the camaraderie shared among fellow enthusiasts. Explore our gallery and relive the passion and excitement that define every race day.
              </p>
            </div>
            <div className="col-md-6 p-0 d-flex">
              <div className="image-slider1">
                <img
                  src={images[currentIndex]}
                  alt={`sliderIndex${currentIndex}`}
                  className="img-fluid"
                />
                <button
                  className="prev-button1 position-absolute"
                  onClick={prevSlide}
                  style={{ top: '50%', left: '10px', transform: 'translateY(-50%)' }}
                >
                  &#10094;
                </button>
                <button
                  className="next-button1 position-absolute"
                  onClick={nextSlide}
                  style={{ top: '50%', right: '10px', transform: 'translateY(-50%)' }}
                >
                  &#10095;
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
      <hr className="section-divider1 my-5" />

      {/* Pricing Section */}
      <div id="pricing" className="pricing-section">
        <div className="container">
          <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
            <h1 className="text-color11 display-6 fw-bold display-6">Pricing</h1>
            <p className=" text-gray-700" style={{ fontSize: "18px" }}>
              Craft an irresistible pricing strategy to captivate your
              customers. Explore our flexible pricing options tailored to suit
              your business needs. Elevate your business and drive growth with
              our comprehensive pricing solutions.
            </p>
          </div>

          <div className="row row-cols-1 row-cols-md-2 g-4">
            {/* Pro Plan */}
            <div className="col">
              <div className="card mb-4 box-shadow custom-card1">
                <div className="card-header custom-header1">
                  <h4 className="my-0 font-weight-normal text-color21">Pro</h4>
                </div>
                <div className="card-body1">
                  <h1 className="card-title pricing-card-title text-color21 ">
                    $15 <small className="text-muted">/ mo</small>
                  </h1>
                  <ul className="list-unstyled mt-3 mb-4 text-color21">
                    <li className="text-color21">20 users included</li>
                    <li className="text-color21">10 GB of storage</li>
                    <li className="text-color21">Priority email support</li>
                    <li className="text-color21">Help center access</li>
                  </ul>
                  <button
                    type="button"
                    className="btn btn-lg btn-block btn-primary"
                  >
                    Get started
                  </button>
                </div>
              </div>
            </div>

            {/* Premium Plan */}
            <div className="col">
              <div className="card mb-4 box-shadow custom-card1">
                <div className="card-header custom-header1">
                  <h4 className="my-0 font-weight-normal text-color21">
                    Premium
                  </h4>
                </div>
                <div className="card-body1">
                  <h1 className="card-title pricing-card-title text-color21">
                    $25 <small className="text-muted">/ mo</small>
                  </h1>
                  <ul className="list-unstyled mt-3 mb-4">
                    <li className="text-color21">50 users included</li>
                    <li className="text-color21">20 GB of storage</li>
                    <li className="text-color21">
                      24/7 priority email support
                    </li>
                    <li className="text-color21">Help center access</li>
                  </ul>
                  <button
                    type="button"
                    className="btn btn-lg btn-block btn-primary"
                  >
                    Get started
                  </button>
                </div>
              </div>
            </div>

            {/* Enterprise Plan */}
            {/* <div className="col">
              <div className="card mb-4 box-shadow custom-card1">
                <div className="card-header custom-header1">
                  <h4 className="my-0 font-weight-normal text-color21">
                    Enterprise
                  </h4>
                </div>
                <div className="card-body1">
                  <h1 className="card-title pricing-card-title text-color21">
                    $40 <small className="text-muted">/ mo</small>
                  </h1>
                  <ul className="list-unstyled mt-3 mb-4">
                    <li className="text-color21">100 users included</li>
                    <li className="text-color21">50 GB of storage</li>
                    <li className="text-color21">
                      24/7 priority email and phone support
                    </li>
                    <li className="text-color21">Help center access</li>
                  </ul>
                  <button
                    type="button"
                    className="btn btn-lg btn-block btn-primary"
                  >
                    Get started
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <hr className="section-divider1 my-5" />

      {/* Events Section */}
      <div id="events" className="events-section mt-5 p-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="text-color11 mb-4 fw-bold display-6">Upcoming Events</h2>
              <p className="text-gray-700 mb-4" style={{ fontSize: "18px" }}>
                Explore our upcoming events and join us!
              </p>
            </div>
          </div>
          {events.map(
            (event, index) =>
              // Conditionally render row for every third event
              index % 3 === 0 && (
                <div className="row mb-4 justify-content-center">
                  {/* Render event cards */}
                  {events.slice(index, index + 3).map((event, innerIndex) => (
                    <div key={event._id} className="col-md-4 mb-4">
                      <div className="card event-card-body">
                        <div className="card-body">
                          <h5 className="card-title mb-3 text-center" style={{ borderBottom: "2px solid #f8b940" }}>
                            {event.name}
                          </h5>
                          <div className="event-details-div mb-2">
                            <p className="event-details mb-2">
                              <b>Date:</b> {normalDate(event.dateTime)}
                            </p>
                            {event.admin && (
                              <React.Fragment>
                                <p className="event-details mb-2">
                                  <b>Organized by:</b> {event.admin.fullName}
                                </p>
                                <p className="event-details mb-2">
                                  <b>Contact:</b> {event.admin.email}
                                </p>
                              </React.Fragment>
                            )}
                          </div>
                          <button
                            className="btn btn-primary float-end text-white shadow-lg"
                            variant="primary"
                            onClick={() => handleSeeDetails(event)}
                          >
                            See Details
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )
          )}

          {/* Event Details Modal */}
          {selectedEvent && (
            <EventDetailsModal
              event={selectedEvent}
              show={showModal}
              onHide={() => setShowModal(false)}
            />
          )}
        </div>
      </div>

      <hr className="section-divider1 my-5" />

      <div id="contact" className="contact-section mt-5 p-5">
        <div className="container">
          <div className="row">
            {/* Information Section */}
            <div className="col-md-6 mb-4">
              <div className="p-4">
                <h1 className="fw-bold text-lg mb-3 display-6">have a question?</h1>
                <p className="text-gray-700 mb-3" style={{ fontSize: "18PX" }}>
                  We're here to help you with any questions or concerns. Reach out to us via the contact form, and we'll respond promptly!
                </p>
                <ul className="list-none p-0" style={{ fontSize: "15px" }}>
                  <li className="mb-2">
                    <i className="fas fa-map-marker-alt mr-2"></i>Rr. Tirana, kati 6, 10000 Prishtinë, Kosovë
                  </li>
                  <li className="mb-2">
                    <i className="fas fa-envelope mr-2"></i>developer@novus.consulting
                  </li>
                  <li className="mb-2">
                    <i className="fas fa-phone-alt mr-2"></i>+383 49 609 000
                  </li>
                  <li className="mb-2">
                    <i className="fas fa-clock mr-2"></i>Mon - Fri: 08:30 AM - 5 PM
                  </li>
                  <li>
                    <i className="fas fa-globe mr-2"></i>www.novus.consulting
                  </li>
                </ul>
                <p className="text-gray-700 mt-4" style={{ fontSize: "18px" }}>
                  We value your feedback and inquiries. Please don’t hesitate to get in touch with us!
                </p>
              </div>
            </div>


            {/* Contact Section */}
            <div className="col-md-6 mb-4">
              <div className="card border-0 shadow-lg rounded-4">
                {/* <div className="card-body p-5">
                  <h2 className="text-primary mb-4 fw-bold">Get in Touch</h2>
                  <form ref={form} onSubmit={handleSubmit} className="custom-contact-form">
                    <div className="mb-4">
                      <label htmlFor="name" className="form-label text-dark fw-semibold">
                        Your Name
                      </label>
                      <input
                        type="text"
                        className="form-control rounded-pill"
                        id="name"
                        name="name"
                        onChange={handleChange}
                        value={formData.name}
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="email" className="form-label text-dark fw-semibold">
                        Email Address
                      </label>
                      <input
                        type="email"
                        className={`form-control rounded-pill ${errors.email ? "is-invalid" : ""}`}
                        id="email"
                        name="email"
                        onChange={handleChange}
                        value={formData.email}
                        required
                      />
                      {errors.email && (
                        <div className="invalid-feedback">{errors.email}</div>
                      )}
                    </div>
                    <div className="mb-4">
                      <label htmlFor="subject" className="form-label text-dark fw-semibold">
                        Subject
                      </label>
                      <input
                        type="text"
                        className="form-control rounded-pill"
                        id="subject"
                        name="subject"
                        onChange={handleChange}
                        value={formData.subject}
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="message" className="form-label text-dark fw-semibold">
                        Message
                      </label>
                      <textarea
                        className="form-control rounded-3"
                        id="message"
                        name="message"
                        rows="5"
                        onChange={handleChange}
                        value={formData.message}
                        required
                      ></textarea>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary btn-lg btn-block rounded-pill shadow-sm"
                    >
                      Send Message
                    </button>
                  </form>
                </div> */}
                <div className="card-body p-5">
                  <h2 className="text-primary mb-4 fw-bold">Get in Touch</h2>
                  <form ref={form} onSubmit={handleSubmit} className="custom-contact-form">
                    <div className="mb-4">
                      <label htmlFor="name" className="form-label text-dark fw-semibold">Your Name</label>
                      <input type="text" className="form-control rounded-pill" id="name" name="name" onChange={handleChange} value={formData.name} required />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="email" className="form-label text-dark fw-semibold">Email Address</label>
                      <input type="email" className={`form-control rounded-pill ${errors.email ? "is-invalid" : ""}`} id="email" name="email" onChange={handleChange} value={formData.email} required />
                      {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                    </div>
                    <div className="mb-4">
                      <label htmlFor="subject" className="form-label text-dark fw-semibold">Subject</label>
                      <input type="text" className="form-control rounded-pill" id="subject" name="subject" onChange={handleChange} value={formData.subject} required />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="message" className="form-label text-dark fw-semibold">Message</label>
                      <textarea className="form-control rounded-3" id="message" name="message" rows="5" onChange={handleChange} value={formData.message} required></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary btn-lg btn-block rounded-pill shadow-sm">Send Message</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <hr className="section-divider1 mt-5" />

      {/* Footer Section */}
      <footer
        className="footer-section py-3"
        style={{ backgroundColor: "#452b90", color: "#F3F0EC" }}
      >
        <div className="container text-center">
          <p>Contact Us: developer@novus.consulting</p>
          <p>
            <a href="https://www.facebook.com/novus.consult/" className="social-link1 mx-2">
              <i className="fab fa-facebook fa-lg"></i>
            </a>
            {/* <a href="https://www.x.com/novus.consulting" className="social-link1 mx-2">
              <i className="fab fa-twitter fa-lg"></i>
            </a> */}
            <a href="https://www.instagram.com/novus.consulting" className="social-link1 mx-2">
              <i className="fab fa-instagram fa-lg"></i>
            </a>
          </p>
          <p>
            &copy; 2024
            <a href="https://novus.consulting/" className="text-light mx-1">
              NOVUS LLC
            </a>. All rights reserved. |
            <a href="/privacy" className="text-light mx-1">
              Privacy Policy
            </a>
          </p>

        </div>
      </footer>

    </div>
  );
};

export default Index;
