import React, { useState, useEffect } from "react";
import Select from "react-select";
import { OverlayTrigger, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "../../layouts/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";
import "./customCss/xclx.css";
import {
  notifyNameWarning,
  notifyLocationWarning,
  notifyDistanceWarning,
  notifyDistanceTypeWarning,
  notifyTimeWarning,
  notifyTopFullWidth,
} from "../../notifyHooks/notifyNewRace";

const StepOne = React.memo(
  ({
    inputDate,
    inputTime,
    setRaceName,
    setInputDate,
    setInputTime,
    setDateTime,
    setLocation,
    location,
    setDistance,
    setDistanceType,
    setIsGunRace,
    setGoSteps,
    distance,
    isGunRace,
    dateTime,
    raceName,
    distanceType,
    currentDateToCompare,
    eventDateToCompare,
  }) => {
    const [dateToCompare, setDateToCompare] = useState(eventDateToCompare);

    const handleTimeChange = (timestampInSeconds) => {
      setDateTime(timestampInSeconds);
    };

    useEffect(() => {
      const timestampInString = `${inputDate}T${inputTime}`;
      const timestampInMilliseconds = Date.parse(timestampInString);
      const timestampInSeconds = Math.floor(timestampInMilliseconds / 1000);

      handleTimeChange(timestampInSeconds);
    }, [inputDate, inputTime, setDateTime]); // Include setDateTime in the dependency array

    const handleNameChange = (e) => {
      setRaceName(e.target.value);
    };

    const handleCombinedChange = (e) => {
      if (e.target.type === "date") {
        if (e.target.value < getCurrentDateTime()) {
          notifyTopFullWidth();
          return;
        }

        const actualDate = e.target.value;
        setDateToCompare(new Date(actualDate).getTime() / 1000);
        setInputDate(e.target.value);
      } else if (e.target.type === "time") {
        setInputTime(e.target.value);
      }
    };

    const handleLocationChange = (e) => {
      setLocation(e.target.value);
    };

    const handleDistanceChange = (e) => {
      e.preventDefault();
      setDistance(e.target.value);
    };

    const handleDistanceTypeChange = (selectedType) => {
      setDistanceType(selectedType);
    };

    const handleIsGunRace = (selectedRaceType) => {
      setIsGunRace(selectedRaceType.value === "Gun Time");
    };

    const getCurrentDateTime = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    };

    const handleFormSubmit = async () => {
      try {
        if (raceName.trim() === "") {
          notifyNameWarning();
          return;
        }

        if (location.trim() === "") {
          notifyLocationWarning();
          return;
        }

        if (distance.trim() === "") {
          notifyDistanceWarning();
          return;
        }

        if (distanceType === "") {
          notifyDistanceTypeWarning();
          return;
        }

        if (dateTime === "") {
          notifyTimeWarning();
          return;
        }

        if (inputTime === "") {
          notifyTimeWarning();
          return;
        }

        if (isNaN(distance)) {
          toast.warn("Distance field should be a number.", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }

        if (dateToCompare < currentDateToCompare) {
          toast.warn("Please select a future date.", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }

        if (dateTime) setGoSteps(1);
      } catch (error) {
        console.error("Error in handleFormSubmit1:", error);
      }
    };

    return (
      <React.Fragment>
        <ScrollToTop />
        <ToastContainer />

        <div className="basic-form">
          <form>
            <div className="row">
              <div className="form-group mb-3 col-md-6">
                <label for="raceName">Name</label>
                <input
                  id="raceName"
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  value={raceName}
                  onChange={(e) => {
                    handleNameChange(e);
                  }}
                />
              </div>
              <div className="form-group mb-3 col-md-6">
                <label for="location">Location</label>
                <input
                  id="location"
                  type="text"
                  className="form-control"
                  placeholder="Location"
                  value={location}
                  onChange={handleLocationChange}
                />
              </div>
              <div className="form-group mb-3 col-md-2">
                <label for="distance">Distance</label>
                <input
                  id="distance"
                  type="text"
                  className="form-control"
                  placeholder="Distance"
                  value={distance}
                  onChange={handleDistanceChange}
                />
              </div>
              <div className="form-group mb-3 col-md-2">
                <label for="distanceType">Distance Type</label>
                <Select
                  id="distanceType"
                  value={distanceType}
                  onChange={handleDistanceTypeChange}
                  options={["km", "m"].map((type) => ({
                    value: type,
                    label: type,
                  }))}
                />
              </div>

              <div className="form-group mb-3 col-md-2">
                <label for="isGunRace">Race Type</label>
                <Select
                  id="isGunRace"
                  value={
                    isGunRace
                      ? { value: "Gun Time", label: "Gun Time" }
                      : { value: "Chip Time", label: "Chip Time" }
                  }
                  onChange={handleIsGunRace}
                  options={["Gun Time", "Chip Time"].map((type) => ({
                    value: type,
                    label: type,
                  }))}
                />
              </div>

              <div className="form-group mb-3 col-md-3">
                <label for="date" className="d-inline-block mr-2">
                  Date
                </label>
                <OverlayTrigger
                  trigger="click"
                  placement="right"
                  responsive={true}
                  overlay={
                    <h3
                      className="popover-header"
                      style={{
                        marginLeft: "1.5px",
                        borderRadius: "5.5px",
                        fontFamily: "sans-serif",
                        backgroundColor: "#f3f0ec",
                        color: "black",
                        border: "groove",
                        borderBlockColor: "#3b257a",
                        borderInlineColor: "#3b257a",
                      }}
                    >
                      Automatically set to event date
                    </h3>
                  }
                >
                  <Button
                    id="timeButton"
                    variant="primary"
                    style={{
                      padding: "0rem 0.2rem",
                    }}
                    className="rounded-circle"
                  >
                    <i className="bi bi-info-circle"></i>{" "}
                  </Button>
                </OverlayTrigger>
                <input
                  id="date"
                  type="date"
                  className="form-control"
                  min={getCurrentDateTime()}
                  placeholder="Date"
                  value={inputDate}
                  onChange={handleCombinedChange}
                />
              </div>
              <div className="form-group mb-3 col-md-3">
                <label for="timeButton" className="d-inline-block mr-2">
                  Start Time
                </label>
                <OverlayTrigger
                  trigger="click"
                  placement="right"
                  responsive={true}
                  overlay={
                    <h3
                      className="popover-header"
                      style={{
                        marginLeft: "1.5px",
                        borderRadius: "5.5px",
                        fontFamily: "sans-serif",
                        backgroundColor: "#f3f0ec",
                        color: "black",
                        border: "groove",
                        borderBlockColor: "#3b257a",
                        borderInlineColor: "#3b257a",
                      }}
                    >
                      Approximate start time of the race
                    </h3>
                  }
                >
                  <Button
                    id="timeButton"
                    variant="primary"
                    style={{
                      padding: "0rem 0.2rem",
                    }}
                    className="rounded-circle"
                  >
                    <i className="bi bi-info-circle"></i>{" "}
                  </Button>
                </OverlayTrigger>
                <input
                  id="time"
                  type="time"
                  className="form-control d-block"
                  value={inputTime}
                  placeholder="Time"
                  onChange={handleCombinedChange}
                />
              </div>
            </div>
            <div className="text-end toolbar toolbar-bottom p-2">
              <button
                id="next"
                type="button"
                className="btn btn-primary sw-btn-next"
                onClick={() => {
                  handleFormSubmit();
                }}
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
);

export default StepOne;
