import { toast } from "react-toastify";

const notifyOldPasswordWarning = () => {
  toast.warn("Please enter the actual password!", {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyNewPasswordWarning = () => {
  toast.warn("Please enter the new password!", {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyConfirmNewPasswordWarning = () => {
  toast.warn("Please confirm the new password!", {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyOldPasswordValidity = () => {
  toast.warn("Current password is incorrect.", {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyPasswordMatch = () => {
  toast.warn("New password and confirm password do not match.", {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyLengthValidity = () => {
  toast.warn("Password must be at least 6 characters.", {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyUppercaseLetterValidity = () => {
  toast.warn("Password must contain at least one uppercase letter.", {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyPasswordSpecial = () => {
  toast.warn("New password cannot be the same as old password.", {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyFullNameAbsence = () => {
  toast.warn("Full Name is required.", {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
const notifyUserNameConflict = (message) => {
  toast.warn(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyUserNameAbsence = () => {
  toast.warn("Username is required.", {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export {
  notifyOldPasswordWarning,
  notifyNewPasswordWarning,
  notifyConfirmNewPasswordWarning,
  notifyOldPasswordValidity,
  notifyPasswordMatch,
  notifyLengthValidity,
  notifyUppercaseLetterValidity,
  notifyPasswordSpecial,
  notifyFullNameAbsence,
  notifyUserNameConflict,
  notifyUserNameAbsence,
};
