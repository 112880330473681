import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import swal from "sweetalert";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  loadingToggleAction,
  loginAction,
  registerAction,
  forgetPasswordAction,
} from "../../store/actions/AuthActions";
import "./styles.css";
import marathon_Race from "../../images/marathon_Race.jpg";

function AuthPage(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [username, setUsername] = useState("");
  const [signEmail, setSignEmail] = useState("");
  const [signPassword, setSignPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [registerForm, setRegisterForm] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isRegister = params.get("register") === "true";
    setRegisterForm(isRegister);
  }, [location]);

  let errorsObj = {
    email: "",
    password: "",
    fullName: "",
    username: "",
    signEmail: "",
    signPassword: "",
  };

  let errorsObjLogin = {
    email: "",
    password: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [errorsLogin, setErrorsLogin] = useState(errorsObjLogin);
  const [isLogin, setIsLogin] = useState(true); // State to toggle between login and registration forms
  const dispatch = useDispatch();

  function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObjLogin = { ...errorsObjLogin };
    if (email === "") {
      errorObjLogin.email = "Email is Required";
      error = true;
    } else {
      errorObjLogin.email = ""; // Clear the error message if the email is not empty
    }
    if (password === "") {
      errorObjLogin.password = "Password is Required";
      error = true;
    }

    setErrorsLogin(errorObjLogin);
    if (error) {
      return;
    }

    dispatch(loadingToggleAction(true));

    // User is subscribed, redirect to the dashboard or another view
    dispatch(loginAction(email, password, navigate));
  }

  const handleEmailChange = (e) => {
    let error = false;
    const errorObjLogin = { ...errorsObjLogin };
    if (e.target.value === "") {
      errorObjLogin.email = "Email is Required";
      error = true;
    } else {
      errorObjLogin.email = "";
    }
    setErrorsLogin(errorObjLogin);
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    let error = false;
    const errorObjLogin = { ...errorsObjLogin };
    if (e.target.value === "") {
      errorObjLogin.password = "Password is Required";
      error = true;
    } else {
      errorObjLogin.password = "";
    }
    setErrorsLogin(errorObjLogin);
    setPassword(e.target.value);
  };

  function onSignUp(e) {
    e.preventDefault();
    setLoading(true);
    let error = false;
    const errorObj = { ...errorsObj };
    if (fullName === "") {
      errorObj.fullName = "Full Name is Required";
      error = true;
    }
    if (username === "") {
      errorObj.username = "Username is Required";
      error = true;
    }
    if (signEmail === "") {
      errorObj.signEmail = "Email is Required";
      error = true;
    }
    if (signPassword === "") {
      errorObj.signPassword = "Password is Required";
      error = true;
    }
    if (signPassword && signPassword.length < 6) {
      errorObj.signPassword = "Password must be at least 6 characters";
      error = true;
    }
    if (signPassword && !/[A-Z]/.test(signPassword)) {
      errorObj.signPassword =
        "Password must contain at least one uppercase letter";
      error = true;
    }
    if (signPassword.length >= 6 && signPassword !== confirmPassword) {
      errorObj.confirmPassword = "Passwords do not match";
      errorObj.signPassword = "Passwords do not match";
      error = true;
    }
    if (confirmPassword === "") {
      errorObj.confirmPassword = "Confirm Password is Required";
      error = true;
    }

    if (confirmPassword && confirmPassword !== signPassword) {
      errorObj.confirmPassword = "Passwords do not match";
      error = true;
    }
    setErrors(errorObj);
    if (error) return;

    dispatch(loadingToggleAction(true));
    dispatch(
      registerAction(fullName, username, signEmail, signPassword, navigate)
    )
      .then(() => {
        // Registration is successful
        setLoading(false);
      })
      .catch(() => {
        // Registration failed
        setLoading(false);
      });
  }
  function onForget(e) {
    e.preventDefault();

    swal({
      title: "Trouble logging in?",
      text: "Do you want to reset your password?",
      icon: "warning",
      buttons: ["Cancel", "Yes"],
      dangerMode: false,
    }).then((confirmed) => {
      if (confirmed) {
        const token = generateRandomToken(16);
        dispatch(forgetPasswordAction(email, token, navigate));

        swal({
          title: "Check your email!",
          text: "You have 5 minutes to verify from the email we sent you!",
          icon: "success",
          timer: 300000,
          buttons: ["Close"],
        }).then(() => {
          swal.close();
        });
      } else {
        swal.close();
      }
    });
  }

  function generateRandomToken(length) {
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const randomBytes = new Uint8Array(length);

    // Use crypto.getRandomValues() for better randomness
    crypto.getRandomValues(randomBytes);

    let token = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = randomBytes[i] % charset.length;
      token += charset.charAt(randomIndex);
    }

    return token;
  }

  return (
    <div className={`page-wraper ${isLogin ? "login-form" : "register-form"}`}>
      <div className="login-account">
        <div className="row">
          <div className="col-lg-6 align-self-start">
            <div
              className="account-info-area"
              style={{
                backgroundImage: "url(" + marathon_Race + ")",
              }}
            >
              <div className="login-content">
                <p className="sub-title">
                  Log in to your admin dashboard with your credentials or create
                  a new account
                </p>
                <h1 className="title">Race Dashboard</h1>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-7 col-sm-12 mx-auto align-self-center">
            {" "}
            <div className="login-form">
              <h6 className="login-title">
                <span>{isLogin && !registerForm ? "Login" : "Register"}</span>
              </h6>

              {isLogin && !registerForm ? (
                <form
                  onSubmit={onLogin}
                  style={{
                    transition: "opacity 0.5s ease-in-out",
                    opacity: 1,
                  }}
                >
                  {/* ... Login form fields */}
                  <div className="mb-4">
                    <label className="mb-1 text-dark">Email</label>
                    <input
                      type="email"
                      className={`form-control ${errorsLogin.email ? "border-danger" : ""
                        }`}
                      value={email}
                      placeholder="Email"
                      onChange={handleEmailChange}
                    />
                    {errorsLogin.email && (
                      <div className="text-danger fs-12">
                        {errorsLogin.email}
                      </div>
                    )}
                  </div>
                  <div className="mt-4 mb-4">
                    <label className="mb-1 text-dark">Password</label>
                    <input
                      type="password"
                      name="password"
                      value={password}
                      onChange={handlePasswordChange}
                      className={`form-control ${errorsLogin.password ? "border-danger" : ""
                        }`}
                      placeholder="Password"
                    />
                    {errorsLogin.password && (
                      <div className="text-danger fs-12">
                        {errorsLogin.password}
                      </div>
                    )}
                  </div>

                  <div className="text-center mb-4">
                    <button type="submit" className="btn btn-primary btn-block">
                      Sign In
                    </button>
                    <p className="text-center mt-3">
                      <Link
                        onClick={onForget}
                        className="btn-link text-primary"
                      >
                        {" "}
                        Forgot Password?
                      </Link>
                    </p>
                  </div>
                  <div className="text-center">
                    <h6 className="login-title mb-3"></h6>
                    {/* <button
                      type="button"
                      className="btn btn-secondary center"
                      onClick={() => {
                        setIsLogin(false);
                      }}
                    > */}
                    <button
                      type="button"
                      className="btn btn-secondary center"
                      onClick={() => {
                        // Show an alert to the user when clicking on "Create new account"
                        swal({
                          title: "Registration Not Available",
                          text: "The registration feature is not available at the moment. Please contact the admin for assistance.",
                          icon: "info",
                          button: "OK",
                          className: "text-center-swal",
                        });
                      }}
                    >
                      Create new account
                    </button>
                  </div>
                </form>
              ) : (
                // <form
                //   onSubmit={onSignUp}
                //   style={{
                //     transition: "opacity 0.5s ease-in-out",
                //     opacity: 1,
                //   }}
                // >
                //   {/* ... Registration form fields */}
                //   <div className="mt-4">
                //     <label className="mb-1 text-dark">Full Name</label>
                //     <input
                //       name="fullName"
                //       required=""
                //       className={`form-control ${errors.fullName ? "border-danger" : ""
                //         }`}
                //       onChange={(e) => setFullName(e.target.value)}
                //       value={fullName}
                //       placeholder="Full Name"
                //       type="text"
                //     />
                //     {errors.fullName && (
                //       <div className="text-danger fs-12">{errors.fullName}</div>
                //     )}
                //   </div>
                //   <div className="mt-4">
                //     <label className="mb-1 text-dark">Username</label>
                //     <input
                //       name="username"
                //       required=""
                //       className={`form-control ${errors.username ? "border-danger" : ""
                //         }`}
                //       onChange={(e) => setUsername(e.target.value)}
                //       value={username}
                //       placeholder="Username"
                //       type="text"
                //     />
                //     {errors.username && (
                //       <div className="text-danger fs-12">{errors.username}</div>
                //     )}
                //   </div>
                //   <div className="mt-4">
                //     <label className="mb-1 text-dark">Email</label>
                //     <input
                //       name="signEmail"
                //       value={signEmail}
                //       onChange={(e) => setSignEmail(e.target.value)}
                //       className={`form-control ${errors.signEmail ? "border-danger" : ""
                //         }`}
                //       placeholder="Email Address"
                //     />
                //     {errors.signEmail && (
                //       <div className="text-danger fs-12">
                //         {errors.signEmail}
                //       </div>
                //     )}
                //   </div>
                //   <div className="mt-4">
                //     <label className="mb-1 text-dark">Password</label>
                //     <input
                //       type="password"
                //       name="signPassword"
                //       value={signPassword}
                //       onChange={(e) => setSignPassword(e.target.value)}
                //       className={`form-control ${errors.signPassword ? "border-danger" : ""
                //         }`}
                //       placeholder="Password"
                //     />

                //     {errors.signPassword && (
                //       <div className="text-danger fs-12">
                //         {errors.signPassword}
                //       </div>
                //     )}
                //   </div>
                //   <div className="mt-4">
                //     <label className="mb-1 text-dark">Confirm Password</label>
                //     <input
                //       type="password"
                //       name="confirmPassword"
                //       value={confirmPassword}
                //       onChange={(e) => setConfirmPassword(e.target.value)}
                //       className={`form-control ${errors.confirmPassword ? "border-danger" : ""
                //         }`}
                //       placeholder="Confirm Password"
                //     />

                //     {errors.confirmPassword && (
                //       <div className="text-danger fs-12">
                //         {errors.confirmPassword}
                //       </div>
                //     )}
                //   </div>

                //   <div className="text-center mb-4 mt-4">
                //     <button type="submit" className="btn btn-primary btn-block">
                //       Sign Up
                //     </button>
                //   </div>
                //   <div className="text-center">
                //     <h6 className="login-title mb-3"></h6>
                //     <button
                //       type="button"
                //       className="btn btn-secondary center"
                //       onClick={() => {
                //         setIsLogin(true);
                //         setRegisterForm(false);
                //       }}
                //     >
                //       Sign In
                //     </button>
                //   </div>
                // </form>
                <div className="text-center mt-4 mb-4">
                  <div
                    style={{
                      padding: "20px",
                      backgroundColor: "#f8d7da",
                      color: "#721c24",
                      borderRadius: "8px",
                      border: "1px solid #f5c6cb",
                      display: "inline-block",
                      maxWidth: "400px",
                    }}
                  >
                    <i className="fas fa-exclamation-circle" style={{ fontSize: "24px", marginBottom: "10px" }}></i>
                    <h3 style={{ margin: "10px 0" }}>Feature Unavailable</h3>
                    <p style={{ margin: "0", fontSize: "16px" }}>
                      We're currently working on this feature. Please check back soon!
                    </p>
                  </div>
                </div>

              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(AuthPage);
