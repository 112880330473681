import { toast } from "react-toastify";

const notifyDateTime = () => {
  toast.warn("Please select a future date and time!", {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyEarlyDateSelection = () => {
  toast.warn("You have selected an earlier date than today. Re-consider!", {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyNameWarning = () => {
  toast.warn("Please enter the event name.", {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyDateWarning = () => {
  toast.warn("Please select a date.", {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export {
  notifyDateTime,
  notifyEarlyDateSelection,
  notifyNameWarning,
  notifyDateWarning,
};
