import React, { useReducer } from "react";
import Select from "react-select";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import { Row, Col, Card, Table, Button, Modal } from "react-bootstrap";
import ScrollToTop from "../../layouts/ScrollToTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { AiFillFilePdf } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import useEvents from "../../../hooks/useEvents";
import useRaces from "../../../hooks/useRaces";
import useResults from "../../../hooks/useResults";

const initialState = {
  pdfModal: false,
  csvModal: false,
};

const reducer = (state, active) => {
  switch (active.type) {
    case "pdfModal":
      return {
        ...state,
        pdfModal: !state.pdfModal,
      };
    case "csvModal":
      return {
        ...state,
        csvModal: !state.csvModal,
      };

    default:
      return state;
  }
};

const Results = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [events, setEvents] = useState([]);
  const [races, setRaces] = useState([]);
  const [organizatedByEvent, setOrganizatedByEvent] = useState(null);
  const [organizatedByRace, setOrganizatedByRace] = useState(null);
  const [isRaceSelectDisabled, setIsRaceSelectDisabled] = useState(true); // State to disable the race select
  const [results, setResults] = useState([]);
  const [raceData, setRaceData] = useState(null);
  const [eventData, setEventData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [actualRaceName, setActualRaceName] = useState(null);
  const [selectedColumnsPdf, setSelectedColumnsPdf] = useState([]);
  const [selectedColumnsCsv, setSelectedColumnsCsv] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();

  const { fetchAllEvents, fetchEventById } = useEvents();
  const { fetchRacesByEventId, fetchRaceByIds } = useRaces();
  const {
    fetchRaceResults,
    generateResultsExcelHandler,
    generateResultsPDFHandler,
  } = useResults();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetchAllEvents();
        setEvents(response.events);

        if (response.message === "No events found") {
          swal({
            title: "No results found!",
            text: "Please add new events and races!",
            icon: "info",
            closeOnClickOutside: false,
            buttons: {
              later: {
                text: "Main Page",
                value: "later",
                className: "btn-later",
              },
            },
          }).then(() => {
            navigate(`/dashboard`);
          });
        }
      } catch (error) {
        console.error("Failed to fetch events!", error);
      }
    };

    const fetchRaces = async () => {
      try {
        const response = await fetchRacesByEventId(organizatedByEvent);
        setRaces(response);
      } catch (error) {
        console.error(error);
        // Handle error cases
      }
    };

    fetchRaces();
    fetchEvents();
  }, [organizatedByEvent]);

  // useEffect(() => {
  //   const fetchRaces = async () => {
  //     try {
  //       const response = await fetchRacesByEventId(organizatedByEvent);
  //       console.log(response);
  //       setRaces(response);
  //     } catch (error) {
  //       console.error(error);
  //       // Handle error cases
  //     }
  //   };

  //   fetchRaces();
  // }, [organizatedByEvent]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []); // runs only once after the initial render

  const handleEventChange = (selectedOption) => {
    setOrganizatedByEvent(selectedOption.value);
    setIsRaceSelectDisabled(false);
  };

  const handleRaceChange = (selectedOption) => {
    setOrganizatedByRace(selectedOption.value);
  };

  const handleColumnCheckboxChangePdf = (column) => {
    if (selectedColumnsPdf.includes(column)) {
      setSelectedColumnsPdf(selectedColumnsPdf.filter((col) => col !== column));
    } else {
      setSelectedColumnsPdf([...selectedColumnsPdf, column]);
    }
  };

  const handleSelectAllColumnsPdf = () => {
    setSelectedColumnsPdf(headerCells);
  };

  const handleColumnCheckboxChangeCsv = (column) => {
    if (selectedColumnsCsv.includes(column)) {
      setSelectedColumnsCsv(selectedColumnsCsv.filter((col) => col !== column));
    } else {
      setSelectedColumnsCsv([...selectedColumnsCsv, column]);
    }
  };

  const handleSelectAllColumnsCsv = () => {
    setSelectedColumnsCsv(headerCells);
  };

  const isSearchButtonDisabled = !organizatedByRace || isLoading;

  function normalTime(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);

    const options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false, // Use 24-hour format
    };

    // Convert the date to a time format
    return date.toLocaleTimeString("en-US", options);
  }

  const handleResultsSubmit = async () => {
    setSelectedColumnsPdf([]);
    setSelectedColumnsCsv([]);

    setIsLoading(true);

    try {
      const resultsResponse = await fetchRaceResults(organizatedByRace);
      const raceResponse = await fetchRaceByIds(
        organizatedByRace,
        organizatedByEvent
      );
      const eventResponse = await fetchEventById(organizatedByEvent);

      setResults(resultsResponse);
      setRaceData(raceResponse.race);
      setEventData(eventResponse.event);
      setActualRaceName(raceResponse.race.raceName);

      const currentRaceName = raceResponse.race.raceName;

      if (resultsResponse.length === 0) {
        swal(
          "No results found!",
          `There are no results for "${currentRaceName}" race yet.`,
          "info"
        );
        return; // Stop further execution
      }
    } catch (error) {
      swal("Error", "Failed to fetch data!", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const openPdfModal = () => {
    dispatch({ type: "pdfModal" });
  };

  const openCsvModal = () => {
    dispatch({ type: "csvModal" });
  };

  const predefinedHeaders = [
    "Place",
    "Name",
    "Surname",
    "Gender",
    "Bib",
    "Start",
  ];

  let headerRow = null;
  let bodyRows = null;

  const skipFirstSplit = true;

  let headerCells = [];

  if (results.length > 0 && results[0].splits) {
    headerCells = [
      ...predefinedHeaders,
      ...(skipFirstSplit
        ? results[0].splitNames.slice(1).map((split) => split.name)
        : results[0].splitNames.map((split) => split.name)),
    ];

    headerCells.push("Total Time");

    headerRow = (
      <tr className="text-center">
        {headerCells.map((headerText, index) => (
          <th
            key={index}
            style={
              headerText === "Registration Date" || headerText === "Event"
                ? { width: "160px" }
                : {}
            }
          >
            {headerText}
          </th>
        ))}
      </tr>
    );

    bodyRows = results.map((result, rowIndex) => {
      const emptyCellsCount = results[0].splits.length - result.splits.length;
      const splitCells = (
        skipFirstSplit ? results[0].splitNames.slice(1) : results[0].splitNames
      ).map((splitName) => {
        const participantSplit = result.splits.find(
          (split) => split.splitId === splitName._id
        );
        return participantSplit ? normalTime(participantSplit.splitTime) : "";
      });

      const date = new Date(result.participant.registrationDate);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const ampm = hours >= 12 ? "PM" : "AM";

      // Convert hours to 12-hour format
      const formattedHours = hours % 12 || 12;

      const formattedDate = `${day}/${month}/${year}, ${formattedHours}:${minutes} ${ampm}`;

      const bodyCells = [
        result.place !== null ? result.place : "-",
        result.participant.firstName,
        result.participant.lastName,
        result.participant.gender,
        result.bibNumber,
        normalTime(result.startTime),
        ...splitCells,
        // ...Array(emptyCellsCount).fill(""),
        <b>{result.totalSplitTime}</b>,
      ];

      return (
        <tr className="text-center" key={rowIndex}>
          {bodyCells.map((cellText, cellIndex) => (
            <td className="text-center" key={cellIndex}>
              {cellText}
            </td>
          ))}
        </tr>
      );
    });
  }

  const handleDownloadRaceResultsPDF = async (eventId, raceId) => {
    try {
      if (selectedColumnsPdf.length === 0) {
        toast.warn("Please select at least one column to download!");
        return;
      }
      const reorderedSelectedColumnsPdf = headerCells.filter((header) =>
        selectedColumnsPdf.includes(header)
      );

      const response = await generateResultsPDFHandler(
        eventId,
        raceId,
        reorderedSelectedColumnsPdf
      );

      // Convert the blob response to a data URL
      const blob = new Blob([response], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      // Create a link and click it to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${raceData.raceName}_results.pdf`); // Set desired file name and extension
      document.body.appendChild(link);
      link.click();

      // Clean up the URL and remove the link
      URL.revokeObjectURL(url);
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadRaceResults = async () => {
    try {
      if (selectedColumnsCsv.length === 0) {
        toast.warn("Please select at least one column to download!");
        return;
      }

      const reorderedSelectedColumnsCsv = headerCells.filter((header) =>
        selectedColumnsCsv.includes(header)
      );

      const response = await generateResultsExcelHandler(
        organizatedByEvent,
        organizatedByRace,
        selectedColumnsCsv
      );

      // Create a URL for the blob data
      const url = window.URL.createObjectURL(new Blob([response]));

      // Create a link and click it to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${raceData.raceName}_results.csv`); // Set desired file name and extension
      document.body.appendChild(link);
      link.click();

      // Clean up the URL and remove the link
      URL.revokeObjectURL(url);
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      <ToastContainer />

      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-12">
            <div className="card col-12">
              <div className="card-body">
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    {events?.length > 0 && (
                      <React.Fragment>
                        <label>Select Event</label>

                        <Select
                          style={{
                            lineHeight: "40px",
                            color: "#7e7e7e",
                            paddingLeft: "15px",
                          }}
                          value={
                            events.find(
                              (event) => event._id === organizatedByEvent
                            )
                              ? {
                                  value: events.find(
                                    (event) => event._id === organizatedByEvent
                                  )._id,
                                  label: events.find(
                                    (event) => event._id === organizatedByEvent
                                  ).name,
                                }
                              : null
                          }
                          onChange={handleEventChange}
                          options={events.map((event) => ({
                            value: event._id,
                            label: event.name,
                          }))}
                          placeholder="Select Event"
                        />
                      </React.Fragment>
                    )}
                  </div>
                  {!isRaceSelectDisabled && (
                    <div className="form-group mb-3 col-md-6">
                      {races.length > 0 ? (
                        <React.Fragment>
                          <label>Select Race</label>
                          <Select
                            style={{
                              lineHeight: "40px",
                              color: "#7e7e7e",
                              paddingLeft: " 15px",
                            }}
                            value={
                              races.find(
                                (race) => race._id === organizatedByRace
                              )
                                ? {
                                    value: races.find(
                                      (race) => race._id === organizatedByRace
                                    )._id,
                                    label: races.find(
                                      (race) => race._id === organizatedByRace
                                    ).raceName,
                                  }
                                : null
                            }
                            onChange={handleRaceChange}
                            options={races.map((race) => ({
                              value: race._id,
                              label: race.raceName,
                            }))}
                            placeholder="Select Race"
                          />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <label>Select Race</label>
                          {showAlert && (
                            <div className="alert alert-warning" role="alert">
                              No race available for this event
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-md-12 text-end">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleResultsSubmit}
                      disabled={isSearchButtonDisabled}
                      style={{
                        pointerEvents: isSearchButtonDisabled ? "auto" : "",
                        cursor: isSearchButtonDisabled ? "not-allowed" : "",
                      }}
                    >
                      Search Results
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>

        {isLoading ? (
          <div className="text-center mt-4">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden"></span>
            </div>
          </div>
        ) : (
          results.length > 0 && (
            <Row>
              <Col lg={12}>
                <Card>
                  <Card.Header>
                    <Card.Title style={{ fontSize: "19px" }}>
                      <b>{actualRaceName}</b> Results
                    </Card.Title>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        onClick={openPdfModal}
                        variant="secondary"
                        className="btn-sm"
                        style={{
                          marginRight: "10px",
                          borderRadius: "5px",
                          backgroundColor: "rgb(192, 18, 18)",
                          padding: "5px",
                          border: "none",
                        }}
                        title="Download Results (.pdf)"
                      >
                        <AiFillFilePdf style={{ fontSize: "x-large" }} />
                      </Button>

                      <Button
                        onClick={openCsvModal}
                        variant="primary"
                        className="btn-sm"
                        title="Download Results (.csv)"
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "green",
                          border: "none",
                          padding: "5px 9px",
                        }}
                      >
                        <FontAwesomeIcon icon={faFileExcel} size="2x" />
                      </Button>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Table responsive hover>
                      <thead>{headerRow}</thead>
                      <tbody>{bodyRows}</tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )
        )}

        <Modal
          className="fade bd-example-modal-md"
          size="md"
          show={state.pdfModal}
          onHide={() => {
            dispatch({ type: "pdfModal" });
          }}
        >
          <Modal.Header>
            {raceData && (
              <Modal.Title>
                Download <b>{raceData.raceName}</b> Results (PDF)
              </Modal.Title>
            )}
            <Button
              variant=""
              className="btn-close"
              onClick={() => dispatch({ type: "pdfModal" })}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            {raceData && (
              <React.Fragment>
                <div className="form-group">
                  <div className="row">
                    <div className="form-group mb-3 col-md-12">
                      <h6>Select the columns you want to download:</h6>
                    </div>
                  </div>
                  {headerCells.map((column, index) => (
                    <div className="form-check" key={index}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={column}
                        id={`columnCheckbox${index}`}
                        checked={selectedColumnsPdf.includes(column)}
                        onChange={() => handleColumnCheckboxChangePdf(column)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`columnCheckbox${index}`}
                      >
                        {column}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="text-end">
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={handleSelectAllColumnsPdf}
                  >
                    Select All
                  </Button>
                </div>
              </React.Fragment>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="danger light"
              onClick={() => dispatch({ type: "pdfModal" })}
            >
              Close
            </Button>

            <Button
              variant="primary"
              onClick={() =>
                handleDownloadRaceResultsPDF(eventData._id, raceData._id)
              }
            >
              Download
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="fade bd-example-modal-md"
          size="md"
          show={state.csvModal}
          onHide={() => {
            dispatch({ type: "csvModal" });
          }}
        >
          <Modal.Header>
            {raceData && (
              <Modal.Title>
                Download <b>{raceData.raceName}</b> Results (CSV)
              </Modal.Title>
            )}
            <Button
              variant=""
              className="btn-close"
              onClick={() => dispatch({ type: "csvModal" })}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            {raceData && (
              <React.Fragment>
                <div className="form-group">
                  <div className="row">
                    <div className="form-group mb-3 col-md-12">
                      <h6>Select the columns you want to download:</h6>
                    </div>
                  </div>
                  {headerCells.map((column, index) => (
                    <div className="form-check" key={index}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={column}
                        id={`columnCheckbox${index}`}
                        checked={selectedColumnsCsv.includes(column)}
                        onChange={() => handleColumnCheckboxChangeCsv(column)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`columnCheckbox${index}`}
                      >
                        {column}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="text-end">
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={handleSelectAllColumnsCsv}
                  >
                    Select All
                  </Button>
                </div>
              </React.Fragment>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="danger light"
              onClick={() => dispatch({ type: "csvModal" })}
            >
              Close
            </Button>

            <Button variant="primary" onClick={handleDownloadRaceResults}>
              Download
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Results;
