import React, { Suspense } from "react";

/// Components
import { connect } from "react-redux";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import Web from "./jsx/components/Site/Web";
import RaceRegistrationForm from "./jsx/components/Site/RaceRegistrationForm";
import PrivacyPolicy from "./jsx/components/Site/PrivacyPolicy";

// action
import { isAuthenticated } from "./store/selectors/AuthSelectors";
/// Style
import "./jsx/other/swiper/swiper-bundle.min.css";
import "./jsx/other/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }
  return ComponentWithRouterProp;
}

function Web1(props) {
  let routeblog = (
    <Routes>
      <Route path="/" element={<Web />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/race-registration/:eventId/:raceId" element={<RaceRegistrationForm />} />
    </Routes>
  );

  return (
    <Suspense
      fallback={
        <div id="preloader">
          <div className="sk-three-bounce">
            <div className="sk-child sk-bounce1"></div>
            <div className="sk-child sk-bounce2"></div>
            <div className="sk-child sk-bounce3"></div>
          </div>
        </div>
      }
    >
      {routeblog}
    </Suspense>
  );
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(Web1));
