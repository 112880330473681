import axios from "axios";

const getAllEvents = async () => {
  try {
    const response = await axios.get("/events");
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return [];
    } else {
      console.error("Failed to fetch events", error);
    }
  }
};

const getEventById = async (eventId) => {
  try {
    const response = await axios.get(`/event/${eventId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const updateEvent = async (eventId, editedData) => {
  try {
    await axios.put(`/event/${eventId}`, editedData);
    return true;
  } catch (error) {
    console.error("Failed to update event", error);
    return false;
  }
};

const deleteEvent = async (eventId) => {
  try {
    await axios.delete(`/event/${eventId}`);
    return true;
  } catch (error) {
    console.error("Failed to delete event", error);
    return false;
  }
};

const transformDateEvent = (date) => {
  const timestampInString = `${date}T${"00:00:00"}`;
  const timestampInMilliseconds = Date.parse(timestampInString);
  const timestampInSeconds = Math.floor(timestampInMilliseconds / 1000);
  console.log("timestampInSeconds", timestampInSeconds);
  return timestampInSeconds;
};

const createEvent = async (name, date) => {
  try {
    const response = await axios.post("/event/create", {
      headers: {
        "Content-Type": "application/json",
      },
      name,
      date: transformDateEvent(date),
    });
    return response.data.event;
  } catch (error) {
    console.error("Failed to create event", error);
    throw error;
  }
};
export { getAllEvents, getEventById, updateEvent, deleteEvent, createEvent };
