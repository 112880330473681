import axios from "axios";

const getAllTrackers = async () => {
  try {
    const response = await axios.get("/trackers");
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return [];
    } else {
      console.error("Failed to fetch trackers", error);
    }
  }
};

const getAllRaceTrackers = async (raceId) => {
  try {
    const response = await axios.get(`/trackers/${raceId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch trackers", error);
  }
};

const createTracker = async (trackerData) => {
  try {
    const response = await axios.post("/tracker/create", trackerData);
    return response;
  } catch (error) {
    console.error("Failed to create tracker", error);
    throw { statusCode: error.response.status, message: error.response.data };
  }
};

const deleteTracker = async (trackerId) => {
  try {
    const response = await axios.delete(`/tracker/${trackerId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to delete tracker", error);
  }
};

const getTrackerById = async (trackerId) => {
  try {
    const response = await axios.get(`/tracker/${trackerId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch tracker", error);
  }
};

const pinCheck = async (trackerId, oldPin, newPin) => {
  try {
    const response = await axios.post(`/tracker/${trackerId}/check-pin`, {
      oldPin: oldPin,
      newPin: newPin,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to check pin", error);
  }
};

const editTracker = async (trackerId, trackerData) => {
  try {
    const response = await axios.put(`/tracker/${trackerId}`, trackerData);
    return response.data;
  } catch (error) {
    console.error("Failed to edit tracker", error);
  }
};

export {
  getAllTrackers,
  getAllRaceTrackers,
  createTracker,
  deleteTracker,
  getTrackerById,
  editTracker,
  pinCheck,
};
