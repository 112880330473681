import { formatError, login, register } from "../../services/AuthService";
import swal from "sweetalert";
import axios from "axios";

export const SIGNUP_CONFIRMED_ACTION = "[signUp action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signUp action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const NAVTOGGLE = "NAVTOGGLE";
export const SIDEBAR_COLLAPSE_TOGGLE = "SIDEBAR_COLLAPSE_TOGGLE";

export function registerAction(
  fullName,
  username,
  signEmail,
  signPassword,
  navigate
) {
  return async (dispatch) => {
    try {
      const response = await register(
        fullName,
        username,
        signEmail,
        signPassword
      );
      // Assuming your registration response contains a token

      dispatch(confirmedSignupAction(response.data.token));
      dispatch(loginConfirmedAction(response.data.token));
      const responsse = await axios.post("/api/verify-email", {
        secret: process.env.REACT_APP_VERIFY_EMAIL_SECRET,
        email: signEmail,
      });
      navigate("/dashboard");

      // const verifyEmail = await axios.post("/api/verify-email", {
      //   email: signEmail,
      //   secret: process.env.REACT_APP_VERIFY_EMAIL_SECRET,
      // });
      // swal({
      //   title:
      //     verifyEmail.status === 200
      //       ? "Email sent successfully"
      //       : "Email was not sent",
      //   text: verifyEmail.data.message,
      //   icon: verifyEmail.status === 200 ? "success" : "error",

      //   buttons: {
      //     cancel: "OK",
      //   },
      // });
    } catch (error) {
      swal({
        title: "Error",
        text: error.response.data.message,
        icon: "error",
        buttons: {
          cancel: "OK",
        },
      });
      const errorMessage = formatError(error.response.data);
      dispatch(signupFailedAction(errorMessage));
    }
  };
}

export async function forgetPasswordAction(email, token, navigate) {
  try {
    const REACT_APP_FORGET_PASSWORD_SECRET =
      process.env.REACT_APP_FORGET_PASSWORD_SECRET;

    // Send the token to the backend
    const response = await fetch("/api/generate-token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, REACT_APP_FORGET_PASSWORD_SECRET }),
    });

    if (response.ok) {
      swal(
        "Check your email!",
        "You have 5 minutes to verify your email!",
        "success"
      );
    } else {
      const errorData = await response.json();
      let errorMessage = "An error occurred";

      if (errorData && errorData.message) {
        errorMessage = errorData.message;
      }

      const statusCode = response.status;
      let icon = "error"; // Default to error
      let title = "Error"; // Default to error title

      // Handle different status codes for warnings and errors
      if (statusCode === 400 || statusCode === 404) {
        icon = "warning";
        title = "Warning";
      } else if (statusCode >= 500) {
        icon = "error";
        title = "Server Error";
      } else if (statusCode >= 200 && statusCode < 300) {
        icon = "success";
        title = "Success";
      }

      swal({
        title: title,
        text: errorMessage,
        icon: icon,
        className: "text-center-swal",
        buttons: {
          cancel: "OK",
        },
      }).then(() => {
        if (icon === "success") {
          // Redirect to login if successful
          navigate("/login");
        }
      });
    }
  } catch (error) {
    throw new Error("Error sending email", error);
  }
}

export function loginAction(email, password, navigate) {
  return async (dispatch) => {
    try {
      const response = await login(email, password);
      if (response) {
        dispatch(loginConfirmedAction(response.data.token));
        navigate("/dashboard");
      }
    } catch (error) {
      const errorMessage = formatError(error.response.data);
      if (errorMessage) {
        dispatch(loginFailedAction(errorMessage));
      } else {
        // Handle other error scenarios
      }
    }
  };
}

export function Logout(navigate) {
  // localStorage.removeItem("userDetails");
  // navigate("/login");

  return {
    type: LOGOUT_ACTION,
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(data) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload: data,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}

export const navtoggle = () => {
  // alert(1);
  return {
    type: "NAVTOGGLE",
  };
};

export const sidebarCollapseToggle = () => {
  return {
    type: SIDEBAR_COLLAPSE_TOGGLE,
  };
};
