import axios from "axios";

const getAllProducts = async () => {
    try {
        const response = await axios.get('/products');
        return response.data;
    }
    catch (error) {
        console.error("Failed to fetch products", error);
    }
}

const createCheckoutSessionStandard = async () => {
    try {
        const response = await axios.post(`/create-checkout-session/standard`);
        return response.data;
    }
    catch (error) {
        console.error("Failed to create checkout session", error);
    }
}

const createCheckoutSessionPremium = async () => {
    try {
        const response = await axios.post(`/create-checkout-session/premium`);
        return response.data;
    }
    catch (error) {
        console.error("Failed to create checkout session", error);
    }
}

const checkSubscription = async () => {
    try {
        const response = await axios.get('/check-subscription');
        return response.data;
    }
    catch (error) {
        console.error("Failed to check subscription", error);
    }
}

const cancelSubscription = async () => {
    try {
        const response = await axios.post('/cancel-subscription');
        return response.data;
    }
    catch (error) {
        console.error("Failed to cancel subscription", error);
    }
}

export {
    getAllProducts,
    createCheckoutSessionStandard,
    createCheckoutSessionPremium,
    checkSubscription,
    cancelSubscription
}
