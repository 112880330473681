import { toast } from "react-toastify";

const notifyFirstNameWarning = () => {
  toast.warn("Please enter new participant's first name.", {
    position: "top-center",

    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
const notifyWarning = () => {
  toast.warn("Please select a file for upload!", {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyNameWarning = () => {
  toast.warn("Please enter participant name.", {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyLastNameWarning = () => {
  toast.warn("Please enter new participant's last name.", {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyGenderWarning = () => {
  toast.warn("Please select your gender.", {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyBibValidation = () => {
  toast.warn("Bib field must contain only numbers, please enter a valid bib.", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyAgeValidation = () => {
  toast.warn("Age field must contain only numbers, please enter a valid age.", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export {
  notifyWarning,
  notifyNameWarning,
  notifyFirstNameWarning,
  notifyLastNameWarning,
  notifyGenderWarning,
  notifyBibValidation,
  notifyAgeValidation,
};
