// Import necessary dependencies
import React, { useContext, useReducer, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { Link } from "react-router-dom";
import "../../components/Dashboard/customCss/sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import { MenuList } from "./Menu";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import "../../components/Dashboard/customCss/xclx.css";

// Reducer function
const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

// Initial state
const initialState = {
  active: "",
  activeSubmenu: "",
};

// SideBar component
const SideBar = () => {
  // Use Context
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar,
    sideBarState,
  } = useContext(ThemeContext);
  
  // Use Reducer
  const [state, setState] = useReducer(reducer, initialState);

  // For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);

  // Handle scroll position
  useScrollPosition(({ prevPos, currPos }) => {
    const isShow = currPos.y > prevPos.y;
    if (isShow !== hideOnScroll) setHideOnScroll(isShow);
  }, [hideOnScroll]);

  // Handle active menu
  const handleMenuActive = (status) => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  };

  // Handle active submenu
  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };

  // Current path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let route =window.location.pathname;
  route = route.split("/");
  route = route[2]

  return (
    <div
      onMouseEnter={() => ChangeIconSidebar(true)}
      onMouseLeave={() => ChangeIconSidebar(false)}
      className={`deznav  border-right ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <div className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {MenuList.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index}>
                  {data.title}
                </li>
              );
            } else {
              return (
                <React.Fragment key={index}>
                  <li
                    className={`${
                      route === data.title.toLowerCase() ? "mm-active" : ""
                    }`}
                  >
                    {data.content && data.content.length > 0 ? ( 
                      <React.Fragment> 
                        <Link 
                          to="#"
                          className="has-arrow"
                          onClick={() => handleMenuActive(data.title)}
                        >
                          <div className="menu-icon">{data.iconStyle}</div>{" "}
                          <span className="nav-text">{data.title}</span>
                          {data.update && data.update.length > 0 && (
                            <span className="badge badge-xs badge-danger ms-2">
                              {data.update}
                            </span>
                          )}
                          {sideBarState && (
                            <FontAwesomeIcon
                              className="nav-text"
                              icon={
                                state.active === data.title
                                  ? faChevronDown
                                  : faChevronRight
                              }
                              style={{
                                float: "right",
                                height: "10px",
                                marginTop: "2px",
                                marginRight: "-10px",
                              }}
                            />
                          )}
                        </Link>
                        <Collapse
                          in={state.active === data.title ? true : false}
                        >
                          <ul
                            className={`${
                              menuClass === "mm-collapse" ? "mm-show" : ""
                            }`}
                          >
                            {data.content &&
                              data.content.map((data, index) => {
                                return (
                                  <li
                                    key={index}
                                    className={`${
                                      state.activeSubmenu === data.title
                                        ? "mm-active"
                                        : ""
                                    }`}
                                  >
                                    {data.content && data.content.length > 0 ? (
                                      <React.Fragment>
                                        <Link
                                          to={data.to}
                                          className={
                                            data.hasMenu ? "has-arrow" : ""
                                          }
                                          onClick={() => {
                                            handleSubmenuActive(data.title);
                                          }}
                                        >
                                          {data.title}
                                        </Link>
                                        <Collapse
                                          in={
                                            state.activeSubmenu === data.title
                                              ? true
                                              : false
                                          }
                                        >
                                          <ul
                                            className={`${
                                              menuClass === "mm-collapse"
                                                ? "mm-show"
                                                : ""
                                            }`}
                                          >
                                            {data.content &&
                                              data.content.map((data, ind) => {
                                                return (
                                                  <li key={ind}>
                                                    <Link
                                                      className={`${
                                                        path === data.to
                                                          ? "mm-active"
                                                          : ""
                                                      }`}
                                                      to={data.to}
                                                    >
                                                      {data.title}
                                                    </Link>
                                                  </li>
                                                );
                                              })}
                                          </ul>
                                        </Collapse>
                                      </React.Fragment>
                                    ) : (
                                      <Link to={data.to}>{data.title}</Link>
                                    )}
                                  </li>
                                );
                              })}
                          </ul>
                        </Collapse>
                      </React.Fragment>
                    ) : (
                      <Link to={data.to} title={data.title}>
                        <div className="menu-icon">{data.iconStyle}</div>{" "}
                        <span className="nav-text">{data.title}</span>
                        {data.update && data.update.length > 0 ? (
                          <span className="badge badge-xs badge-danger ms-2">
                            {data.update}
                          </span>
                        ) : (
                          ""
                        )}
                      </Link>
                    )}
                  </li>
                </React.Fragment>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
