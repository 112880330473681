import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import useRaces from "../../../../hooks/useRaces";

const CompletedRaces = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const completedRacesPerPage = 8;
  const lastIndex = currentPage * completedRacesPerPage;
  const firstIndex = lastIndex - completedRacesPerPage;
  const [loading, setLoading] = useState(true);

  const [races, setRaces] = useState([]);

  const { fetchAllRaces } = useRaces();
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  useEffect(() => {
    const fetchRaces = async () => {
      try {
        const response = await fetchAllRaces();

        if (response) {
          const { completedRaces } = response;
          setRaces(completedRaces);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchRaces();
  }, []);

  const myRaces = races ? races?.slice(firstIndex, lastIndex) : 0;
  const npage = races ? Math.ceil(races?.length / completedRacesPerPage) : 0;
  const number = npage ? [...Array(npage + 1).keys()]?.slice(1) : 0;

  const formatDate = (timestamp) => {
    const inputDatetime = new Date(timestamp * 1000);
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const formattedDate = inputDatetime.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  return (
    <React.Fragment>
      {loading ? (
        <div className="text-center mt-4">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden"></span>
          </div>
        </div>
      ) : (
        <div className="card">
          <div className="card-body p-0">
            <div className="table-responsive active-projects style-1">
              <div className="tbl-caption">
                <h4 className="heading mb-0">Completed Races Overview</h4>
              </div>
              <div
                id="projects-tbl_wrapper"
                className="dataTables_wrapper no-footer mb-0"
              >
                <table
                  id="projects-tbl"
                  className="table dataTable no-footer mb-2 mb-sm-0"
                >
                  {myRaces.length > 0 ? (
                    <thead>
                      <tr>
                        <th className="text-center col-1">Race Name</th>
                        <th className="text-center col-2">Location</th>
                        <th className="text-center col-2">Participants</th>
                        <th className="text-center col-2">Results</th>
                        <th className="text-center col-2">Status</th>
                        <th className="text-center col-1">Date</th>
                      </tr>
                    </thead>
                  ) : null}
                  {myRaces.length > 0 ? (
                    <tbody>
                      {myRaces.map((race, index) => (
                        <tr key={index}>
                          <td className="text-center col-1">{race.raceName}</td>
                          <td className="text-center col-2">{race.location}</td>
                          <td className="text-center col-2">
                            <Link
                              to={`/events/${race.organizatedBy}/races/${race._id}`}
                            >
                              <Button variant="primary light btn-xxs">
                                View All
                              </Button>
                            </Link>
                          </td>
                          <td className="text-center col-2">
                            <Link
                              to={`/events/${race.organizatedBy}/races/${race._id}/results`}
                            >
                              <Button variant="primary light btn-xxs">
                                View Details
                              </Button>
                            </Link>
                          </td>
                          <td className="text-center col-2">
                            <span
                              className={`badge light border-0 badge-success`}
                            >
                              Completed
                            </span>
                          </td>
                          <td className="text-center col-1">
                            <span>{formatDate(race.dateTime)}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td>
                          {" "}
                          <p
                            style={{
                              textAlign: "center",
                              justifyContent: "center",
                              marginTop: "15px",
                              fontSize: "15px",
                            }}
                          >
                            No races available yet.
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
                {myRaces.length > 0 && (
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Showing {lastIndex - completedRacesPerPage + 1} to{" "}
                      {lastIndex} of {races.length} races
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers justify-content-center"
                      id="example2_paginate"
                    >
                      <Link
                        className="paginate_button previous disabled"
                        to="#"
                        onClick={prePage}
                        style={{
                          opacity: currentPage === npage ? 0.5 : 1,
                          pointerEvents:
                            currentPage === npage ? "none" : "auto",
                        }}
                      >
                        <i className="fa-solid fa-angle-left" />
                      </Link>
                      <span>
                        {number.map((n, i) => (
                          <Link
                            className={`paginate_button ${
                              currentPage === n ? "current" : ""
                            } `}
                            key={i}
                            onClick={() => changeCPage(n)}
                          >
                            {n}
                          </Link>
                        ))}
                      </span>
                      <Link
                        className="paginate_button next"
                        to="#"
                        onClick={nextPage}
                        style={{
                          pointerEvents:
                            currentPage ===
                            Math.ceil(races.length / completedRacesPerPage)
                              ? "none"
                              : "auto",
                          opacity:
                            currentPage ===
                            Math.ceil(races.length / completedRacesPerPage)
                              ? 0.5
                              : 1,
                        }}
                      >
                        <i className="fa-solid fa-angle-right" />
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CompletedRaces;
