import React from "react";
import { useNavigate } from "react-router-dom";

import { SVGICON } from "../../constant/theme";

const EditProfile = ({ closeDropdown }) => {
  let navigate = useNavigate();

  const routeChange = () => {
    let path = `/edit-admin`;
    navigate(path);
    closeDropdown();
  };

  return (
    <React.Fragment>
      <button
        className="dropdown-item ai-icon ms-1 logout-btn"
        onClick={routeChange}
      >
        {SVGICON.UserSvg} <span className="ms-2">Profile</span>
      </button>
    </React.Fragment>
  );
};

export default EditProfile;
