import React, { useState, useEffect, Fragment } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Card, Table, Button } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "../../layouts/ScrollToTop";
import swal from "sweetalert";
import useEvents from "../../../hooks/useEvents";
import useSplitTimes from "../../../hooks/useSplitTimes";
import useRaces from "../../../hooks/useRaces";
import "./customCss/xclx.css";

const EditConfirmedSplits = () => {
  const { raceId, eventId } = useParams();
  const [race, setRace] = useState({});
  const [event, setEvent] = useState([]);
  const [selectedSplits, setSelectedSplits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [splitTimes, setSplitTimes] = useState([]);
  const [loadState, setLoadState] = useState(false);
  const [openOptions, setOpenOptions] = useState({});
  const [trackersData, setTrackersData] = useState([]);
  const [trackerShow, setTrackerShow] = useState({});
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const navigate = useNavigate();
  const [selectedParticipantsSplits, setSelectedParticipantsSplits] = useState(
    []
  );

  const { fetchEventById } = useEvents();

  const { fetchSplitTimesForEditConfirmed } = useSplitTimes();

  const { generateConfirmedSplitsHandler } = useRaces();

  useEffect(() => {
    const fetchRace = async () => {
      try {
        setLoadState(true);

        const response = await fetchSplitTimesForEditConfirmed(raceId);
        console.log(response);
        const { race, trackers, splitTimes, inputChecked, selectedSplits } =
          response;

        setRace(race);
        setTrackersData(trackers);
        setSplitTimes(splitTimes);
        setSelectedParticipantsSplits(inputChecked);
        setSelectedSplits(selectedSplits);

        if (race.status === 0 || race.status === 2) {
          swal({
            title: "Race has no results yet.",
            text: "Please generate some split times first and then come back to edit them.",
            icon: "info",
            closeOnClickOutside: false,
            className: "text-center-swal",
            buttons: {
              later: {
                text: "Later",
                value: "later",
                className: "btn-later",
              },
              raceDetails: {
                text: "Race Details",
                value: "raceDetails",
                className: "btn-addNew",
              },
            },
          }).then((value) => {
            if (value === "later") {
              navigate(`/events/${eventId}/races`);
            } else if (value === "raceDetails") {
              navigate(`/events/${eventId}/races/${raceId}`);
            }
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadState(false);
      }
    };

    const fetchEvent = async () => {
      try {
        const response = await fetchEventById(eventId);
        console.log(response.event);
        setEvent(response.event);
      } catch (error) {
        swal("Failed!", "Event details error!", "error");
        console.error(error);
      }
    };

    fetchEvent();
    fetchRace();
  }, [raceId, eventId]);

  function normalTime(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    return date.toLocaleTimeString("en-US", options);
  }

  const handleTrackerNameToggle = (e, participantId, splitId, time) => {
    setOpenOptions((prevOpenOptions) => {
      const newOpenOptions = {
        ...prevOpenOptions,
        [participantId]: {
          ...prevOpenOptions[participantId],
          [splitId]: {
            ...prevOpenOptions[participantId]?.[splitId],
            [time]: !prevOpenOptions[participantId]?.[splitId]?.[time],
          },
        },
      };

      return newOpenOptions;
    });
  };

  const handleSplitCheckboxChange = (
    participant_id,
    splitId,
    splitTime,
    trackerId,
    checked
  ) => {
    setSelectedSplits((prevSelectedSplits) => {
      // Create a new array by filtering out the splits for the current participant and splitId
      // const updatedSelectedSplits = prevSelectedSplits.filter(
      //   (split) =>
      //     split.participant_id !== participant_id || split.splitId !== splitId
      // );

      // // If the checkbox is checked, add the new split
      // if (checked) {
      //   updatedSelectedSplits.push({
      //     splitId,
      //     splitTime,
      //     trackerId: trackerId,
      //     participant_id,
      //   });
      // }

      const updatedSelectedSplits = prevSelectedSplits.map((split) => {
        // Check if the current split matches the participant_id and splitId
        if (
          split.participantId === participant_id &&
          split.splitId === splitId
        ) {
          // Update the splitTime of the matched split
          return { ...split, splitTime: splitTime };
        }
        return split; // Return the split without modification if not matched
      });

      // Dynamically enable/disable options for the next split
      const nextSplitIndex =
        race.splits.findIndex((split) => split._id === splitId) + 1;
      const nextSplitId =
        nextSplitIndex < race.splits.length
          ? race.splits[nextSplitIndex]._id
          : null;

      if (nextSplitId) {
        const selectedTimeOfNextSplit = updatedSelectedSplits.find(
          (split) => split.splitId === nextSplitId
        )?.splitTime;

        updatedSelectedSplits.forEach((split) => {
          if (split.splitId === nextSplitId) {
            split.disabled = split.splitTime !== selectedTimeOfNextSplit;
          }
        });
      }
      return updatedSelectedSplits;
    });
  };

  const handleSubmit = async () => {
    try {
      setButtonIsLoading(true);
      setIsLoading(true);
      // // Check if there are any changes in the split times
      // let hasChanges = false;
      // selectedParticipantsSplits.forEach((participantSplits) => {
      //   participantSplits.splits.forEach((split) => {
      //     if (
      //       split.SPLITTIME !== split.lastSplit &&
      //       !selectedSplits.some(
      //         (selectedSplit) =>
      //           selectedSplit.participant_id ===
      //             participantSplits.participant_id &&
      //           selectedSplit.splitId === split.SPLITTIME
      //       )
      //     ) {
      //       hasChanges = true;
      //     }
      //   });
      // });

      // // If there are no changes, add only the default selected split times to selectedSplits
      // if (!hasChanges) {
      //   selectedParticipantsSplits.forEach((participantSplits) => {
      //     participantSplits.splits.forEach((split) => {
      //       if (split.defaultChecked) {
      //         selectedSplits.push({
      //           splitId: split.splitId,
      //           splitTime: split.SPLITTIME,
      //           trackerName: split.participant,
      //           participant_id: split.participantId,
      //         });
      //       }
      //     });
      //   });
      // }
      // Send the selectedSplits to the server
      const response = await generateConfirmedSplitsHandler(
        raceId,
        selectedSplits
      );
      console.log(response);
      swal("Success", "Split times updated successfully.", "success");
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

      setSelectedSplits(selectedSplits);
    } catch (error) {
      console.error("Error calling the API route:", error);
    } finally {
      setIsLoading(false);
      setButtonIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      <ToastContainer />

      {loadState && (
        <div className="d-flex align-items-center justify-content-center vh-100">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden"></span>
          </div>
        </div>
      )}
      {race.status === 1 && (
        <div className="container-fluid">
          {!loadState ? (
            <React.Fragment>
              {race && race.participants ? (
                <div>
                  <Card>
                    <Card.Header>
                      <Card.Title style={{ fontSize: "19px" }}>
                        {event.name && (
                          <React.Fragment>
                            <Link to={`/events/${eventId}/races`}>
                              <b>{event.name}</b>
                            </Link>{" "}
                            Races &gt;{" "}
                            <Link to={`/events/${eventId}/races/${raceId}`}>
                              <b>{race.raceName}</b>
                            </Link>{" "}
                            Race &gt; Edit Results
                          </React.Fragment>
                        )}
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th className="text-center col-1">
                              <strong>Participant</strong>
                            </th>
                            <th className="text-center col-2">
                              <strong>Bib</strong>
                            </th>
                            <th className="text-center col-2">
                              <strong>Start</strong>
                            </th>
                            {race.splits.map((raceSplit, splitIndex) => {
                              // Skip the first split when isGunRace is true
                              if (splitIndex > 0 || !race.isGunRace) {
                                return (
                                  <th
                                    className="text-center col-2"
                                    key={raceSplit._id}
                                  >
                                    <strong>{raceSplit.name}</strong>
                                  </th>
                                );
                              }
                              return null;
                            })}
                          </tr>
                        </thead>

                        <tbody>
                          {race.participants.map((participant) => (
                            <tr key={participant._id}>
                              <td className="text-center col-1">
                                <strong>
                                  {participant.firstName} {participant.lastName}
                                </strong>
                              </td>
                              <td className="text-center col-2">
                                <strong>{participant.bibNumber}</strong>
                              </td>
                              <td className="text-center col-2">
                                {race.isGunRace ? (
                                  <label style={{ display: "block" }}>
                                    <input
                                      id={"disablePosition"}
                                      type="radio"
                                      className="form-check-input"
                                      value={normalTime(race.dateTime)}
                                      style={{
                                        margin: "auto",
                                        marginRight: "3px",
                                      }}
                                      defaultChecked={true}
                                    />
                                    {normalTime(race.dateTime)}
                                  </label>
                                ) : (
                                  ""
                                )}
                              </td>
                              {race.splits.map((raceSplit, splitIndex) => {
                                // Filter splitTimes for this participant and raceSplit
                                const splitData = splitTimes.find((split) => {
                                  return (
                                    split.participantId === participant._id &&
                                    split.splitId === raceSplit._id
                                  );
                                });

                                // Render the split data here
                                if (splitIndex > 0 || !race.isGunRace) {
                                  return (
                                    <td
                                      className="text-center col-2"
                                      key={raceSplit._id}
                                    >
                                      {splitData ? (
                                        <div>
                                          {splitData.splitTime.map(
                                            (time, index) => {
                                              const isConfirmed =
                                                selectedSplits.some(
                                                  (split) =>
                                                    split.participantId ===
                                                      participant._id &&
                                                    split.splitId ===
                                                      raceSplit._id &&
                                                    split.splitTime.toString() ===
                                                      time
                                                );

                                              const precedingSplit =
                                                selectedSplits.find(
                                                  (split) =>
                                                    split.participantId ===
                                                      participant._id &&
                                                    split.splitId ===
                                                      race.splits[
                                                        splitIndex - 1
                                                      ]?._id
                                                );

                                              const isDisabled =
                                                precedingSplit &&
                                                time < precedingSplit.splitTime;

                                              return (
                                                <div
                                                  key={index}
                                                  style={{
                                                    position: "relative",
                                                    marginBottom: "5px",
                                                  }}
                                                >
                                                  <label
                                                    style={{
                                                      display: "block",
                                                    }}
                                                    key={index}
                                                    className={
                                                      isDisabled
                                                        ? "disabled-option"
                                                        : ""
                                                    }
                                                  >
                                                    <input
                                                      id={"disablePosition"}
                                                      type="radio"
                                                      className={`form-check-input ${
                                                        isDisabled
                                                          ? "disabled-option"
                                                          : ""
                                                      }`}
                                                      name={`split_${participant._id}_${raceSplit._id}`}
                                                      value={normalTime(time)}
                                                      style={{
                                                        margin: "auto",
                                                        marginRight: "3px",
                                                      }}
                                                      onChange={(e) =>
                                                        handleSplitCheckboxChange(
                                                          participant._id,
                                                          raceSplit._id,
                                                          time,
                                                          splitData.trackerId,
                                                          e.target.checked
                                                        )
                                                      }
                                                      defaultChecked={
                                                        isConfirmed
                                                      }
                                                      disabled={isDisabled}
                                                    />
                                                    {normalTime(time)}
                                                  </label>
                                                  <span
                                                    date-value={time}
                                                    onClick={(e) => {
                                                      handleTrackerNameToggle(
                                                        e,
                                                        participant._id,
                                                        raceSplit._id,
                                                        time
                                                      );
                                                      setTrackerShow(
                                                        (prevTrackerShow) => ({
                                                          ...prevTrackerShow,
                                                          [`${participant._id}_${raceSplit._id}_${time}`]:
                                                            !prevTrackerShow[
                                                              `${participant._id}_${raceSplit._id}_${time}`
                                                            ],
                                                        })
                                                      );
                                                    }}
                                                    style={{
                                                      cursor: "pointer",
                                                      position: "absolute",
                                                      top: "-2px",
                                                      marginLeft: "40px",
                                                      transition:
                                                        "transform 0.5s ease",
                                                      transform: openOptions[
                                                        participant._id
                                                      ]?.[raceSplit._id]?.[time]
                                                        ? "rotate(360deg)"
                                                        : "rotate(0deg)",
                                                    }}
                                                    className="d-inline-block"
                                                  >
                                                    {openOptions[
                                                      participant._id
                                                    ]?.[raceSplit._id]?.[
                                                      time
                                                    ] ? (
                                                      <FaCaretUp />
                                                    ) : (
                                                      <FaCaretDown />
                                                    )}
                                                  </span>
                                                  <div
                                                    className={`ml-1${
                                                      trackerShow[
                                                        `${participant._id}_${raceSplit._id}_${time}`
                                                      ]
                                                        ? " showtracker"
                                                        : " hidetracker"
                                                    }`}
                                                  >
                                                    {openOptions[
                                                      participant._id
                                                    ]?.[raceSplit._id]?.[
                                                      time
                                                    ] && (
                                                      <span
                                                        className={`ml-2 trackerSpan`}
                                                      >
                                                        {
                                                          trackersData.find(
                                                            (tracker) =>
                                                              tracker._id ===
                                                              splitData.trackerId
                                                          ).trackerName
                                                        }
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      ) : (
                                        <p>No time recorded</p>
                                      )}
                                    </td>
                                  );
                                }
                              })}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="text-end toolbar toolbar-bottom p-2">
                        <Button
                          className="btn btn-primary"
                          onClick={handleSubmit}
                          disabled={buttonIsLoading}
                        >
                          {buttonIsLoading
                            ? "Saving Changes..."
                            : "Save Changes"}
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ) : (
                <p>No participants</p>
              )}
            </React.Fragment>
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
};

export default EditConfirmedSplits;
