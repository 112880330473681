import { getAllRaces, getAllRacesByEventId, getRaceByEventId, getAllRacesByTrackerId, editSplits, getRaceByIds, createRace, deleteRace, editRace, generateConfirmedSplits, updateRaceTime } from "./requests/raceRequests";
const useRaces = () => {
  const fetchAllRaces = async () => {
    try {
      const fetchedRaces = await getAllRaces();
      return fetchedRaces;
    } catch (error) {
      console.error("Failed to fetch races", error);
    }
  }

  const fetchRacesByEventId = async (eventId) => {
    try {
      const fetchedRaces = await getAllRacesByEventId(eventId);
      return fetchedRaces;
    } catch (error) {
      console.error("Failed to fetch races by eventId", error);
    }
  }

  const fetchRaceByEventId = async (eventId) => {
    try {
      const fetchedRace = await getRaceByEventId(eventId);
      return fetchedRace;
    } catch (error) {
      console.error("Failed to fetch race by eventId", error);
    }
  }

  const fetchRacesByTrackerId = async (trackerId) => {
    try {
      const fetchedRaces = await getAllRacesByTrackerId(trackerId);
      return fetchedRaces;
    } catch (error) {
      console.error("Failed to fetch races by trackerId", error);
    }
  }

  const fetchRaceByIds = async (raceId, eventId) => {
    try {
      const fetchedRace = await getRaceByIds(raceId, eventId);
      return fetchedRace;
    } catch (error) {
      console.error("Failed to fetch race by ID", error);
    }
  }

  const createRaceHandler = async (raceData) => {
    try {
      const response = await createRace(raceData);
      return response;
    } catch (error) {
      console.error("Failed to create race", error);
    }
  }

  const deleteRaceHandler = async (raceId) => {
    try {
      const response = await deleteRace(raceId);
      return response;
    } catch (error) {
      console.error("Failed to delete race", error);
    }
  }

  const editRaceHandler = async (raceId, editedData) => {
    try {
      const response = await editRace(raceId, editedData);
      return response;
    }
    catch (error) {
      console.error("Failed to edit race", error);
    }
  }

  const editSplitsHandler = async (trackerId, raceId, splits) => {
    try {
      const response = await editSplits(trackerId, raceId, splits);
      return response;
    }
    catch (error) {
      console.error("Failed to edit splits", error);
    }
  }

  const generateConfirmedSplitsHandler = async (raceId, selectedSplits) => {
    try {
      const response = await generateConfirmedSplits(raceId, selectedSplits);
      return response;
    }
    catch (error) {
      console.error("Failed to generate confirmed splits", error);
    }
  }

  const updateRaceTimeHandler = async (raceId, raceTime) => {
    try {
      const response = await updateRaceTime(raceId, raceTime);
      return response;
    }
    catch (error) {
      console.error("Failed to update time", error);
    }
  }


  return {
    fetchAllRaces,
    fetchRacesByEventId,
    fetchRaceByEventId,
    fetchRacesByTrackerId,
    fetchRaceByIds,
    createRaceHandler,
    deleteRaceHandler,
    editRaceHandler,
    editSplitsHandler,
    generateConfirmedSplitsHandler,
    updateRaceTimeHandler
  };
};

export default useRaces;
