import React, { useState, useEffect, useReducer, lazy } from "react";
import { SVGICON } from "../../constant/theme";
import axios from "axios";
import {
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
  Dropdown,
} from "react-bootstrap";
import "./customCss/xclx.css";

import { Link, useNavigate } from "react-router-dom";
import useRaces from "../../../hooks/useRaces";
import useEvents from "../../../hooks/useEvents";
import useTrackers from "../../../hooks/useTrackers";

import CompletedRaces from "./elements/CompletedRaces";
import UpcomingBlog from "./elements/UpcomingBlog";

const initialState = {
  openEventModal: false,
  openRaceModal: false,
  openTrackerModal: false,
  openParticipantModal: false,
  openParticipantDetails: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "openEventModal":
      return {
        ...state,
        openEventModal: !state.openEventModal,
      };
    case "openRaceModal":
      return {
        ...state,
        openRaceModal: !state.openRaceModal,
      };
    case "openTrackerModal":
      return {
        ...state,
        openTrackerModal: !state.openTrackerModal,
      };
    case "openParticipantModal":
      return {
        ...state,
        openParticipantModal: !state.openParticipantModal,
      };
    case "openParticipantDetails":
      return {
        ...state,
        openParticipantDetails: !state.openParticipantDetails,
      };

    default:
      return state;
  }
};

const MainPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const [races, setRaces] = useState([]);
  const [events, setEvents] = useState([]);
  const [trackers, setTrackers] = useState([]);
  const [largestRaces, setLargestRaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [componentsLoaded, setComponentsLoaded] = useState(false);
  const [firstSixParticipants, setFirstSixParticipants] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);

  const { fetchAllRaces } = useRaces();
  const { fetchAllEvents } = useEvents();
  const { fetchAllTrackers } = useTrackers();

  const [cardBlog, setCardBlog] = useState([
    {
      svg: SVGICON.EventLogo,
      number: "",
      title: "No of total events",
      changetheme: "purple",
    },
    {
      svg: SVGICON.DashboardLogo,
      number: "",
      title: "No of total races",
      changetheme: "primary",
    },
    {
      svg: SVGICON.UserSvgGreen,
      number: "",
      title: "No of total participants",
      changetheme: "success",
    },
    {
      svg: SVGICON.UserThree,
      number: "",
      title: "No of total trackers",
      changetheme: "danger",
    },
  ]);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setComponentsLoaded(true);
    }, 2000);

    return () => clearTimeout(loadingTimeout);
  }, []);

  useEffect(() => {
    const fetchRaces = async () => {
      try {
        const response = await fetchAllRaces();
        setRaces(response);

        const { races, totalParticipants, largestRaces, allParticipants } =
          response;
        const racesData = response.races;

        if (racesData) {
          setFirstSixParticipants(allParticipants);
          setLargestRaces(largestRaces);

          // Update the cardBlog array with real values
          setCardBlog((prevCardBlog) => [
            ...prevCardBlog.slice(0, 1),
            {
              ...prevCardBlog[1],
              number: 0 || races?.length,
            },
            ...prevCardBlog.slice(2),
          ]);

          setCardBlog((prevCardBlog) => [
            ...prevCardBlog.slice(0, 2),
            {
              ...prevCardBlog[2],
              number: 0 || totalParticipants?.toString(),
            },
            ...prevCardBlog.slice(3),
          ]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchEvents = async () => {
      try {
        const response = await fetchAllEvents();
        setEvents(response.events);

        const eventsData = response.events;

        // Update the cardBlog array with real values
        setCardBlog((prevCardBlog) => [
          {
            ...prevCardBlog[0],
            number: 0 || response.events?.length.toString(),
          },
          ...prevCardBlog.slice(1),
        ]);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchTrackers = async () => {
      try {
        const response = await fetchAllTrackers();
        console.log("response", response);
        setTrackers(response.trackers);

        const trackersData = response;

        if (trackersData) {
          // Update the cardBlog array with real values
          setCardBlog((prevCardBlog) => [
            ...prevCardBlog.slice(0, 3),
            {
              ...prevCardBlog[3],
              number: response.trackers?.length || 0,
            },
          ]);
        }
      } catch (error) {
        alert("error");
        // Handle error cases
      }
    };

    fetchEvents();
    fetchTrackers();
    fetchRaces();
  }, []);

  const resetSelectedParticipant = () => {
    setSelectedParticipant(null);
  };

  const formatTrackerDate = (date) => {
    const inputDatetime = new Date(date);
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const formattedDate = inputDatetime.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  const openModal = (index) => {
    if (index === 0) {
      dispatch({ type: "openEventModal" });
    } else if (index === 1) {
      dispatch({ type: "openRaceModal" });
    } else if (index === 3) {
      dispatch({ type: "openTrackerModal" });
    } else if (index === 2) {
      dispatch({ type: "openParticipantModal" });
    }
  };

  const openParticipantDetailsModal = (participant) => {
    setSelectedParticipant(participant);
    dispatch({ type: "openParticipantDetails" });
  };

  const openRaceDetails = (eventId, raceId) => {
    navigate(`/events/${eventId}/races/${raceId}`);
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        {loading ? (
          <div className="text-center mt-4">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden"></span>
            </div>
          </div>
        ) : (
          <div className="row">
            {cardBlog.map((item, index) => (
              <div className="col-xl-3 col-sm-6" key={index}>
                <OverlayTrigger
                  key={index}
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-${index}`}>Click for more</Tooltip>
                  }
                >
                  <div
                    className="card box-hover"
                    onClick={() => openModal(index)}
                    style={{ cursor: "pointer", position: "relative" }}
                  >
                    <div className="card-body">
                      <div
                        className="d-flex align-items-center"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div
                          className={`icon-box icon-box-lg bg-${item.changetheme}-light rounded`}
                        >
                          {item.svg}
                        </div>
                        <div className="total-projects ms-3">
                          <h3 className={`text-${item.changetheme} count`}>
                            {item.number || 0}
                          </h3>
                          <span>{item.title}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </OverlayTrigger>
              </div>
            ))}

            <div className="col-xl-8">
              <CompletedRaces />
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="col-xl-12">
                <UpcomingBlog />
              </div>
            </div>
            {componentsLoaded && (
              <React.Fragment>
                <div className="col-xl-12">
                  <div className="card bg-primary-light analytics-card">
                    <div className="card-body mt-4 pb-1">
                      <div className="row align-items-center">
                        <div className="col-xl-2">
                          <h3 className="mb-3">Largest races</h3>
                          <p className="mb-0 text-primary pb-4">
                            Races with the
                            <br /> longest distance.
                          </p>
                        </div>
                        <div className="col-xl-10">
                          {largestRaces.length > 0 ? (
                            <div className="row">
                              {largestRaces.map((race, index) => (
                                <div
                                  className="col-xl-2 col-sm-4 col-6"
                                  key={index}
                                >
                                  <div
                                    className="card ov-card"
                                    onClick={() =>
                                      openRaceDetails(
                                        race.organizatedBy,
                                        race._id
                                      )
                                    }
                                  >
                                    <div className="card-body">
                                      <div className="ana-box">
                                        <div className="ic-n-bx">
                                          <div
                                            className="icon-box bg-primary rounded-circle"
                                            style={{ color: "#f8b940" }}
                                          >
                                            {index + 1}
                                          </div>
                                        </div>
                                        <div className="anta-data">
                                          <h5>{race.raceName}</h5>
                                          <span>{race.distance} km</span>
                                          <br />
                                          <h3 style={{ fontSize: "13px" }}>
                                            Type:{" "}
                                            {race.isGunRace
                                              ? "Gun Race"
                                              : "Chip Race"}
                                          </h3>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="text-center">
                              <p
                                className="mb-0"
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                No races available
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6  col-lg-6">
                  <div className="card">
                    <div className="card-header border-0 pb-0">
                      <h4 className="card-title">Trackers Overview</h4>
                    </div>
                    <div className="card-body">
                      <div
                        style={{ height: "370px" }}
                        id="DZ_W_TimeLine"
                        className="widget-timeline dz-scroll height370 ps--active-y"
                      >
                        {trackers?.length > 0 ? (
                          <ul className="timeline">
                            {trackers?.map((tracker, index) => (
                              <li key={index} title="Click for details">
                                <div className="timeline-badge primary"></div>
                                <Link
                                  className="timeline-panel text-muted"
                                  to={`/trackers/${tracker._id}`}
                                >
                                  <span>{`initial registration: ${formatTrackerDate(
                                    tracker.createdAt
                                  )}`}</span>
                                  <h6 className="mb-0">
                                    {`${tracker.fullName} is registered as ${tracker.trackerName} (login username credential)`}
                                  </h6>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <div className="text-center">
                            <p className="mb-0" style={{ fontSize: "15px" }}>
                              No trackers available
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                  <div className="card">
                    <div className="card-header border-0 pb-0">
                      <h4 className="card-title">Participants Overview</h4>
                    </div>
                    <div className="card-body">
                      <div
                        style={{ height: "370px" }}
                        id="DZ_W_Todo1"
                        className="widget-media dz-scroll ps--active-y"
                      >
                        {firstSixParticipants.length > 0 ? (
                          <ul className="timeline">
                            {firstSixParticipants.map((participant, index) => (
                              <li
                                key={index}
                                onClick={() =>
                                  openParticipantDetailsModal(participant)
                                }
                              >
                                <div className="timeline-panel">
                                  <div className="media me-2">
                                    <i
                                      className="bi bi-person-circle"
                                      style={{ fontSize: "1.5rem" }}
                                    ></i>
                                  </div>
                                  <div className="media-body">
                                    <h5 className="mb-1">{`${participant.firstName + ' ' + participant.lastName}`}</h5>
                                    <small className="d-block">
                                      Click for details
                                    </small>
                                  </div>
                                  <Dropdown className="dropdown">
                                    <Dropdown.Toggle
                                      variant="primary light"
                                      className=" i-false p-0 sharp"
                                      disabled // Add the disabled prop to make it unclickable
                                    >
                                      {index + 1}
                                    </Dropdown.Toggle>
                                  </Dropdown>
                                </div>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <div className="text-center">
                            <p className="mb-0" style={{ fontSize: "15px" }}>
                              No participants available
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
      <Modal
        className="fade bd-example-modal-lg"
        size="lg"
        show={state.openParticipantDetails}
        onHide={() => {
          dispatch({ type: "openParticipantDetails" });
          resetSelectedParticipant();
        }}
      >
        <Modal.Header>
          <Modal.Title>{"b"} Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              dispatch({ type: "openParticipantDetails" });
              resetSelectedParticipant();
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <React.Fragment>
            {selectedParticipant && (
              <div>
                <table className="table">
                  <tbody>
                    <tr>
                      <td>
                        <b>Email:</b>
                      </td>
                      <td>{selectedParticipant.email}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Age:</b>
                      </td>
                      <td>{selectedParticipant.age}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Gender:</b>
                      </td>
                      <td>{selectedParticipant.gender}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Bib Number:</b>
                      </td>
                      <td>{selectedParticipant.bibNumber}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Registration Date:</b>
                      </td>
                      <td>
                        {formatTrackerDate(
                          selectedParticipant.registrationDate
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger light"
            onClick={() => {
              dispatch({ type: "openParticipantDetails" });
              resetSelectedParticipant();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="fade bd-example-modal-lg"
        size="lg"
        show={state.openEventModal}
        onHide={() => {
          dispatch({ type: "openEventModal" });
        }}
      >
        <Modal.Header>
          <Modal.Title>More about events</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => dispatch({ type: "openEventModal" })}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <React.Fragment>
            <div className="form-group">
              <h6>
                Events are exciting gatherings that bring together enthusiasts,
                competitors, and fans to celebrate the thrill of racing. These
                occasions showcase the best in speed and skill, featuring a
                variety of racing formats, from high-speed circuits to off-road
                challenges. Participants get to experience the adrenaline-fueled
                excitement, while spectators enjoy the spectacle of finely tuned
                machines and skilled drivers pushing the limits.
              </h6>
            </div>
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger light"
            onClick={() => dispatch({ type: "openEventModal" })}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        className="fade bd-example-modal-lg"
        size="lg"
        show={state.openRaceModal}
        onHide={() => {
          dispatch({ type: "openRaceModal" });
        }}
      >
        <Modal.Header>
          <Modal.Title>More about races</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => dispatch({ type: "openRaceModal" })}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <React.Fragment>
            <div className="form-group">
              <h6>
                Races are organized competitions where participants compete to
                achieve victory in various categories. From endurance races that
                test the limits of both machines and drivers to sprint races
                that demand quick reflexes, races come in different forms. Each
                race is a unique challenge, combining strategy, skill, and
                speed. Whether on a track, road, or off-road terrain, races
                provide an opportunity for participants to showcase their
                talents and passion for motorsports.
              </h6>
            </div>
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger light"
            onClick={() => dispatch({ type: "openRaceModal" })}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        className="fade bd-example-modal-lg"
        size="lg"
        show={state.openParticipantModal}
        onHide={() => {
          dispatch({ type: "openParticipantModal" });
        }}
      >
        <Modal.Header>
          <Modal.Title>More about participants</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => dispatch({ type: "openParticipantModal" })}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <React.Fragment>
            <div className="form-group">
              <h6>
                Participants are the heart and soul of any racing event. Whether
                drivers, riders, or team members, participants bring a unique
                blend of skill, determination, and passion to the racing
                community. Each participant contributes to the diversity and
                competitiveness of the field, creating a dynamic and thrilling
                atmosphere. Racing events attract participants from various
                backgrounds, fostering a sense of camaraderie and sportsmanship
                among those who share a love for speed and competition.
              </h6>
            </div>
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger light"
            onClick={() => dispatch({ type: "openParticipantModal" })}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        className="fade bd-example-modal-lg"
        size="lg"
        show={state.openTrackerModal}
        onHide={() => {
          dispatch({ type: "openTrackerModal" });
        }}
      >
        <Modal.Header>
          <Modal.Title>More about trackers</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => dispatch({ type: "openTrackerModal" })}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <React.Fragment>
            <div className="form-group">
              <h6>
                Trackers play a crucial role in monitoring and recording the
                performance of racing participants. These devices use advanced
                technology to collect data such as speed, distance covered, and
                lap times. Trackers help both participants and organizers
                analyze and improve performance, offering valuable insights into
                racing dynamics. From GPS tracking to telemetry data, trackers
                contribute to the overall racing experience by enhancing safety,
                competitiveness, and data-driven decision-making.
              </h6>
            </div>
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger light"
            onClick={() => dispatch({ type: "openTrackerModal" })}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default MainPage;
