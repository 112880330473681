import { getRaceResults, generateResultsExcel, generateResultsPDF } from "./requests/resultsRequests";
const useTrackers = () => {
    const fetchRaceResults = async (raceId) => {
        try {
            const fetchedResults = await getRaceResults(raceId);
            return fetchedResults;
        }
        catch (error) {
            console.error("Failed to fetch results", error);
        }
    }
    const generateResultsExcelHandler = async (eventId, raceId, selectedColumnsCsv) => {
        try {
            const response = await generateResultsExcel(eventId, raceId, selectedColumnsCsv);
            return response;
        }
        catch (error) {
            console.error("Failed to generate excel", error);
        }
    }
    const generateResultsPDFHandler = async (eventId, raceId, reorderedSelectedColumnsPdf) => {
        try {
            const response = await generateResultsPDF(eventId, raceId, reorderedSelectedColumnsPdf);
            return response;
        }
        catch (error) {
            console.error("Failed to generate pdf", error);
        }
    }
    return { fetchRaceResults, generateResultsExcelHandler, generateResultsPDFHandler };
}
export default useTrackers;