import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const [pageRefreshed, setPageRefreshed] = useState(false);

  useEffect(() => {
    // Check if the page is refreshed
    if (performance.navigation.type === 1) {
      setPageRefreshed(true);
    }

    if (pageRefreshed) {
      window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [pathname, pageRefreshed]);

  return null;
}
