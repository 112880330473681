import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { Card } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./customCss/xclx.css";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import { Stepper, Step } from "react-form-stepper";
import useEvents from "../../../hooks/useEvents";
import useRaces from "../../../hooks/useRaces";
import useTrackers from "../../../hooks/useTrackers";

const NewRace = () => {
  const [raceName, setRaceName] = useState("");
  const [location, setLocation] = useState("");
  const [distance, setDistance] = useState("");
  const [distanceType, setDistanceType] = useState("");
  const [isGunRace, setIsGunRace] = useState(true);
  const [dateTime, setDateTime] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [inputDate, setInputDate] = useState("");
  const [inputTime, setInputTime] = useState("");
  const [organizatedBy, setOrganizatedBy] = useState("");
  // const [events, setEvents] = useState([]);
  const [setRaces] = useState([]);
  const [splits, setSplits] = useState(["Start Split"]);
  const [split, setSplit] = useState("");
  const [splitLocation, setSplitLocation] = useState("");
  const [splitDistanceType, setSplitDistanceType] = useState("");
  const [splitMinTime, setSplitMinTime] = useState("");
  const [splitNames, setSplitNames] = useState(["Start Split"]);
  const [showAddedSplits, setShowAddedSplits] = useState(true);
  const [event, setEvent] = useState([]);
  const { eventId } = useParams();
  const [splitsSet, setSplitsSet] = useState(new Set(["Start Split"]));
  const [selectedTrackers, setSelectedTrackers] = useState([]);
  const [trackerToSplitMap, setTrackerToSplitMap] = useState({});
  const [trackers, setTrackers] = useState([]);
  const [availableTrackers, setAvailableTrackers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [goSteps, setGoSteps] = useState(0);
  const [eventDateToCompare, setEventDateToCompare] = useState("");
  const [currentDateToCompare, setCurrentDateToCompare] = useState("");
  const navigate = useNavigate();

  const { fetchEventById } = useEvents();

  const { createRaceHandler } = useRaces();

  const { fetchAllTrackers } = useTrackers();

  useEffect(() => {
    const fetchTrackers = async () => {
      try {
        const response = await fetchAllTrackers();
        setTrackers(response.trackers);
        setAvailableTrackers(response.trackers);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchEvent = async () => {
      try {
        const response = await fetchEventById(eventId);
        const timestamp = response.event.dateTime;
        const date = new Date(timestamp * 1000);
        const currentUnixTime = Math.floor(new Date().getTime() / 1000);
        setEventDateToCompare(timestamp);
        setCurrentDateToCompare(currentUnixTime);
        if (timestamp < currentUnixTime) {
          swal(
            "Warning",
            "Event date is in the past! Please set a new date.",
            "warning"
          );
        }

        setEventDate(date);

        setInputDate(formatTimestampToDate(timestamp));
        setEvent(response.event);
      } catch (error) {
        swal("Failed!", "Event details error!", "error");
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTrackers();
    fetchEvent();
  }, [eventId]);

  function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const handleFormSubmit = async (e) => {
    const selectedDistaneType = distanceType.label;
    const convertToUnixTime = (timeString) => {
      const [minutes, seconds] = timeString.split(":").map(Number);
      const milliseconds = minutes * 60 + seconds; // Convert minutes and seconds to milliseconds
      return milliseconds; // Convert milliseconds to seconds
    };

    const splitsWithTrackers = splits.map((splitName, index) => {
      if (index === 0) {
        // For the first split, take only the name and set location and minTime to null
        return {
          name: splitName,
          trackerId: selectedTrackers[index]?.value || "",
          location: null,
          distanceType: null,
          minTime: null,
        };
      } else {
        // For other splits, take name, location, and minTime
        const minTimeUnix = splitName.minTime
          ? convertToUnixTime(splitName.minTime)
          : null; // Convert minTime to Unix time
        return {
          name: splitName.name,
          trackerId: selectedTrackers[index]?.value || "",
          location: splitName.location,
          distanceType: splitName.distanceType.value,
          minTime: minTimeUnix,
        };
      }
    });

    console.log(splitsWithTrackers);

    // Check if any split is not filled
    const noTracker = splitsWithTrackers.find((split) => !split.trackerId);

    if (noTracker) {
      swal("Warning", "Please select tracker for each split.", "warning");
      return;
    }
    try {
      const response = await createRaceHandler({
        raceName,
        location,
        distance,
        distanceType: selectedDistaneType,
        isGunRace,
        dateTime,
        organizatedBy: event._id,
        splits: splitsWithTrackers, // Send the modified selectedTrackers array
      });

      swal("Success!", "Race created successfully!", "success");

      setTrackerToSplitMap({});
      setRaceName("");
      setLocation("");
      setDistance("");
      setDistanceType("");
      setOrganizatedBy("");
      setInputDate("");
      setInputTime("");
      setShowAddedSplits(false);
      setSplit("");
      setSplitNames([]);
      setSplits([]);
      setSplitsSet(new Set());

      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      navigate(`/events/${eventId}/races`);
    } catch (error) {
      console.error("Failed to create race", error);
      swal("Oops", "Failed to create race!", "error");
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          {isLoading ? (
            <div className="text-center mt-4">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden"></span>
              </div>
            </div>
          ) : (
            <div className="col-xl-12 col-xxl-12">
              <div className="card">
                <Card.Header>
                  <Card.Title style={{ fontSize: "19px" }}>
                    {event.name &&
                      (goSteps === 0 ? (
                        <React.Fragment>
                          <Link to={`/events/${eventId}/races`}>
                            <b>{event.name}</b> Races
                          </Link>{" "}
                          &gt; Add New Race &gt; Fill the form below first
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Link to={`/events/${eventId}/races`}>
                            <b>{event.name}</b> Races
                          </Link>{" "}
                          &gt; Add New Race &gt; Complete Split Details
                        </React.Fragment>
                      ))}
                  </Card.Title>
                </Card.Header>{" "}
                <div className="card-body">
                  <div className="form-wizard">
                    <Stepper className="nav-wizard" activeStep={goSteps}>
                      <Step
                        className="nav-link"
                        onClick={() => setGoSteps(0)}
                      />
                      <Step
                        className="nav-link"
                        onClick={() => setGoSteps(1)}
                      />
                    </Stepper>
                    {goSteps === 0 && (
                      <React.Fragment>
                        <StepOne
                          inputDate={inputDate}
                          inputTime={inputTime}
                          setRaceName={setRaceName}
                          setInputDate={setInputDate}
                          setInputTime={setInputTime}
                          setDateTime={setDateTime}
                          setLocation={setLocation}
                          location={location}
                          setDistance={setDistance}
                          setDistanceType={setDistanceType}
                          setIsGunRace={setIsGunRace}
                          setGoSteps={setGoSteps}
                          raceName={raceName}
                          distance={distance}
                          isGunRace={isGunRace}
                          dateTime={dateTime}
                          setOrganizatedBy={setOrganizatedBy}
                          organizatedBy={organizatedBy}
                          distanceType={distanceType}
                          goSteps={goSteps}
                          currentDateToCompare={currentDateToCompare}
                          eventDateToCompare={eventDateToCompare}
                        />
                      </React.Fragment>
                    )}
                    {goSteps === 1 && (
                      <React.Fragment>
                        <StepTwo
                          splits={splits}
                          splitsSet={splitsSet}
                          trackerToSplitMap={trackerToSplitMap}
                          setTrackerToSplitMap={setTrackerToSplitMap}
                          selectedTrackers={selectedTrackers}
                          setSelectedTrackers={setSelectedTrackers}
                          setSplits={setSplits}
                          setSplitNames={setSplitNames}
                          setAvailableTrackers={setAvailableTrackers}
                          availableTrackers={availableTrackers}
                          setSplitsSet={setSplitsSet}
                          setSplit={setSplit}
                          split={split}
                          setSplitLocation={setSplitLocation}
                          splitLocation={splitLocation}
                          splitDistanceType={splitDistanceType}
                          setSplitDistanceType={setSplitDistanceType}
                          splitMinTime={splitMinTime}
                          setSplitMinTime={setSplitMinTime}
                          setShowAddedSplits={setShowAddedSplits}
                          showAddedSplits={showAddedSplits}
                          trackers={trackers}
                          splitNames={splitNames}
                        />
                        <div className="text-end toolbar toolbar-bottom p-2">
                          <button
                            className="btn btn-secondary sw-btn-prev me-1"
                            onClick={() => {
                              setGoSteps(0);
                            }}
                          >
                            Back
                          </button>
                          <button
                            className="btn btn-primary sw-btn-next ms-1"
                            onClick={handleFormSubmit}
                          >
                            Create Race
                          </button>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewRace;
