import { toast } from "react-toastify";

const notifyNameWarning = () => {
  toast.warn("Please enter the race name.", {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyLocationWarning = () => {
  toast.warn("Please enter the location.", {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyDistanceWarning = () => {
  toast.warn("Please enter the distance.", {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyDistanceTypeWarning = () => {
  toast.warn("Please select the distance type.", {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyTimeWarning = () => {
  toast.warn("Please select the time.", {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyTopFullWidth = () => {
  toast.warn("Please select a future date and time!", {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifySplitWarning = (split) => {
  toast.warn(`Split "${split}" is already added.`, {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  return;
};

const notifyTimeValidation = () => {
  toast.warn("Please enter valid min time (00-59).", {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyTrackerAlreadyAssigned = (trackerId, splitName) => {
  toast.warn(
    <div style={{ width: "auto" }}>
      Tracker "<strong>{trackerId}</strong>" is already assigned to split "
      <strong>{splitName}</strong>".
    </div>,
    {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  );
};

export {
  notifyNameWarning,
  notifyLocationWarning,
  notifyDistanceWarning,
  notifyDistanceTypeWarning,
  notifyTimeWarning,
  notifyTopFullWidth,
  notifySplitWarning,
  notifyTimeValidation,
  notifyTrackerAlreadyAssigned,
};
