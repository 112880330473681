import React, { useState, useEffect } from "react";
import Select from "react-select";
import swal from "sweetalert";
import { ListGroup, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../layouts/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";
import "./customCss/xclx.css";
import {
  notifySplitWarning,
  notifyTimeValidation,
  notifyTrackerAlreadyAssigned,
} from "../../notifyHooks/notifyNewRace";
import useTrackers from "../../../hooks/useTrackers";

const StepTwo = ({
  splits,
  setSplits,
  splitsSet,
  setSplitsSet,
  trackerToSplitMap,
  setTrackerToSplitMap,
  selectedTrackers,
  setSelectedTrackers,
  splitNames,
  setSplitNames,
  availableTrackers,
  setAvailableTrackers,
  split,
  setSplit,
  splitLocation,
  setSplitLocation,
  splitDistanceType,
  setSplitDistanceType,
  splitMinTime,
  setSplitMinTime,
  showAddedSplits,
  setShowAddedSplits,
  trackers,
}) => {
  const [trackerss, setTrackerss] = useState([]);
  const [loadState, setLoadState] = useState(false);
  const navigate = useNavigate();

  const { fetchAllTrackers } = useTrackers();
  useEffect(() => {
    const fetchTrackers = async () => {
      try {
        setLoadState(true);
        const response = await fetchAllTrackers();
        setTrackerss(response);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          swal({
            title: "No trackers found!",
            text: "Please add new trackers and then come back to add new race!",
            icon: "info",
            className: "text-center-swal",

            buttons: {
              later: {
                text: "Later",
                value: "later",
                className: "btn-later",
              },
              addNewTracker: {
                text: "Add New Tracker",
                value: "addNewTracker",
                className: "btn-addNew",
              },
            },
            closeOnClickOutside: false,
          }).then((value) => {
            if (value === "later") {
              navigate(`/`);
            } else if (value === "addNewTracker") {
              navigate(`/trackers/new`);
            }
          });
        }
        console.error(error);
      } finally {
        setLoadState(false);
      }
    };

    fetchTrackers();
  }, []);

  const handleSplitChange = (e) => {
    setSplit(e.target.value);
  };

  const handleSplitLocationChange = (e) => {
    setSplitLocation(e.target.value);
  };

  const handleSplitDistanceTypeChange = (selectedType) => {
    setSplitDistanceType(selectedType);
  };

  const handleSplitMinTimeChange = (updatedTime) => {
    const minutes =
      updatedTime.minutes !== undefined
        ? updatedTime.minutes
        : splitMinTime.split(":")[0];
    const seconds =
      updatedTime.seconds !== undefined
        ? updatedTime.seconds
        : splitMinTime.split(":")[1];

    const formattedTime = `${String(minutes).padStart(2, "0")}:${String(
      seconds
    ).padStart(2, "0")}`;
    setSplitMinTime(formattedTime);
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      maxHeight: "120px", // Set a max height for the dropdown menu
      overflowY: "auto", // Enable vertical scrolling
      scrollbarWidth: "thin", // For Firefox
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "120px",
      scrollbarWidth: "thin", // For Firefox
      "&::-webkit-scrollbar": {
        width: "6px",
        height: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#ccc",
        borderRadius: "3px",
      },
    }),
  };

  const handleAddSplitButton = () => {
    if (
      split !== "" &&
      splitLocation !== "" &&
      splitDistanceType !== "" &&
      splitMinTime !== ""
    ) {
      const [minutes, seconds] = splitMinTime.split(":").map(Number);

      if (!split) {
        toast.info("Split Name is required");
        return;
      }

      if (!splitLocation) {
        toast.info("Split Distance/Location is required");
        return;
      }

      if (!splitDistanceType) {
        toast.info("Distance Type is required");
        return;
      }

      if (!minutes || !seconds) {
        toast.info("Minimum Time is required");
        return;
      }

      if (minutes < 0 || minutes > 59 || seconds < 0 || seconds > 59) {
        notifyTimeValidation();
        return;
      }

      if (splitLocation && isNaN(splitLocation)) {
        toast.info("Split Distance/Location must be a number");
        return;
      }
      if (splitsSet.has(split)) {
        notifySplitWarning(split);
      } else {
        swal(`Are you sure you want to add the "${split}" split?`, {
          buttons: ["No", "Yes"],
        }).then((value) => {
          if (value) {
            handleSplitAdd();
            setShowAddedSplits(true);
          } else {
          }
        });
      }
    }
  };

  const handleSplitAdd = () => {
    const newSplitDetails = {
      name: split,
      location: splitLocation,
      distanceType: splitDistanceType,
      minTime: splitMinTime,
    };

    splitsSet.add(split);
    setSplitsSet(splitsSet);
    setSplits((prevSplits) => [...prevSplits, newSplitDetails]);

    // Generate a unique split name and update the split names state
    const newSplitName = split;
    setSplitNames((prevSplitNames) => [...prevSplitNames, newSplitName]);
    // Reset the input fields
    setSplit("");
    setSplitLocation("");
    setSplitDistanceType("");
    setSplitMinTime({ minutes: "", seconds: "" });
  };

  const handleSplitDelete = (index) => {
    const deletedSplit = splits[index];
    splitsSet.delete(deletedSplit.name);

    const deletedTracker = trackerToSplitMap[deletedSplit.name];

    // Remove the tracker assignment for the deleted split
    setTrackerToSplitMap((prevMap) => {
      const newMap = { ...prevMap };
      delete newMap[deletedSplit.name];
      return newMap;
    });

    // Remove the split from the state variables
    setSplitNames((prevSplitNames) => {
      const newSplitNames = [...prevSplitNames];
      newSplitNames.splice(index, 1);
      return newSplitNames;
    });

    setSplits((prevSplits) => {
      const newSplits = [...prevSplits];
      newSplits.splice(index, 1);
      return newSplits;
    });

    setSelectedTrackers((prevSelectedTrackers) => {
      const newSelectedTrackers = [...prevSelectedTrackers];
      newSelectedTrackers.splice(index, 1); // Remove the tracker assignment for the deleted split
      // Update the tracker assignments for subsequent splits
      for (let i = index; i < newSelectedTrackers.length; i++) {
        if (newSelectedTrackers[i] !== null) {
          const splitName = splitNames[i];
          setTrackerToSplitMap((prevMap) => ({
            ...prevMap,
            [splitName]: newSelectedTrackers[i],
          }));
        }
      }
      return newSelectedTrackers;
    });

    // Update the available trackers list by adding this tracker back
    if (deletedTracker) {
      setAvailableTrackers((prevAvailableTrackers) => [
        ...prevAvailableTrackers,
        { _id: deletedTracker.value, trackerName: deletedTracker.label },
      ]);
    }
  };

  const handleRemoveTracker = (index) => {
    const targetSplit = splits[index];
    if (targetSplit === "Start Split") {
      const deletedTracker = trackerToSplitMap[targetSplit];

      // Remove the tracker assignment for "Start Split"
      setTrackerToSplitMap((prevMap) => {
        const newMap = { ...prevMap };
        delete newMap["Start Split"];
        return newMap;
      });

      // Clear the tracker assignment for "Start Split"
      setSelectedTrackers((prevSelectedTrackers) => {
        const newSelectedTrackers = [...prevSelectedTrackers];
        newSelectedTrackers[index] = null;
        return newSelectedTrackers;
      });

      if (deletedTracker) {
        setAvailableTrackers((prevAvailableTrackers) => [
          ...prevAvailableTrackers,
          { _id: deletedTracker.value, trackerName: deletedTracker.label },
        ]);
      }
    } else {
      const deletedTracker = trackerToSplitMap[targetSplit.name];

      // Remove the tracker assignment for "Start Split"
      setTrackerToSplitMap((prevMap) => {
        const newMap = { ...prevMap };
        delete newMap["Start Split"];
        return newMap;
      });

      // Clear the tracker assignment for "Start Split"
      setSelectedTrackers((prevSelectedTrackers) => {
        const newSelectedTrackers = [...prevSelectedTrackers];
        newSelectedTrackers[index] = null;
        return newSelectedTrackers;
      });

      if (deletedTracker) {
        setAvailableTrackers((prevAvailableTrackers) => [
          ...prevAvailableTrackers,
          { _id: deletedTracker.value, trackerName: deletedTracker.label },
        ]);
      }
    }
  };

  const AddedSplitsList = ({
    selectedTrackers,
    handleSplitDelete,
    availableTrackers,
  }) => {
    const handleTrackerChange = (selectedOption, splitIndex) => {
      const trackerId = selectedOption.value;
      const trackerName = selectedOption.label;
      const splitName = splitNames[splitIndex];
      // Check if the tracker is already assigned to another split
      for (let i = 0; i < selectedTrackers.length; i++) {
        if (i !== splitIndex && selectedTrackers[i]?.value === trackerId) {
          notifyTrackerAlreadyAssigned(trackerName, splitNames[i]);
          return;
        }
      }

      // Update the trackerToSplitMap with the selected split for the tracker
      setTrackerToSplitMap((prevMap) => ({
        ...prevMap,
        [splitName]: selectedOption,
      }));

      const updatedSelectedTrackers = [...selectedTrackers];
      updatedSelectedTrackers[splitIndex] = selectedOption;
      setSelectedTrackers(updatedSelectedTrackers);

      // Update the availableTrackers by filtering out the selected tracker
      const updatedAvailableTrackers = availableTrackers.filter(
        (tracker) => tracker._id !== trackerId
      );
      setAvailableTrackers(updatedAvailableTrackers);
    };

    return (
      <div className="form-group mb-3 col-md-12">
        {splitNames.length > 0 && (
          <div className="basic-list-group">
            {splitNames.map((splitName, index) => (
              <div
                className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                style={{
                  backgroundColor: "#f8f9fa",
                  borderRadius: "10px",
                  marginBottom: "5px",
                  border: "none",
                  position: "relative",
                }}
                key={index}
              >
                {index === 0 ? (
                  <span
                    style={{
                      fontSize: "18px",
                      fontFamily: "popins, sans-serif",
                    }}
                  >
                    '{splitName}' is automatically added.
                  </span>
                ) : (
                  <span
                    style={{
                      fontSize: "18px",
                      fontFamily: "popins, sans-serif",
                    }}
                  >
                    Split '{splitName}' is added.
                  </span>
                )}

                {trackers?.length > 0 && (
                  <React.Fragment>
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "450px",
                        position: "absolute",
                        alignContent: "center",
                      }}
                    >
                      <Select
                        id="tracker"
                        styles={customStyles} // Apply custom styles
                        style={{
                          lineHeight: "40px",
                          color: "#7e7e7e",
                          width: "500px",
                          marginLeft: "28px",
                        }}
                        value={selectedTrackers[index] || ""}
                        onChange={(selectedOption) =>
                          handleTrackerChange(selectedOption, index)
                        }
                        options={availableTrackers?.map((tracker) => ({
                          value: tracker._id,
                          label: tracker.trackerName,
                        }))}
                        isDisabled={!!selectedTrackers[index]}
                        placeholder="Select Tracker"
                      />
                      <button
                        style={{
                          marginLeft: "5px",
                          fontSize: "1.5rem",
                          border: "1px solid #ccc",
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "3px",
                        }}
                        title="Remove Tracker"
                        type="button"
                        onClick={() => handleRemoveTracker(index)}
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </React.Fragment>
                )}

                <Button
                  variant="danger"
                  title="Remove Split"
                  type="button"
                  onClick={() => handleSplitDelete(index)}
                  style={{
                    display: splitName === "Start Split" ? "none" : "block",
                  }}
                >
                  <span>
                    <i className="far fa-window-close" />
                  </span>
                </Button>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      <ToastContainer />

      <div className="basic-form">
        <div className="row">
          <div className="form-group mb-12 col-md-12">
            <label for="split">Split Info</label>
            <div className="row">
              <div className="col">
                <input
                  id="split"
                  className="form-control"
                  type="text"
                  placeholder="Split Name"
                  value={split}
                  onChange={handleSplitChange}
                />
              </div>
              <div className="col">
                <input
                  id="location"
                  className="form-control"
                  type="text"
                  placeholder="Split Distance/Location"
                  value={splitLocation}
                  onChange={handleSplitLocationChange}
                />
              </div>
              <div className="form-group mb-3 col-md-2">
                <Select
                  id="distanceType"
                  value={splitDistanceType}
                  onChange={handleSplitDistanceTypeChange}
                  options={["km", "m"].map((type) => ({
                    value: type,
                    label: type,
                  }))}
                  placeholder="Distance Type"
                />
              </div>
              <div className="col">
                <input
                  id="minutes"
                  className="form-control"
                  type="number"
                  min="0"
                  max="59"
                  placeholder="Minutes"
                  value={splitMinTime.minutes}
                  onChange={(e) =>
                    handleSplitMinTimeChange({
                      ...splitMinTime,
                      minutes: parseInt(e.target.value) || 0,
                    })
                  }
                />
              </div>
              <div className="col">
                <input
                  id="seconds"
                  className="form-control"
                  type="number"
                  min="0"
                  max="59"
                  placeholder="Seconds"
                  value={splitMinTime.seconds}
                  onChange={(e) =>
                    handleSplitMinTimeChange({
                      ...splitMinTime,
                      seconds: parseInt(e.target.value) || 0,
                    })
                  }
                />
              </div>
              <div className="col">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAddSplitButton}
                  disabled={
                    !split ||
                    !splitLocation ||
                    !splitDistanceType ||
                    !splitMinTime
                  }
                  style={{
                    width: "100%",
                    pointerEvents:
                      !split ||
                      !splitLocation ||
                      !splitDistanceType ||
                      !splitMinTime
                        ? "auto"
                        : "",
                    cursor:
                      !split ||
                      !splitLocation ||
                      !splitDistanceType ||
                      !splitMinTime
                        ? "not-allowed"
                        : "",
                  }}
                >
                  Add Split
                </button>
              </div>
            </div>
          </div>
          <div className="form-group mb-12 col-md-12">
            {showAddedSplits && (
              <AddedSplitsList
                splitNames={splitNames}
                selectedTrackers={selectedTrackers}
                handleSplitDelete={handleSplitDelete}
                availableTrackers={availableTrackers}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StepTwo;
