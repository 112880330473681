import {
  getAllEvents,
  getEventById,
  deleteEvent,
  updateEvent,
  createEvent,
} from "./requests/eventRequests";

const useEvents = () => {
  const fetchAllEvents = async () => {
    try {
      const fetchedEvents = await getAllEvents();

      return fetchedEvents;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchEventById = async (eventId) => {
    try {
      const response = await getEventById(eventId);
      return response; // Return the entire response data
    } catch (error) {
      console.error("Failed to fetch event data for editing", error);
      throw new Error("Failed to fetch event data for editing");
    }
  };

  const deleteEventHandler = async (eventId) => {
    try {
      const response = await deleteEvent(eventId);
      return response;
    } catch (error) {
      console.error("Failed to delete event", error);
      throw new Error("Failed to delete event");
    }
  };

  const updateEventHandler = async (eventId, editedData) => {
    try {
      await updateEvent(eventId, editedData);
      return true;
    } catch (error) {
      console.error("Failed to update event", error);
      return false;
    }
  };

  const createEventHandler = async (name, date) => {
    try {
      const response = await createEvent(name, date);
      return response;
    } catch (error) {
      console.error("Failed to create event", error);
      throw error;
    }
  };

  return {
    deleteEventHandler,
    createEventHandler,
    updateEventHandler,
    fetchEventById,
    fetchAllEvents,
  };
};

export default useEvents;
