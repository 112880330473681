import React, { useState } from "react";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "../../layouts/ScrollToTop";
import "react-datepicker/dist/react-datepicker.css";
import useEvents from "../../../hooks/useEvents";
import "./customCss/xclx.css";
import {
  notifyNameWarning,
  notifyDateWarning,
} from "../../notifyHooks/notifyEvents";

const NewEvent = () => {
  const [name, setEventName] = useState("");
  const [date, setDate] = useState("");

  const { createEventHandler } = useEvents();

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const transformDate = (date) => {
    const timestampInString = `${date}T${"00:00:00"}`;
    const timestampInMilliseconds = Date.parse(timestampInString);
    const timestampInSeconds = Math.floor(timestampInMilliseconds / 1000);
    return timestampInSeconds;
  };

  const handleEventNameChange = (e) => {
    setEventName(e.target.value);
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;

    setDate(selectedDate);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (name.trim() === "") {
      notifyNameWarning();
      return;
    }

    if (date.trim() === "") {
      notifyDateWarning();
      return;
    }

    try {
      const response = await createEventHandler(name, date);
      swal("Success!", "Event created successfully!", "success");
    } catch (error) {
      swal("Oops", "Failed to create event!", "error");
    }

    setEventName("");
    setDate("");
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      <ToastContainer />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title" style={{ fontSize: "19px" }}>
                  Add New Event
                </h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <form onSubmit={handleFormSubmit}>
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          value={name}
                          onChange={handleEventNameChange}
                        />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label>Date</label>
                        <input
                          type="date"
                          className="form-control"
                          min={getCurrentDateTime()}
                          placeholder="Date"
                          value={date}
                          onChange={handleDateChange}
                        />
                      </div>
                    </div>
                    <div className="text-end mt-2 mb-2">
                      <button type="submit" className="btn btn-primary">
                        Create Event
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewEvent;
