import {
  LOADING_TOGGLE_ACTION,
  LOGIN_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
  SIGNUP_CONFIRMED_ACTION,
  SIGNUP_FAILED_ACTION,
  SIDEBAR_COLLAPSE_TOGGLE,
  NAVTOGGLE,
} from "../actions/AuthActions";

const initialState = {
  // auth: {
  //   email: "",
  //   idToken: "",
  //   localId: "",
  //   expiresIn: "",
  //   refreshToken: "",
  // },
  auth: {
    message: "",
    token: "",
  },
  token: "",
  errorMessage: "",
  successMessage: "",
  showLoading: false,
  // count : false,
  isSidebarCollapsed: true,
};

export function AuthReducer(state = initialState, action) {
  if (action.type === SIGNUP_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: "",
      successMessage: "",
      showLoading: false,
    };
  }

  if (action.type === LOGIN_CONFIRMED_ACTION) {
    return {
      ...state,
      // auth: {
      //   ...state.auth,
      //   token: action.payload,
      // },
      token: action.payload,
      errorMessage: "",
      // successMessage: "Login Successfully Completed",
      showLoading: false,
    };
  }

  if (action.type === LOGOUT_ACTION) {
    return {
      ...state,
      errorMessage: "",
      successMessage: "",
      token: "",
      auth: {
        message: "",
        token: "",
      },
    };
  }

  if (
    action.type === SIGNUP_FAILED_ACTION ||
    action.type === LOGIN_FAILED_ACTION
  ) {
    return {
      ...state,
      errorMessage: action.payload,
      successMessage: "",
      showLoading: false,
    };
  }

  if (action.type === LOADING_TOGGLE_ACTION) {
    return {
      ...state,
      showLoading: action.payload,
    };
  }

  if (action.type === NAVTOGGLE) {
    return {
      ...state,
      count: !state.count,
    };
  }

  if (action.type === SIDEBAR_COLLAPSE_TOGGLE) {
    return {
      ...state,
      isSidebarCollapsed: !state.isSidebarCollapsed,
    };
  }
  return state;
}
