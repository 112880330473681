import React, { Fragment, useState, useEffect, useReducer } from "react";
import { NavLink } from "react-router-dom";
import { Row, Col, Button, Card, Table, Modal } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Pagination } from "react-bootstrap";
import swal from "sweetalert";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import ScrollToTop from "../../layouts/ScrollToTop";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useEvents from "../../../hooks/useEvents";
import useRaces from "../../../hooks/useRaces";
import useTrackers from "../../../hooks/useTrackers";
import "./customCss/xclx.css";
import {
  notifySplitNameWarning,
  notifyDistanceField,
  notifyTrackerWarning,
  notifySplitLocationWarning,
  notifySplitDistanceTypeWarning,
  notifySplitTimeWarning,
  notifyTimeValidation,
} from "../../notifyHooks/notifyEventDetails";

const initialState = {
  editRaceModal: false,
  selectedRace: null, // Initially set to null, no race selected.
};

const reducer = (state, active) => {
  switch (active.type) {
    case "editRaceModal":
      return {
        ...state,
        editRaceModal: !state.editRaceModal,
        selectedRace: active.payload,
      };
    default:
      return state;
  }
};

const EventDetails = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { eventId, raceId } = useParams();
  const [races, setRaces] = useState([]);
  const [event, setEvent] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [racesPerPage] = useState(4);
  const [splits, setSplits] = useState([]);
  const [selectedRace, setSelectedRace] = useState();
  const [editedRaceData, setEditedRaceData] = useState();
  const [showMoreSplits, setShowMoreSplits] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedDistanceType, setSelectedDistanceType] = useState(null);
  const [selectedRaceType, setSelectedRaceType] = useState(null);
  const [newSplitName, setNewSplitName] = useState("");
  const [newSplitLocation, setNewSplitLocation] = useState("");
  const [newSplitDistanceType, setNewSplitDistanceType] = useState("");
  const [splitMinTime, setSplitMinTime] = useState("");
  const [availableTrackers, setAvailableTrackers] = useState([]);
  const [freeTrackers, setFreeTrackers] = useState([]);
  const [selectedTracker, setSelectedTracker] = useState(null);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [loadState, setLoadState] = useState(true);
  const [inputDate, setInputDate] = useState("");
  const [inputTime, setInputTime] = useState("");
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const navigate = useNavigate();

  const {
    fetchRacesByEventId,
    fetchRaceByIds,
    deleteRaceHandler,
    editRaceHandler,
  } = useRaces();
  const { fetchEventById } = useEvents();
  const { fetchAllTrackers } = useTrackers();

  const getStatusOptions = () => {
    return [
      { value: 0, label: "Scheduled" },
      { value: 1, label: "Completed" },
      { value: 2, label: "Canceled" },
    ];
  };

  const getDistanceTypeOptions = () => {
    return [
      { value: "km", label: "km" },
      { value: "m", label: "m" },
    ];
  };

  const getRaceTypeOptions = () => {
    return [
      { value: true, label: "Gun Race" },
      { value: false, label: "Chip Race" },
    ];
  };

  const statusOptions = getStatusOptions();
  const distanceTypeOptions = getDistanceTypeOptions();
  const raceTypeOptions = getRaceTypeOptions();

  const handleShowMore = () => {
    setShowMoreSplits(true);
  };

  const handleShowLess = () => {
    setShowMoreSplits(false);
  };

  const handleCloseModal = () => {
    setShowMoreSplits(false);
    setNewSplitName("");
    setNewSplitLocation("");
    setNewSplitDistanceType("");
    setSplitMinTime("");
    setSelectedTracker(null);
    setIsInputVisible(false);
  };

  useEffect(() => {
    const fetchRaces = async () => {
      try {
        const response = await fetchRacesByEventId(eventId);
        console.log(response);
        setRaces(response);
        if (response.length === 0) {
          swal({
            title: "No races found!",
            text: "Please add new race!",
            icon: "info",
            closeOnClickOutside: false,
            buttons: {
              later: {
                text: "Later",
                value: "later",
                className: "btn-later",
              },
              addNewTracker: {
                text: "Add New Race",
                value: "addNewRace",
                className: "btn-addNew",
              },
            },
          }).then((value) => {
            if (value === "later") {
              navigate(`/events`);
            } else if (value === "addNewRace") {
              navigate(`/events/${eventId}/races/new`);
            }
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadState(false);
      }
    };

    const fetchEvent = async () => {
      try {
        const response = await fetchEventById(eventId);
        setEvent(response.event);
      } catch (error) {
        swal("Failed!", "Event details error!", "error");
        console.error(error);
      }
    };

    const fetchTrackers = async () => {
      try {
        const response = await fetchAllTrackers();
        console.log("trackers", response);
        setAvailableTrackers(response.trackers);
      } catch (error) {
        console.error(error);
      }
    };

    fetchEvent();
    fetchRaces();
    fetchTrackers();
  }, [eventId, raceId]);

  const handleNewSplitNameChange = (e) => {
    setNewSplitName(e.target.value);
  };

  const handleNewSplitLocationChange = (e) => {
    setNewSplitLocation(e.target.value);
  };

  const handleNewSplitDistanceTypeChange = (selectedOption) => {
    setNewSplitDistanceType(selectedOption);
  };

  const handleSplitMinTimeChange = (updatedTime) => {
    const minutes =
      updatedTime.minutes !== undefined
        ? updatedTime.minutes
        : splitMinTime.split(":")[0];
    const seconds =
      updatedTime.seconds !== undefined
        ? updatedTime.seconds
        : splitMinTime.split(":")[1];

    const formattedTime = `${String(minutes).padStart(2, "0")}:${String(
      seconds
    ).padStart(2, "0")}`;
    setSplitMinTime(formattedTime);
  };

  const handleUserChange = (selectedOption) => {
    if (selectedOption) {
      const trackerId = selectedOption.value;
      const trackerName = selectedOption.label;

      setSelectedTracker({ value: trackerId, label: trackerName });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedRaceData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSplitInputChange = (e, splitIndex) => {
    const { value } = e.target;
    setSplits((prevSplits) => {
      const updatedSplits = [...prevSplits];
      updatedSplits[splitIndex].name = value;
      return updatedSplits;
    });
    setSelectedRace((prevRace) => ({
      ...prevRace,
      splits: prevRace.splits.map((split, index) =>
        index === splitIndex ? { ...split, name: value } : split
      ),
    }));
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption.value);

    setEditedRaceData((prevData) => ({
      ...prevData,
      status: selectedOption.value,
    }));
  };

  const handleDistanceTypeChange = (selectedOption) => {
    setSelectedDistanceType(selectedOption.value);
    setEditedRaceData((prevData) => ({
      ...prevData,
      distanceType: selectedOption.value,
    }));
  };

  const handleRaceTypeChange = (selectedOption) => {
    setSelectedRaceType(selectedOption.value);

    if (selectedOption.value === "Gun Race") {
      setEditedRaceData((prevData) => ({
        ...prevData,
        isGunRace: selectedOption.value,
      }));
    } else {
      setEditedRaceData((prevData) => ({
        ...prevData,
        isGunRace: selectedOption.value,
      }));
    }
  };

  const handleCombinedChange = (e) => {
    if (e.target.type === "date") {
      setInputDate(e.target.value);
    } else if (e.target.type === "time") {
      setInputTime(e.target.value);
    }
  };

  useEffect(() => {
    const timestampInString = `${inputDate}T${inputTime}`;
    const timestampInMilliseconds = Date.parse(timestampInString);
    const timestampInSeconds = Math.floor(timestampInMilliseconds / 1000);
    handleTimeChange(timestampInSeconds);
  }, [inputDate, inputTime]);

  const handleTimeChange = (timestampInSeconds) => {
    setEditedRaceData((prevData) => ({
      ...prevData,
      dateTime: timestampInSeconds,
      date: inputDate,
      time: inputTime,
    }));
  };

  const fetchRaceById = async (raceId) => {
    try {
      const response = await fetchRaceByIds(raceId, eventId);
      return response;
    } catch (error) {
      throw new Error("Failed to fetch race data for editing.");
    }
  };

  const formatTableRaceDate = (date) => {
    const timestampInMilliseconds = date * 1000;
    const dateTime = new Date(timestampInMilliseconds);
    const day = dateTime.getDate().toString().padStart(2, "0");
    const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
    const year = dateTime.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const formatTableRaceTime = (time) => {
    const timestampInMilliseconds = time * 1000;
    const dateTime = new Date(timestampInMilliseconds);
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes().toString().padStart(2, "0");
    const seconds = dateTime.getSeconds().toString().padStart(2, "0");

    const timeFormatted = `${hours}:${minutes}:${seconds}`;
    return timeFormatted;
  };

  const formatEditDate = (date) => {
    const timestampInMilliseconds = date * 1000;
    const dateTime = new Date(timestampInMilliseconds);

    const year = dateTime.getFullYear();
    const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
    const day = dateTime.getDate().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const formatEditTime = (time) => {
    const timestampInMilliseconds = time * 1000;
    const dateTime = new Date(timestampInMilliseconds);

    const hours = dateTime.getHours().toString().padStart(2, "0");
    const minutes = dateTime.getMinutes().toString().padStart(2, "0");

    const formattedTime = `${hours}:${minutes}`;
    return formattedTime;
  };

  const handleEditRace = async (raceId) => {
    try {
      console.log("timi", raceId);
      const response = await fetchRaceById(raceId);
      const selectedRaceData = response.race;

      const trackerIdToNameMap = {};
      availableTrackers.forEach((tracker) => {
        trackerIdToNameMap[tracker._id] = tracker.trackerName;
      });
      // Set initial state for the date input field
      setEditedRaceData({
        ...selectedRaceData,
        date: formatEditDate(selectedRaceData.dateTime),
        time: formatEditTime(selectedRaceData.dateTime),
        trackerIdToNameMap, // Save the mapping in the state
      });

      setInputDate(formatEditDate(selectedRaceData.dateTime));
      setInputTime(formatEditTime(selectedRaceData.dateTime));
      setSelectedStatus(selectedRaceData.status);
      setSelectedDistanceType(selectedRaceData.distanceType);
      setSelectedRaceType(selectedRaceData.isGunRace);
      setSplits(selectedRaceData.splits);

      const assignedTrackers = selectedRaceData.splits.map(
        (split) => split.trackerId
      );

      const allTrackers = availableTrackers?.map((tracker) => ({
        value: tracker._id,
        label: tracker.trackerName,
      }));

      const freeTrackers = allTrackers.filter(
        (tracker) => !assignedTrackers.includes(tracker.value)
      );
      setFreeTrackers(freeTrackers);

      dispatch({ type: "editRaceModal", payload: response.data });
      setSelectedRace(selectedRaceData);
    } catch (error) {
      console.error("Failed to fetch event data for editing", error);
    }
  };

  const handleAddSplit = () => {
    if (!newSplitName) {
      notifySplitNameWarning();
      return;
    }

    if (!newSplitLocation) {
      notifySplitLocationWarning();
      return;
    }

    if (!newSplitDistanceType) {
      notifySplitDistanceTypeWarning();
      return;
    }

    const [minutes, seconds] = splitMinTime.split(":").map(Number);
    if (!minutes || !seconds) {
      notifySplitTimeWarning();
      return;
    }
    if (isNaN(minutes) || isNaN(seconds)) {
      console.log("Provide number please");
      return;
    }

    if (minutes < 0 || minutes > 59 || seconds < 0 || seconds > 59) {
      notifyTimeValidation();
      return;
    }

    if (!selectedTracker) {
      notifyTrackerWarning();
      return;
    }

    // Check if the split name already exists
    if (splits.some((split) => split.name === newSplitName)) {
      toast.warn("Split name must be unique.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    // Generate a unique ID for the new split
    const newSplitId = uuidv4();

    // Add the new split to both the general splits array and the locally added splits array
    setSplits((prevSplits) => [
      ...prevSplits,
      { _id: newSplitId, name: newSplitName, trackerId: selectedTracker.value },
    ]);

    setShowMoreSplits(true);
    setNewSplitName("");
    setSelectedTracker(null);
    setNewSplitLocation("");
    setNewSplitDistanceType("");
    setSplitMinTime("");
    setSplitMinTime({
      minutes: "",
      seconds: "",
    });

    // Update the editedRaceData.splits array in real-time
    setEditedRaceData((prevRace) => ({
      ...prevRace,
      splits: [
        ...prevRace.splits,
        {
          _id: newSplitId,
          name: newSplitName,
          trackerId: selectedTracker.value,
          distance: newSplitLocation,
          distanceType: newSplitDistanceType.value,
          minTime: splitMinTime,
        },
      ],
      trackers: [...prevRace.trackers, selectedTracker.value],
    }));

    // Remove the selected tracker from the list of free trackers
    const updatedAvailableTrackers = freeTrackers.filter(
      (tracker) => tracker.value !== selectedTracker.value
    );
    setFreeTrackers(updatedAvailableTrackers);
  };

  const handleDeleteRace = async (raceId) => {
    try {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this race!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        className: "text-center-swal",
      }).then(async (willDelete) => {
        if (willDelete) {
          // Send a DELETE request to the server
          await deleteRaceHandler(raceId);

          // Update the state to remove the deleted race from local state
          setRaces((prevRaces) =>
            prevRaces.filter((race) => race._id !== raceId)
          );

          swal("Race has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Race is still active!");
        }
      });
    } catch (error) {
      console.error("Failed to delete race!", error);
    }
  };

  const handleDeleteSplit = (splitIndex) => {
    const deletedSplit = splits[splitIndex];

    setSplits((prevSplits) => {
      const updatedSplits = [...prevSplits];
      updatedSplits.splice(splitIndex, 1);
      return updatedSplits;
    });

    // Retrieve the tracker name using the mapping
    const trackerName =
      editedRaceData.trackerIdToNameMap[deletedSplit.trackerId];

    // Add the deleted tracker back to the available/free trackers
    setFreeTrackers((prevFreeTrackers) => [
      ...prevFreeTrackers,
      { value: deletedSplit.trackerId, label: trackerName },
    ]);

    // setSelectedRace((prevRace) => ({
    //   ...prevRace,
    //   splits: prevRace.splits.filter((split, index) => index !== splitIndex),
    // }));

    editedRaceData.splits.splice(splitIndex, 1);
    editedRaceData.trackers.splice(splitIndex, 1);
  };

  const handleSaveChanges = async () => {
    try {
      const convertToUnixTime = (timeString) => {
        // If timeString is a number, return it immediately
        if (!isNaN(timeString)) {
          return Number(timeString);
        }

        const [minutes, seconds] = timeString?.split(":").map(Number);
        const milliseconds = minutes * 60 + seconds; // Convert minutes and seconds to milliseconds
        return milliseconds; // Convert milliseconds to seconds
      };
      setButtonIsLoading(true);
      if (isNaN(editedRaceData.distance)) {
        notifyDistanceField();
        return;
      }
      const finalSplits = editedRaceData.splits.map((split, index) => {
        if (index === 0) {
          return {
            ...split,
          };
        } else {
          return {
            ...split,
            minTime: convertToUnixTime(split.minTime),
          };
        }
      });

      editedRaceData.splits = finalSplits;

      // Send a PUT request to update the race on the backend using editedRaceData
      await editRaceHandler(selectedRace._id, editedRaceData);

      setRaces((prevRaces) =>
        prevRaces.map((race) =>
          race._id === selectedRace._id ? editedRaceData : race
        )
      );

      dispatch({ type: "editRaceModal" });
      swal("Race has been updated!", {
        icon: "success",
      });
      setShowMoreSplits(false);
      setIsInputVisible(false);
    } catch (error) {
      console.error("Failed to update race", error);
      swal("Oops", "Failed to update race!", "error");
    } finally {
      setButtonIsLoading(false);
    }
  };

  // Calculate indexes of the races to be displayed
  const indexOfLastRace = currentPage * racesPerPage;
  const indexOfFirstRace = indexOfLastRace - racesPerPage;
  const currentRaces = races.slice(indexOfFirstRace, indexOfLastRace);
  // const currentRace = races.slice(indexOfFirstRace, indexOfLastRace);

  // Change page
  const handlePageChange = (pageNumber) => {
    if (
      pageNumber >= 1 &&
      pageNumber <= Math.ceil(races.length / racesPerPage)
    ) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <Fragment>
      <ScrollToTop />
      <ToastContainer />
      <div className="container-fluid">
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Header>
                {event.name && (
                  <React.Fragment>
                    {" "}
                    <Card.Title style={{ fontSize: "19px" }}>
                      <React.Fragment>
                        <Link to={`/events`}>All Events</Link> &gt;{" "}
                        <b>{event.name}</b> Races
                      </React.Fragment>
                    </Card.Title>
                    <NavLink to={`/events/${eventId}/races/new`}>
                      <Button variant="primary">Add New Race</Button>
                    </NavLink>
                  </React.Fragment>
                )}
              </Card.Header>
              <Card.Body>
                {loadState ? (
                  <div className="text-center mt-4">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden"></span>
                    </div>
                  </div>
                ) : (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className="col-1 text-center">
                          <strong>#</strong>
                        </th>
                        <th className="text-center">
                          <strong>NAME</strong>
                        </th>
                        <th className="text-center">
                          <strong>DATE</strong>
                        </th>
                        <th className="text-center">
                          <strong>START</strong>
                        </th>
                        <th className="text-center">
                          <strong>STATUS</strong>
                        </th>
                        <th className="text-center">
                          <strong>LOCATION</strong>
                        </th>
                        <th className="text-center">
                          <strong>DETAILS</strong>
                        </th>
                        <th className="col-3 text-center">
                          <strong>RESULTS & SPLITS</strong>
                        </th>
                        <th className="col-1 text-center">
                          <strong>ACTION</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentRaces.map((race, index) => (
                        <tr key={index}>
                          <td className="text-center">
                            <strong>{index + indexOfFirstRace + 1}</strong>
                          </td>
                          <td className="text-center">{race.raceName}</td>
                          <td className="text-center">
                            {formatTableRaceDate(race.dateTime)}
                          </td>
                          <td className="text-center">
                            {formatTableRaceTime(race.dateTime)}
                          </td>
                          <td className="text-center">
                            {race.status === 0 ? (
                              <span className="badge light badge-warning">
                                Scheduled
                              </span>
                            ) : race.status === 1 ? (
                              <span className="badge light badge-success">
                                Completed
                              </span>
                            ) : (
                              <span className="badge light badge-danger">
                                Canceled
                              </span>
                            )}
                          </td>
                          <td className="text-center">{race.location}</td>
                          <td className="text-center">
                            {
                              <NavLink
                                to={`/events/${event._id}/races/${race._id}`}
                              >
                                <Button className="btn btn-primary btn-xs btn btn-info light">
                                  View Race
                                </Button>
                              </NavLink>
                            }
                          </td>
                          <td className="text-center">
                            {
                              <React.Fragment>
                                <NavLink
                                  to={`/events/${eventId}/races/${race._id}/results`}
                                  style={{
                                    pointerEvents:
                                      race.status !== 1 ||
                                      race.participants.some(
                                        (participant) =>
                                          participant.confirmedSplits.length ===
                                          0
                                      ) ||
                                      race.participants.length === 0
                                        ? "none"
                                        : "auto",
                                  }}
                                >
                                  <Button
                                    className="me-2 btn btn-xs btn-info light"
                                    disabled={
                                      race.status !== 1 ||
                                      race.participants.some(
                                        (participant) =>
                                          participant.confirmedSplits.length ===
                                          0
                                      ) ||
                                      race.participants.length === 0
                                    }
                                    title={
                                      race.status !== 1 ||
                                      race.participants.some(
                                        (participant) =>
                                          participant.confirmedSplits.length ===
                                          0
                                      ) ||
                                      race.participants.length === 0
                                        ? "No results yet"
                                        : ""
                                    }
                                    style={{
                                      pointerEvents: "all",
                                      cursor:
                                        race.status !== 1 ||
                                        race.participants.some(
                                          (participant) =>
                                            participant.confirmedSplits
                                              .length === 0
                                        ) ||
                                        race.participants.length === 0
                                          ? "not-allowed"
                                          : "",
                                    }}
                                  >
                                    View Results
                                  </Button>
                                </NavLink>
                              </React.Fragment>
                            }
                            {
                              <NavLink
                                to={`/events/${event._id}/races/${race._id}/edit-results`}
                                style={{
                                  pointerEvents:
                                    race.status !== 1 ||
                                    race.participants.some(
                                      (participant) =>
                                        participant.confirmedSplits.length === 0
                                    ) ||
                                    race.participants.length === 0
                                      ? "none"
                                      : "auto",
                                }}
                              >
                                <Button
                                  className="me-2 btn btn-xs btn-info light"
                                  disabled={
                                    race.status !== 1 ||
                                    race.participants.some(
                                      (participant) =>
                                        participant.confirmedSplits.length === 0
                                    ) ||
                                    race.participants.length === 0
                                  }
                                  title={
                                    race.status !== 1 ||
                                    race.participants.some(
                                      (participant) =>
                                        participant.confirmedSplits.length === 0
                                    ) ||
                                    race.participants.length === 0
                                      ? "No results yet"
                                      : ""
                                  }
                                  style={{
                                    pointerEvents: "all",
                                    cursor:
                                      race.status !== 1 ||
                                      race.participants.some(
                                        (participant) =>
                                          participant.confirmedSplits.length ===
                                          0
                                      ) ||
                                      race.participants.length === 0
                                        ? "not-allowed"
                                        : "",
                                  }}
                                >
                                  Edit Results
                                </Button>
                              </NavLink>
                            }
                          </td>
                          <td className="text-center">
                            <Button
                              variant="primary"
                              className="shadow btn-xs sharp me-1"
                              onClick={() => handleEditRace(race._id)}
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Button>
                            <Button
                              variant="danger"
                              className="shadow btn-xs sharp"
                              onClick={() => handleDeleteRace(race._id)}
                            >
                              <i className="fa fa-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Card.Body>

              <Card.Footer>
                <div className="pagination-container">
                  <Pagination>
                    <li className="page-item page-indicator">
                      <Link
                        className="page-link"
                        to="#"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        style={{
                          pointerEvents: currentPage === 1 ? "none" : "all",
                          opacity: currentPage === 1 ? 0.5 : 1,
                        }}
                      >
                        <i className="la la-angle-left" />
                      </Link>
                    </li>
                    {Array.from(
                      { length: Math.ceil(races.length / racesPerPage) },
                      (_, index) => index + 1
                    ).map((page) => (
                      <Pagination.Item
                        key={page}
                        active={page === currentPage}
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </Pagination.Item>
                    ))}
                    <li className="page-item page-indicator">
                      <Link
                        className="page-link"
                        to="#"
                        onClick={() => handlePageChange(currentPage + 1)}
                        style={{
                          pointerEvents:
                            currentPage ===
                            Math.ceil(races.length / racesPerPage)
                              ? "none"
                              : "all",
                          opacity:
                            currentPage ===
                            Math.ceil(races.length / racesPerPage)
                              ? 0.5
                              : 1,
                        }}
                      >
                        <i className="la la-angle-right" />
                      </Link>
                    </li>
                  </Pagination>
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>

        <Modal
          className="fade bd-example-modal-lg"
          show={state.editRaceModal}
          size="lg"
          onHide={() => {
            handleCloseModal();
            dispatch({ type: "editRaceModal" });
          }}
        >
          <Modal.Header>
            <Modal.Title>Edit Race</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => {
                dispatch({ type: "editRaceModal" });
                handleCloseModal();
              }}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            {selectedRace ? (
              <React.Fragment>
                <div className="basic-form">
                  <div className="row">
                    <div className="form-group mb-3 w-50">
                      <span className="form-group-text w-25">
                        <b>Race Name</b>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        value={editedRaceData.raceName}
                        onChange={handleInputChange}
                        name="raceName"
                      />
                    </div>
                    <div className="form-group mb-3 w-50">
                      <span className="form-group-text w-25">
                        <b>Status</b>
                      </span>
                      <Select
                        className="mb-10 w-100"
                        options={statusOptions}
                        value={statusOptions.find(
                          (option) => option.value === selectedStatus
                        )}
                        onChange={handleStatusChange}
                      />
                    </div>

                    <div className="form-group mb-3 w-50">
                      <span className="form-group-text w-25">
                        <b>Location</b>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        value={editedRaceData.location}
                        onChange={handleInputChange}
                        name="location"
                      />
                    </div>

                    <div className="form-group mb-3 w-50">
                      <span className="form-group-text w-25">
                        <b>Distance</b>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        value={editedRaceData.distance}
                        onChange={handleInputChange}
                        name="distance"
                      />
                    </div>

                    <div className="form-group mb-3 w-50">
                      <span className="form-group-text w-25">
                        <b>Distance type</b>
                      </span>
                      <Select
                        className="mb-10 w-100"
                        options={distanceTypeOptions}
                        value={distanceTypeOptions.find(
                          (option) => option.value === selectedDistanceType
                        )}
                        onChange={handleDistanceTypeChange}
                      />
                    </div>

                    <div className="form-group mb-3 w-50">
                      <span className="form-group-text w-25">
                        <b>Race type</b>
                      </span>
                      <Select
                        className="mb-10 w-100"
                        options={raceTypeOptions}
                        value={raceTypeOptions.find(
                          (option) => option.value === selectedRaceType
                        )}
                        onChange={handleRaceTypeChange}
                      />
                    </div>

                    <div className="form-group mb-3 w-50">
                      <span className="form-group-text w-25">
                        <b>Race Date</b>
                      </span>
                      <input
                        type="date"
                        className="form-control"
                        value={editedRaceData.date}
                        onChange={handleCombinedChange}
                        name="date"
                      />
                    </div>
                    <div className="form-group mb-3 w-50">
                      <span className="form-group-text w-25">
                        <b>Start Time</b>
                      </span>
                      <input
                        type="time"
                        className="form-control"
                        value={editedRaceData.time}
                        onChange={handleCombinedChange}
                        name="time"
                      />
                    </div>

                    {!isInputVisible && (
                      <div className={`text-center mt-3`}>
                        <Button
                          variant="primary light"
                          onClick={() => setIsInputVisible(true)}
                        >
                          Add New Split
                        </Button>
                      </div>
                    )}
                    {isInputVisible && <div className="border-top mt-3"></div>}
                    <div
                      className={`form-group mb-3 mt-3${
                        isInputVisible ? " fade-in" : " fade-out"
                      }`}
                    >
                      <div className="row align-items-center">
                        <div className="col">
                          <input
                            type="text"
                            className="form-control"
                            value={newSplitName}
                            onChange={handleNewSplitNameChange}
                            placeholder="New Split Name"
                          />
                        </div>
                        <div className="col">
                          <input
                            type="text"
                            className="form-control"
                            value={newSplitLocation}
                            onChange={handleNewSplitLocationChange}
                            placeholder="New Split Location/Distance"
                          />
                        </div>
                        <div className="col">
                          <Select
                            value={newSplitDistanceType}
                            onChange={(selectedOption) =>
                              handleNewSplitDistanceTypeChange(selectedOption)
                            }
                            options={["km", "m"].map((type) => ({
                              value: type,
                              label: type,
                            }))}
                            placeholder="Distance Type"
                          />
                        </div>
                      </div>
                      <div className="row align-items-center mt-3">
                        <div className="col">
                          <input
                            id="minutes"
                            className="form-control"
                            type="number"
                            min="0"
                            max="59"
                            placeholder="Minutes"
                            value={splitMinTime.minutes}
                            onChange={(e) =>
                              handleSplitMinTimeChange({
                                ...splitMinTime,
                                minutes: parseInt(e.target.value) || 0,
                              })
                            }
                          />
                        </div>
                        <div className="col">
                          <input
                            id="seconds"
                            className="form-control"
                            type="number"
                            min="0"
                            max="59"
                            placeholder="Seconds"
                            value={splitMinTime.seconds}
                            onChange={(e) =>
                              handleSplitMinTimeChange({
                                ...splitMinTime,
                                seconds: parseInt(e.target.value) || 0,
                              })
                            }
                          />
                        </div>
                        <div className="col">
                          <Select
                            value={selectedTracker}
                            onChange={(selectedOption) =>
                              handleUserChange(selectedOption)
                            }
                            options={freeTrackers}
                            placeholder="Select Tracker"
                          />
                        </div>
                      </div>
                      <div className="row text-end mt-3">
                        <div className="col">
                          <Button
                            className="me-2"
                            variant="primary"
                            onClick={handleAddSplit}
                            style={{ borderRadius: "6px" }}
                          >
                            Add
                          </Button>
                          <Button
                            variant="secondary"
                            onClick={() => setIsInputVisible(false)}
                            style={{ borderRadius: "6px" }}
                          >
                            Hide
                          </Button>
                        </div>
                      </div>
                    </div>
                    {isInputVisible && <div className="border-bottom"></div>}

                    {editedRaceData.splits.length > 0 && (
                      <div className="text-center mt-3">
                        <h3>Edit Splits</h3>
                      </div>
                    )}
                    {/* Render splits if isExpanded is true */}
                    <React.Fragment>
                      {editedRaceData.splits
                        ?.slice(
                          0,
                          showMoreSplits ? editedRaceData.splits.length : 2
                        )
                        .map((split, splitIndex) => (
                          <React.Fragment>
                            <div
                              className="input-group mb-3 mt-3 w-50"
                              key={split._id}
                            >
                              <span className="input-group-text w-25">
                                <b>Split {splitIndex + 1}</b>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                value={split.name}
                                onChange={(e) =>
                                  handleSplitInputChange(e, splitIndex)
                                }
                                disabled={splitIndex === 0} // Disable the input for the first split
                              />
                              <Button
                                className="me-2"
                                variant="danger light"
                                onClick={() => handleDeleteSplit(splitIndex)}
                                style={{
                                  borderRadius: "6px",
                                  pointerEvents: splitIndex === 0 ? "auto" : "",
                                  cursor: splitIndex === 0 ? "not-allowed" : "",
                                }}
                                title={
                                  splitIndex === 0
                                    ? "Cannot delete first split"
                                    : ""
                                }
                                disabled={splitIndex === 0} // Disable the button for the first split
                              >
                                Delete
                              </Button>
                            </div>
                          </React.Fragment>
                        ))}

                      {/* Render added splits */}

                      {/* Button to show more splits */}
                      {editedRaceData.splits.length > 2 && !showMoreSplits && (
                        <div className="d-flex justify-content-center mt-3">
                          <Button
                            variant="primary light"
                            onClick={handleShowMore}
                          >
                            Show More Splits
                          </Button>
                        </div>
                      )}

                      {/* Button to show less splits */}
                      {showMoreSplits && editedRaceData.splits.length > 2 && (
                        <div className="d-flex justify-content-center mt-3">
                          <Button
                            variant="secondary light"
                            onClick={handleShowLess}
                          >
                            Show Less Splits
                          </Button>
                        </div>
                      )}
                    </React.Fragment>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <p>No race selected.</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger light"
              onClick={() => {
                dispatch({ type: "editRaceModal" });
                handleCloseModal();
              }}
            >
              Close
            </Button>
            <Button
              variant="primary"
              disabled={buttonIsLoading}
              onClick={() => {
                handleSaveChanges(editedRaceData);
                handleCloseModal();
              }}
            >
              {buttonIsLoading ? "Saving changes..." : "Save changes"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Fragment>
  );
};

export default EventDetails;
