import {
  getAllSplitTimesForRaceGenerateResults,
  getAllSplitTimesForEditConfirmedSplits,
  updateParticipantId,
} from "./requests/splitTimesRequests";
const useSplitTimes = () => {
  const fetchSplitTimesForRaceGenerate = async (raceId) => {
    try {
      const response = await getAllSplitTimesForRaceGenerateResults(raceId);
      return response;
    } catch (error) {
      console.error("Failed to fetch split times", error);
    }
  };
  const fetchSplitTimesForEditConfirmed = async (raceId) => {
    try {
      const response = await getAllSplitTimesForEditConfirmedSplits(raceId);
      return response;
    } catch (error) {
      console.error("Failed to fetch split times", error);
    }
  };

  const updateParticipantIdHandler = async (splitMap) => {
    try {
      const response = await updateParticipantId(splitMap);
      return response;
    } catch (error) {
      console.error("Failed to update participant id", error);
    }
  };

  return {
    fetchSplitTimesForRaceGenerate,
    fetchSplitTimesForEditConfirmed,
    updateParticipantIdHandler,
  };
};
export default useSplitTimes;
