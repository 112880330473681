import axios from "axios"
const getRaceResults = async (raceId) => {
    try {
        const response = await axios.get(`/race/${raceId}/results`);
        return response.data;
    }
    catch (error) {
        console.error("Failed to fetch results", error);
    }
}
const generateResultsExcel = async (eventId, raceId, selectedColumnsCsv) => {
    try {
        const response = await axios.post(
            `/${eventId}/${raceId}/resultsToExcel`,
            { selectedColumnsCsv },
            {
                responseType: "blob",
            }
        );
        return response.data;
    }
    catch (error) {
        console.error("Failed to generate excel", error);
    }
}
const generateResultsPDF = async (eventId, raceId, reorderedSelectedColumnsPdf) => {
    try {
        const response = await axios.post(
            `/${eventId}/${raceId}/resultsToPDF`,
            { selectedColumnsPdf: reorderedSelectedColumnsPdf },
            {
                responseType: "blob", // Set responseType directly in the config object
            }
        );
        return response.data;
    }
    catch (error) {
        console.error("Failed to generate pdf", error);
    }
}

export {
    getRaceResults,
    generateResultsExcel,
    generateResultsPDF
}