import { toast } from "react-toastify";

const notifySplitNameWarning = () => {
  toast.warn("Please enter the new split name.", {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyDistanceField = () => {
  toast.warn("Distance field should be a number.", {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyTrackerWarning = () => {
  toast.warn("Please select a tracker.", {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifySplitLocationWarning = () => {
  toast.warn("Please enter the new split location.", {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifySplitDistanceTypeWarning = () => {
  toast.warn("Please select the distance type.", {
    position: "top-center",

    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifySplitTimeWarning = () => {
  toast.warn("Please enter the minimum split time.", {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const notifyTimeValidation = () => {
  toast.warn("Please enter valid min time (00-59).", {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export {
  notifySplitNameWarning,
  notifyDistanceField,
  notifyTrackerWarning,
  notifySplitLocationWarning,
  notifySplitDistanceTypeWarning,
  notifySplitTimeWarning,
  notifyTimeValidation,
};
