import { SVGICON } from "../../constant/theme";

export const MenuList = [
  //Content
  {
    title: "Links",
    classsChange: "menu-title",
  },
  //Dashboard
  {
    title: "Dashboard",
    iconStyle: SVGICON.DashboardLogo,
    to: "/dashboard",
    style: {
      fontSize: "larger",
    },
    content: [
      {
        title: "Main Page",
        to: "/dashboard",
      },
    ],
  },
  {
    title: "Events",
    iconStyle: SVGICON.EventLogo,
    to: "/events",
    classsChange: "mm-collapse",
    style: {
      fontSize: "larger",
    },
    hasMenu: true,
    content: [
      {
        title: "View Events",
        to: "/events",
      },
      {
        title: "Add Event",
        to: "/events/new",
      },
    ],
  },
  {
    title: "Trackers",
    iconStyle: SVGICON.TrackersLogo,
    to: "/trackers",
    style: {
      fontSize: "larger",
    },
    hasMenu: true,
    content: [
      {
        title: "View Trackers",
        to: "/trackers",
      },
      {
        title: "Add Tracker",
        to: "/trackers/new",
      },
    ],
  },
  {
    title: "Results",
    iconStyle: SVGICON.ResultsLogo,
    to: "/results",
    style: {
      fontSize: "larger",
    },
    content: [
      {
        title: "View Results",
        to: "/results",
      },
    ],
  },
];
