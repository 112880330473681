import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import "./styles.css";
import swal from "sweetalert";
import { Logout } from "../../store/actions/AuthActions";
import { Button } from "react-bootstrap";
import LogoutPage from "../layouts/nav/Logout";
import useAdmins from "../../hooks/useAdmins";
import usePayments from "../../hooks/usePayments";


const Packages = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [admin, setAdmin] = useState(null); // Initialize user state
  const [verified, setVerified] = useState(false);
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { fetchAdminHandler, verifyEmailHandler } = useAdmins();  
  const { fetchAllPayments } = usePayments();
  function onLogout() {
    dispatch(Logout(navigate));
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [productsResponse, adminResponse] = await Promise.all([
          fetchAllPayments(),
          fetchAdminHandler()
        ]);
        if (adminResponse.admin.subscription.isActive === true) {
          navigate("/dashboard");
        }
        if (adminResponse.admin.isVerified === true) {
          setVerified(true);
        }
        setEmail(adminResponse.admin.email);
        setProducts(productsResponse);
        setAdmin(adminResponse.admin);
      } catch (error) {
        console.error("Error fetching data:", error.message);
        // Display an error message to the user
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const onBack = () => {
    navigate("/dashboard");
  };

  const handleVerify = async () => {
    try {
      const response = await verifyEmailHandler(email);
      console.log(response);  
      if (response.status === 200) {
        swal({
          title: "Email sent successfully!",
          text: response.message,
          icon: "success",
          buttons: {
            cancel: "OK",
          },
        });
      } else {
        swal({
          title: "Error",
          text: response.error,
          icon: "error",
          buttons: {
            cancel: "OK",
          },
        });
      }
    } catch (error) {
      console.error("Error verifying email:", error.message);
    }
  };
  const handleNotVerified = () => {
    swal({
      title: "Please verify your email to continue!",
      icon: "warning",
      buttons: {
        cancel: "OK",
      },
    });
  };

  if (loading) {
    return (
      <div className="loading-div">
        <div className="spinner-border" role="status">
          <span className="visually-hidden"></span>
        </div>
      </div>
    );
  }

  return (
    <div className="main-div">
      {!verified && (
        <div className="warning-div">
          <p id="warning">
            Verification has been sent to your email!{" "}
            <u>
              <b>
                <button className="link-button" onClick={handleVerify}>
                  Resend Verification
                </button>
              </b>
            </u>
          </p>
        </div>
      )}
      {admin !== null && (
        <>
          <div className="user-info">
            <p>{admin.username}</p>
            <Button variant="primary btn-xs" onClick={onLogout}>
              Logout
            </Button>
          </div>
        </>
      )}

      <h1 className="text-center h1-packages">Packages</h1>
      <section className="product-container">
        {products.map((product) => (
          <div key={product.type} className="product-card-container">
            <div className="product-card">
              <img
                src="https://i.imgur.com/EHyR2nP.png"
                alt="The cover of Stubborn Attachments"
              />
              <div className="product-description">
                <h3>{product.type === "standard" ? "Standard" : "Premium"}</h3>
                <h5>${(product.price / 100).toFixed(2)}</h5>
              </div>

              <form
                action={`/create-checkout-session/${product.type}`}
                method="POST"
                className="product-form"
              >
                {verified ? (
                  <button type="submit" className="product-button">
                    Checkout
                  </button>
                ) : (
                  <button
                    type="button"
                    className="product-button"
                    onClick={handleNotVerified}
                  >
                    Checkout
                  </button>
                )}
              </form>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default Packages;
